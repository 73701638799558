import {Tool} from "../Tool";
import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import type {MarkupManager} from "../../../managers/spaceitems/MarkupManager";
import {MarkupDrawing} from "../../../elements3d/markups/MarkupDrawing";
import {HighlightMaterial} from "../../../materials/HighlightMaterial";
import {MeasureType} from "../../../renderers/SpaceViewRendererUtils";
import {dottedLineDashSize, dottedLineGapSize, highlightRadius} from "../../../elements3d/markups/MarkupStaticElements";
import type {ToolType} from "../Tools";
import {TempMarkupLineWidth} from "../../../elements3d/markups/abstract/MarkupUtils";
import type {IMarkupConfig} from "../../../elements3d/markups/abstract/MarkupUtils";
import {HTMLUtils} from "../../../../../../../../utils/HTML/HTMLUtils";
import type {PointDouble} from "../../../../../../../../generated/api/base";
import type {Pointer} from "../../../../../../../../utils/interaction/Pointer";

export class PencilTool extends Tool {
	private _markupManager: MarkupManager;
	private _strokeOpacity: number;
	private _markup: MarkupDrawing;
	private _pathCoords: PointDouble[];
	private _optimizedPathCoords: PointDouble[];
	private _cursorCircle: HTMLDivElement;
	private _isTemp: boolean = false;
	protected override _toolType: ToolType = "markup";

	private readonly _measureType: MeasureType;
	private readonly _isHighLight: boolean;

	public static readonly strokeWidth = {
		pencil: 0.025,
		highlight: 0.2,
	};

	public static readonly strokeOpacity = {
		pencil: 1,
		highlight: 0.2,
	};

	constructor(
		spaceViewRenderer: SpaceViewRenderer,
		isHighLight: boolean = false,
		measureType: MeasureType = MeasureType.NONE,
		isTemp: boolean = false,
	) {
		super(spaceViewRenderer, isHighLight, isHighLight ? "none" : "url(src/assets/images/spaceviewer/pencil.svg) 0 20, auto");
		this._isTemp = isTemp;
		this._isHighLight = isHighLight;
		this._strokeOpacity = this._isHighLight ? PencilTool.strokeOpacity.highlight : PencilTool.strokeOpacity.pencil;
		this._markupManager = spaceViewRenderer.markupManager;
		this._measureType = measureType;

		if (this._isHighLight) {
			this._cursorCircle = document.createElement("div");
			this._cursorCircle.classList.add("cursorCircle");
		}
	}

	public override activate() {
		const hasChanged = super.activate();

		if (hasChanged) {
			if (this._cursorCircle) {
				this._domElement.addEventListener("mouseenter", this.onMouseEnter);
				this._domElement.addEventListener("mouseout", this.detachCursorCircle);

				this.onCameraZoomChange();

				const cameraSignals = this._spaceViewRenderer.toolManager.cameraControls.signals;

				cameraSignals.cameraGrabbed.add(this.detachCursorCircle);
				cameraSignals.cameraReleased.add(this.updateCursorCircle);
				cameraSignals.cameraZoomChange.add(this.onCameraZoomChange);
			}
		}

		return hasChanged;
	}

	public override deactivate() {
		this.abortMarkup();
		const hasChanged = super.deactivate();

		if (hasChanged) {
			if (this._cursorCircle) {
				this.detachCursorCircle();
				this._domElement.removeEventListener("mouseenter", this.onMouseEnter);
				this._domElement.removeEventListener("mouseout", this.detachCursorCircle);

				const cameraSignals = this._spaceViewRenderer.toolManager.cameraControls.signals;

				cameraSignals.cameraGrabbed.remove(this.detachCursorCircle);
				cameraSignals.cameraReleased.remove(this.updateCursorCircle);
				cameraSignals.cameraZoomChange.remove(this.onCameraZoomChange);
			}
		}

		return hasChanged;
	}

	private onCameraZoomChange = () => {
		if (this._cursorCircle) {
			const {cameraZoomValue} = this._spaceViewRenderer.toolManager.cameraControls;

			const val = 2 * highlightRadius * cameraZoomValue;

			this._cursorCircle.style.width = `${val}px`;
			this._cursorCircle.style.height = this._cursorCircle.style.width;

			this._cursorCircle.style.top = `-${val / 2 + 1}px`;
			this._cursorCircle.style.left = `-${val / 2 + 1}px`;
		}
	};

	private onMouseEnter = () => {
		this.attachCursorCircleIfNeeded();
	};

	private attachCursorCircleIfNeeded() {
		if (!this._spaceViewRenderer.toolManager.cameraControls.isCameraGrabbed && !this._cursorCircle?.parentElement) {
			this.attachCursorCircle();
		}
	}

	private updateCursorCircle = (pointer: Pointer) => {
		if (this._cursorCircle) {
			const elementUnderMouse = HTMLUtils.elementFromPoint(pointer.pageX, pointer.pageY);

			if (this._domElement === elementUnderMouse) {
				this._cursorCircle.style.transform = `translateX(${pointer.pageX}px) translateY(${pointer.pageY}px)`;

				this.attachCursorCircleIfNeeded();
			} else {
				this.detachCursorCircle();
			}
		}
	};

	private attachCursorCircle = () => {
		document.body.appendChild(this._cursorCircle);
	};

	private detachCursorCircle = () => {
		HTMLUtils.detach(this._cursorCircle);
	};

	protected override onPointerDownCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		this._pathCoords = [{x: worldX, y: worldY}];

		const color = this._isTemp ? this._spaceViewRenderer.measureToolColor.hex : this._markupManager.markupColor;

		const config: IMarkupConfig = {
			strokeColor: color,
			fill: this._measureType === MeasureType.AREA,
			// fillOpacity: 0.1,
			strokeOpacity: this._strokeOpacity,
			isHighLight: this._isHighLight,
			measureType: this._measureType,
			isTemp: this._isTemp,
		};

		if (this._isTemp) {
			const correctionMultiplier = this._spaceViewRenderer.correctionMultiplier.current;

			config.dashSize = dottedLineDashSize * correctionMultiplier;
			config.gapSize = dottedLineGapSize * correctionMultiplier;
			config.lineWidth = TempMarkupLineWidth;
		}

		this._markup = new MarkupDrawing(this._spaceViewRenderer, config);

		this._markup.showLayerWithNotification();
		this._spaceViewRenderer.spaceItemController.deselectAll();
	};

	protected override onPointerMoveCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		if (this._pathCoords) {
			this._pathCoords.push({x: worldX, y: worldY});
			this._optimizedPathCoords = PencilTool.getOptimizedPathCoords(this._pathCoords);

			this._markup.updateGeometry(this._optimizedPathCoords, false);
		}

		this.updateCursorCircle(pointer);
	};

	protected override onPointerUpCallback = (pointer: Pointer) => {
		this.finalizeMarkup();
		this._pathCoords = null;
	};

	/**
	 * We remove some vertices. The goal is that the pathCoords.length should never be larger than HighlightMaterial.ABSOLUTE_ARRAY_MAX
	 */
	public static getOptimizedPathCoords(pathCoords: PointDouble[]) {
		const skipFactor = Math.ceil(pathCoords.length / (2 * HighlightMaterial.ABSOLUTE_ARRAY_MAX)); // we're using vec4, so it's 2 pairs of coords per vec4 array element

		if (skipFactor === 1) {
			return pathCoords;
		}

		const optimizedPathCoords: PointDouble[] = [];

		for (let i = 0; i < pathCoords.length; ++i) {
			const isLastVertex = i === pathCoords.length - 1;

			if (i % skipFactor === 0 || isLastVertex) {
				optimizedPathCoords.push(pathCoords[i]);

				if (isLastVertex) {
					optimizedPathCoords.splice(Math.min(1, optimizedPathCoords.length), 1);
				}
			}
		}

		return optimizedPathCoords;
	}

	// TODO: Could be okay if I could find a solution to 2d shape merging (without intersections). ThreeBSP only works for 3D objects
	// public static convertPathCoordsToFatLineMesh(pathCoords: PointDouble[])
	// {
	// 	const RADIUS = 0.75;

	// 	const timeStamp = performance.now();

	// 	const material = new BasicMaterial({color: 0xff0000, transparent: true, opacity: 0.2});

	// 	let bspObject: ThreeBSP;

	// 	for (let i = 0; i < pathCoords.length - 1; ++i)
	// 	{
	// 		const A = pathCoords[i];
	// 		const B = pathCoords[i + 1];

	// 		const ABDistance = THREEUtils.calculateDistance([A, B]);

	// 		const AB = {
	// 			x: B.x - A.x,
	// 			y: B.y - A.y
	// 		};

	// 		const center = {
	// 			x: (A.x + B.x) / 2,
	// 			y: (A.y + B.y) / 2
	// 		};

	// 		const ABAngleWithX = Math.atan2(AB.y, AB.x);

	// 		let cylinderA = null;

	// 		if (i === 0)
	// 		{
	// 			//const circleA = new CircleBufferGeometry(RADIUS, 8, Math.PI / 2 + ABAngleWithX, Math.PI);
	// 			cylinderA = new CylinderBufferGeometry(RADIUS, RADIUS, 1, 8, 1, false, Math.PI + ABAngleWithX, Math.PI);
	// 			cylinderA.rotateX(Math.PI / 2);
	// 			THREEUtils.applyOffsetToBufferGeometry(cylinderA, A);
	// 		}

	// 		//const plane = new PlaneBufferGeometry(ABDistance, RADIUS * 2);
	// 		const box = new BoxBufferGeometry(ABDistance, RADIUS * 2, 1, 1, 1, 1);
	// 		THREEUtils.applyRotationToBufferGeometry(box, ABAngleWithX);
	// 		THREEUtils.applyOffsetToBufferGeometry(box, center);
	// 		//const circleB = new CircleBufferGeometry(RADIUS, 8, - Math.PI / 2 + ABAngleWithX, Math.PI);
	// 		const cylinderB = new CylinderBufferGeometry(RADIUS, RADIUS, 1, 8, 1, false, ABAngleWithX, Math.PI);
	// 		cylinderB.rotateX(Math.PI / 2);
	// 		THREEUtils.applyOffsetToBufferGeometry(cylinderB, B);

	// 		const arrayOfBufferGeometries = [
	// 			box,
	// 			cylinderB
	// 		];

	// 		if (cylinderA)
	// 		{
	// 			arrayOfBufferGeometries.unshift(cylinderA);
	// 		}

	// 		const lineSegmentGeometry = /*BufferGeometryUtils.mergeVertices(*/BufferGeometryUtils.mergeBufferGeometries(arrayOfBufferGeometries)/*, 1);*/

	// 		if (!bspObject)
	// 		{
	// 			//bspObject = lineSegmentGeometry;
	// 			bspObject = new ThreeBSP(lineSegmentGeometry);
	// 		}
	// 		else
	// 		{
	// 			//bspObject = BufferGeometryUtils.mergeBufferGeometries([bspObject, lineSegmentGeometry]);
	// 			bspObject = bspObject.union(new ThreeBSP(lineSegmentGeometry));
	// 		}
	// 	}

	// 	const finalGeometry = new BufferGeometry().fromGeometry(bspObject.toGeometry());

	// 	const deltaTime = performance.now() - timeStamp;

	// 	console.log(deltaTime);

	// 	return new Mesh(finalGeometry, material);//bspObject.toMesh(material);
	// }

	private finalizeMarkup() {
		if (this._markup) {
			if (this._markup.isValid) {
				this._markupManager.add([this._markup]);
			} else {
				this.abortMarkup();
			}
			this._markup = null;
		}
	}

	public get markup() {
		return this._markup;
	}

	public abortMarkup() {
		this._markup?.destroy(false, true);
		this._markup = null;
	}
}
