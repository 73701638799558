import {styled, css} from "styled-components";
import {useEffect, useMemo, useRef, useState} from "react";
import type {MouseEvent, ReactNode} from "react";
import {Observer} from "mobx-react";
import type {AppState} from "../../../data/state/AppState";
import {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import type {Organization} from "../../../data/models/Organization";
import type {NavBarState} from "../../../StateManager";
import {useAppStore} from "../../../StateManager";
import {colorPalette} from "../styles/colorPalette";
import {VerticalFlexStyle, FlexCenterStyle, FlexStyle, Flex, baseDistance, radius, zIndex, fontWeight, fontSize} from "../styles/styles";
import type {IFlexStyle} from "../styles/styles";
import GridIcon from "../icons/grid.svg?react";
import CirclePlusIcon from "../icons/circle-plus.svg?react";
import CircleQuestionIcon from "../icons/circle-question.svg?react";
import GearIcon from "../icons/gear.svg?react";
import ArrowDownToBracketIcon from "../icons/arrow-down-to-bracket.svg?react";
import SidebarCloseIcon from "../icons/sidebar-close.svg?react";
import HouseLineIcon from "../icons/house-line.svg?react";
import BriefCaseAltIcon from "../icons/briefcase-alt.svg?react";
import BookOpenIcon from "../icons/book-open.svg?react";
import ChartLineUpIcon from "../icons/chart-line-up.svg?react";
import {FeatureImportPanelV5} from "../abstract/view/FeatureImportPanelV5";
import SidebarOpenIcon from "../icons/sidebar.svg?react";
import LogoIcon from "../icons/xyicon_logo.svg?react";
import {useClickOutside, useMouseLeave} from "../utils";
import {CheckboxInputV5} from "../details/datatypes/CheckboxInputV5";
import {CreateViewPopup} from "../abstract/view/create/CreateViewPopup";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {Functions} from "../../../utils/function/Functions";
import {pseudoLabelTooltipStyle} from "../Root.styled";
import {DropdownButtonStyled, DropdownButtonV5} from "../interaction/DropdownButtonV5";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import type {IViewFolder} from "../../../data/models/ViewUtils";
import type {View} from "../../../data/models/View";
import {ViewItemSharingPopup} from "../sharing/ViewItemSharingPopup";
import {NavigationEnum} from "../../../Enums";
import type {IDropdownElement} from "../dropdown/TopBarDropdownV5";
import {TopBarDropdownV5} from "../dropdown/TopBarDropdownV5";
import BugIcon from "../icons/bug.svg?react";
import LightbulbIcon from "../icons/lightbulb-alt.svg?react";
import NotebookIcon from "../icons/notebook.svg?react";
import KeyboardIcon from "../icons/keyboard.svg?react";
import MessageSquareExclamationIcon from "../icons/message-square-exclamation.svg?react";
import {TimeUtils} from "../../../utils/TimeUtils";
import {DropdownContentStyled} from "../dropdown/TopBarDropdown.styled";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {ShortcutWindowV5} from "../popup/ShortcutWindowV5";
import {LoginWindowStyled} from "../user/UserStaticV5.Style";
import {PopupBackdropV5} from "../popup/PopupBackdropV5";
import {BrowserWindow} from "../../../utils/device/BrowserWindow";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import {LoginWindowV5} from "../login/LoginWindowV5";
import {SpaceSelectorPopup} from "./SpaceSelectorPopup";
import {PortfolioSelector} from "./PortfolioSelector";
import type {WorkspaceViewType} from "./views/WorkspaceViewCommon";
import {NavigationViewSection} from "./views/NavigationViewSection";
import type {TMenuV5} from "./NavigationCommon";
import {NavMenuStyled} from "./NavigationCommon";
import {NavigationMenuItem} from "./NavigationMenuItem";
import {ViewFind} from "./ViewFind";

enum DatabaseEnv {
	Dev = 1,
	QA = 2,
	Prod = 3,
}

type TNavigationV5 = {
	$navBarState?: NavBarState;
	$isHome?: boolean;
	appState?: AppState;
};

interface ISideBarWidgetsProps extends IFlexStyle {
	$isHome: boolean;
	$navBarState: NavBarState;
}

const mainNavMenusV5: TMenuV5[] = [
	{
		label: "Home",
		nav: NavigationEnum.NAV_HOME,
		icon: HouseLineIcon,
	},
	{
		label: "Portfolios",
		nav: NavigationEnum.NAV_PORTFOLIOS,
		icon: BriefCaseAltIcon,
	},
	{
		label: "Catalog",
		nav: NavigationEnum.NAV_CATALOG,
		icon: BookOpenIcon,
	},
	{
		label: "Reports",
		nav: NavigationEnum.NAV_REPORTS,
		icon: ChartLineUpIcon,
	},
];

const SeparatorV5 = (props: {readonly isDocked: boolean}) => {
	return (
		<SeparatorV5Styled $isDocked={props.isDocked}>
			<span></span>
		</SeparatorV5Styled>
	);
};

export const Navigation = () => {
	const appState = useAppStore((state) => state.appState);
	const navBarState = useAppStore((state) => state.navBarState);
	const viewForOpenSpaceSelector = useAppStore((state) => state.viewForOpenSpaceSelector);
	const isPortfolioWideSearchOpen = useAppStore((state) => state.isPortfolioWideSearchOpen);
	const [createViewPopupType, setCreateViewPopupType] = useState<WorkspaceViewType | null>(null);
	const [viewFolderIdToSaveNewViewInto, setViewFolderIdToSaveNewViewInto] = useState<string>("");
	const [viewItemForSharePanel, setViewItemForSharePanel] = useState<View | IViewFolder | null>(null);
	const navigationRef = useRef<HTMLDivElement>();
	const spaceSelectorRef = useRef<HTMLDivElement>();
	const onlyIcon = navBarState === "closed";
	const isNavBarHidden = navBarState === "hidden";
	const viewsRef = useRef<HTMLDivElement>();
	const [importOpen, setImportOpen] = useState<boolean>(false);
	const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
	const navigation = navigationRef.current;
	const {localStorage: ls} = appState.app.transport.services;
	const setNavBarState = useAppStore((state) => state.setNavBarState);
	const setViewForOpenSpaceSelector = useAppStore((state) => state.setViewForOpenSpaceSelector);
	const setSearchWindowOpenState = useAppStore((state) => state.setIsPortfolioWideSearchOpen);
	const helpRef = useRef<HTMLDivElement>(null);
	const [isShortcutOpen, setIsShortcutOpen] = useState<boolean>(false);
	const [viewSearchString, setViewSearchString] = useState<string>("");
	const [isLoggedInUsingCredentials, setIsLoggedInUsingCredentials] = useState<boolean>(false);
	const [switchingOrgId, setSwitchingOrgId] = useState<string>("");

	const onMouseLeave = () => {
		if (navBarState === "open") {
			setNavBarState("closed");
		}
	};

	useMouseLeave(navigation, onMouseLeave, [appState.app.modalContainer]);

	useClickOutside([spaceSelectorRef, viewsRef, helpRef], () => {
		if (viewForOpenSpaceSelector) {
			setViewForOpenSpaceSelector(null);
		}
		if (isHelpOpen) {
			setIsHelpOpen(false);
		}
	});

	const onCreateViewClick = () => {
		if (!createViewPopupType) {
			setCreateViewPopupType("My Views");
		}
	};

	const closeCreateViewPopup = () => {
		if (createViewPopupType) {
			setCreateViewPopupType(null);
		}
	};

	const secondaryMenu: TMenuV5[] = [
		{
			label: "Spaces",
			nav: NavigationEnum.NAV_SPACES,
			icon: GridIcon,
		},
		{
			label: "Create View",
			icon: CirclePlusIcon,
			onClick: onCreateViewClick,
		},
	];

	const widgetMenus: TMenuV5[] = [
		{
			label: "Import",
			icon: ArrowDownToBracketIcon,
			onClick: () => setImportOpen(true),
		},
		{
			label: "Help",
			icon: CircleQuestionIcon,
			onClick: () => setIsHelpOpen(true),
		},
		{
			label: "Settings",
			icon: GearIcon,
			onClick: () => {
				appState.app.navigation.goApp(NavigationEnum.NAV_SETTINGS, "personal"), setNavBarState("hidden");
			},
		},
	];

	const goApp = (menu: TMenuV5) => {
		if (menu.nav) {
			appState.app.navigation.goApp(menu.nav);

			if (isPortfolioWideSearchOpen) {
				setSearchWindowOpenState(false);
			}
		}
	};

	const onNavBarClick = () => {
		if (navBarState === "closed" && !importOpen) {
			setNavBarState("open");
		}
	};

	const changeDockedStatus = (newState: NavBarState) => {
		setNavBarState(newState);

		if (newState === "closed" || newState === "docked") {
			ls.set(ls.getV5NavigationPanelDockedStateKey(appState.user.id), newState);
		}
	};

	useEffect(() => {
		const savedValueForDockedState = ls.get(ls.getV5NavigationPanelDockedStateKey(appState.user.id));

		if (savedValueForDockedState) {
			setNavBarState(savedValueForDockedState);
		}
	}, [ls, appState.user.id, setNavBarState]);

	const dockNavBar = () => changeDockedStatus("docked");
	const undockNavBar = () => changeDockedStatus("closed");

	const {
		organizations,
		organizationId,
		app: {transport},
	} = appState;

	const sortedOrganizations = useMemo(() => {
		const array: Organization[] = (organizations ? [...organizations] : []).toSorted((a: Organization, b: Organization) =>
			StringUtils.sortIgnoreCase(a.name, b.name),
		);

		const item = array.find((o) => o.id === organizationId);

		if (!item) {
			return array;
		}

		const index = array.indexOf(item);

		if (index !== -1) {
			array.splice(index, 1);
			array.unshift(item);
		}
		return array;
	}, [organizations, organizationId]);

	const openCreateViewPopup = (type: WorkspaceViewType, viewFolderId: string) => {
		setCreateViewPopupType(type);
		setViewFolderIdToSaveNewViewInto(viewFolderId);
	};

	const onViewItemShareClick = (viewItem: View | IViewFolder) => {
		setViewItemForSharePanel(viewItem);
	};

	const onToggleLiveChatBox = () => {
		const liveChat: HTMLDivElement = document.querySelector("#hubspot-messages-iframe-container.widget-align-left");

		liveChat?.classList.toggle("open");
		setIsHelpOpen(false);
	};

	const onShortCutClicked = async () => {
		if (!isShortcutOpen) {
			setIsShortcutOpen(true);
		} else {
			setIsShortcutOpen(false);
			await TimeUtils.waitForNextFrame();
			setIsShortcutOpen(true);
		}
		setIsHelpOpen(false);
	};

	const onCloseShortcut = () => {
		setIsShortcutOpen(false);
	};

	const onDocsClicked = () => {
		setIsHelpOpen(false);
		appState.app.navigation.openInNewTab("https://support.xyicon.com");
	};

	const onFeatureClicked = () => {
		setIsHelpOpen(false);
		appState.app.navigation.openInNewTab("https://support.xyicon.com/docs/submit-a-feature-request");
	};

	const onBugClicked = () => {
		setIsHelpOpen(false);
		appState.app.navigation.openInNewTab("https://support.xyicon.com/docs/report-an-issue");
	};

	const helpElements: IDropdownElement[] = [
		{
			icon: <MessageSquareExclamationIcon />,
			onMouseDown: onToggleLiveChatBox,
			label: "Live Chat",
		},
		{
			icon: <KeyboardIcon />,
			onMouseDown: onShortCutClicked,
			label: "Keyboard Shortcuts",
		},
		{
			icon: <NotebookIcon />,
			onMouseDown: onDocsClicked,
			label: "Help Documents",
		},
		{
			icon: <LightbulbIcon />,
			onMouseDown: onFeatureClicked,
			label: "Suggest a Feature",
		},
		{
			icon: <BugIcon />,
			onMouseDown: onBugClicked,
			label: "Report a Problem",
		},
	];

	const handleOrgSwitchClose = () => {
		setIsLoggedInUsingCredentials(false);
	};

	const handleSwitchFromSSOToNormalLoginSuccess = async () => {
		await transport.services.auth.switchOrganization(switchingOrgId);
	};

	const onClickOrganizationSwitch = async (org: Organization) => {
		const {
			app: {transport},
			user: {isUserLoggedInUsingXyiconCredentials},
		} = appState;
		const {isSSOEnabledForOrganization, isSSOEnabledForUser} = org;
		const {loginConst} = transport.services.auth;

		// To allow the user with the SSO path the following two condtions needs to be satisfied
		// 1. SSO is enabled for the organization
		// 2. SSO is enabled for the user #7502
		if (isSSOEnabledForOrganization && isSSOEnabledForUser) {
			const param = {
				organizationId: org.id,
				clientID: loginConst.clientId,
				clientSecret: loginConst.clientSecret,
				email: appState.user.email,
				ssoCallBackURL: `${BrowserWindow.getURLPathWithoutQuery()}#auth/ssocallback`,
				isUserLoggedInUsingXyiconCredentials: isUserLoggedInUsingXyiconCredentials.toString(),
			};
			const url = `${transport.apiUrl}/auth/ssosignin?${XHRLoader.encodeParams(param)}`;
			window.open(url, "_self");
		} else {
			if (isSSOEnabledForUser && !isSSOEnabledForOrganization) {
				// This should be there to identify the issue in case of SSO configuration from BE
				// and also as a help for FE dev and QA team
				transport.services.localStorage.set("sso-config-issue", "Something went wrong with SSO configuration from BE");
			}
			if (isUserLoggedInUsingXyiconCredentials) {
				await transport.services.auth.switchOrganization(org.id);
				// this.props.toggleOrganizationSwitch();
			} else {
				setIsLoggedInUsingCredentials(true);
				setSwitchingOrgId(org.id);
			}
		}
	};

	return (
		<Observer>
			{() => {
				// !isHome means that we are in the Workspace
				const isHome = ["portfolios", "catalog", "reports", "home"].includes(appState.selectedMenu);
				const orgLogo = appState.app.transport.getCurrentOrganizationLogo() || "";

				if (isHome && viewSearchString) {
					requestAnimationFrame(() => {
						setViewSearchString("");
					});
				}

				const getDatabaseEnv = (): DatabaseEnv => {
					let env = DatabaseEnv.Prod;
					const baseUrl = appState.app.config.api.base;

					if (baseUrl.includes("dev")) {
						env = DatabaseEnv.Dev;
					} else if (baseUrl.includes("qa")) {
						env = DatabaseEnv.QA;
					}

					return env;
				};

				const databaseEnv = getDatabaseEnv();

				const envPillMaybe: ReactNode = [DatabaseEnv.Dev, DatabaseEnv.QA].includes(databaseEnv) && (
					<EnvPill
						$color={databaseEnv === DatabaseEnv.Dev ? colorPalette.libraryColors.red : colorPalette.libraryColors.eggYolk}
						onClick={Functions.stopPropagation}
					>
						{DatabaseEnv[databaseEnv]}
					</EnvPill>
				);

				return (
					<>
						<NavigationContainerStyled
							$isDocked={navBarState === "docked"}
							$isHome={isHome}
							$isNavBarHidden={isNavBarHidden}
						>
							<NavigationStyled
								$navBarState={navBarState}
								$isHome={isHome}
								ref={navigationRef}
								onClick={onNavBarClick}
							>
								<NavOrganizationDropdownContainerStyled $isHome={isHome}>
									<DropdownButtonV5
										showSearch={true}
										parentStyle={{flex: 1}}
										horizontalAlignment={HorizontalAlignment.outerRight}
										verticalAlignment={VerticalAlignment.top}
										width="350px"
										className="org-option"
										offsetX={8}
										offsetY={0}
										optionsZIndex={zIndex.popup}
										options={sortedOrganizations.map((o) => ({
											customElement: renderOrgDropdownItem(o, appState),
											label: o.name,
											onClick: () => onClickOrganizationSwitch(o),
											isActive: o.id === appState.organizationId,
										}))}
										button={
											<OrganizationTitleStyled
												$label={navBarState === "closed" && appState.organization.name}
												$isMinimized={navBarState === "closed"}
											>
												{orgLogo ? (
													<img
														width={24}
														height={24}
														src={orgLogo}
														alt="organization thumbnail image"
													/>
												) : (
													<LogoIcon style={{minWidth: 24, height: 24}} />
												)}
												{navBarState !== "closed" && <h3>{appState.organization.name}</h3>}
											</OrganizationTitleStyled>
										}
										closeOnMouseLeave={true}
									/>
									{navBarState === "docked" ? (
										<IconButtonV5
											IconComponent={SidebarCloseIcon}
											onClick={undockNavBar}
										/>
									) : (
										navBarState === "open" && (
											<IconButtonV5
												IconComponent={SidebarOpenIcon}
												onClick={dockNavBar}
											/>
										)
									)}
								</NavOrganizationDropdownContainerStyled>
								<SeparatorV5 isDocked={navBarState !== "closed"} />
								<NavigationMidContentStyled $onlyIcon={onlyIcon}>
									<NavMenuStyled>
										{mainNavMenusV5.map((menu, i) => (
											<NavigationMenuItem
												key={menu.label}
												onlyIcon={onlyIcon}
												isActive={appState.selectedMenu === menu.nav}
												{...menu}
												onClick={() => goApp(menu)}
											/>
										))}
									</NavMenuStyled>
									<SeparatorV5 isDocked={navBarState !== "closed"} />
									{isHome ? (
										<NavMenuStyled>
											<PortfolioSelector
												isHome={isHome}
												openCreateViewPopup={openCreateViewPopup}
												onViewItemShareClick={setViewItemForSharePanel}
											/>
										</NavMenuStyled>
									) : (
										<>
											<NavMenuStyled>
												<PortfolioSelector
													isHome={isHome}
													openCreateViewPopup={openCreateViewPopup}
													onViewItemShareClick={setViewItemForSharePanel}
												/>
												{secondaryMenu.map((menu, i) => (
													<NavigationMenuItem
														onClick={() => goApp(menu)}
														isActive={appState.selectedMenu === menu.nav}
														key={menu.label}
														onlyIcon={onlyIcon}
														small={!isHome}
														{...menu}
													/>
												))}
											</NavMenuStyled>
											<SeparatorV5 isDocked={navBarState !== "closed"} />
											<ViewFind
												viewSearchString={viewSearchString}
												setViewSearchString={setViewSearchString}
											/>
											<NavigationViewSection
												openCreateViewPopup={openCreateViewPopup}
												onlyIcon={onlyIcon}
												isHome={isHome}
												onViewItemShareClick={onViewItemShareClick}
												searchString={viewSearchString}
											/>
										</>
									)}
								</NavigationMidContentStyled>
								<Flex
									$flex="1"
									style={{alignSelf: "normal"}}
								/>
								<SideBarWidgetsStyled
									$isHome={isHome}
									$navBarState={navBarState}
								>
									{envPillMaybe}
									{widgetMenus.map((menu) => (
										<NavigationMenuItem
											{...menu}
											key={menu.label}
											onlyIcon={!(isHome && navBarState !== "closed")}
											small={!isHome}
										/>
									))}
								</SideBarWidgetsStyled>
								{importOpen && <FeatureImportPanelV5 onClose={() => setImportOpen(false)} />}
								{isHelpOpen && (
									<TopBarDropdownV5
										elements={helpElements}
										parent={helpRef}
									/>
								)}
								<SpaceSelectorPopup divRef={spaceSelectorRef} />
								{createViewPopupType && (
									<CreateViewPopup
										onClose={closeCreateViewPopup}
										type={createViewPopupType}
										viewFolderIdToSaveNewViewInto={viewFolderIdToSaveNewViewInto}
									/>
								)}
								{viewItemForSharePanel && (
									<ViewItemSharingPopup
										viewItem={viewItemForSharePanel}
										onClose={() => setViewItemForSharePanel(null)}
									/>
								)}
								{isShortcutOpen && (
									<DomPortal destination={document.body}>
										<ShortcutWindowV5 onClose={onCloseShortcut} />
									</DomPortal>
								)}
							</NavigationStyled>
						</NavigationContainerStyled>
						{isLoggedInUsingCredentials && (
							<DomPortal
								destination={appState.app.modalContainer}
								noPortal={false}
							>
								<PopupBackdropV5 onClick={Functions.stopPropagation}>
									<OrganizationSwitchContainerStyled className="sso-switch">
										<LoginWindowV5
											handleClose={handleOrgSwitchClose}
											isOrganizationSwitchView={true}
											handleOrganizationSwitch={handleSwitchFromSSOToNormalLoginSuccess}
										/>
									</OrganizationSwitchContainerStyled>
								</PopupBackdropV5>
							</DomPortal>
						)}
					</>
				);
			}}
		</Observer>
	);
};

const renderOrgDropdownItem = (org: Organization, appState: AppState) => {
	const logo = appState.app.transport.getOrganizationLogoById(org.id);

	return (
		<OrgItem>
			<div className="title">
				{logo ? (
					<img
						width={24}
						height={24}
						src={logo}
						alt={`${org.name} logo thumbnail`}
					/>
				) : (
					<LogoIcon />
				)}
				<span>{org.name}</span>
			</div>
			{appState.organizationId === org.id && <CheckboxInputV5 value={true} />}
		</OrgItem>
	);
};

const OrganizationSwitchContainerStyled = styled.div`
	${LoginWindowStyled} {
		background: none;
	}
`;

const EnvPill = styled.div<{$color: string}>`
	background-color: ${(props) => props.$color};
	border-radius: ${radius.xl};
	height: 24px;
	width: 40px;
	color: ${colorPalette.white};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: default;
`;

const panelWidths = {
	home: "200px",
	workspace: "288px",
	minimized: "64px",
};

const NavigationContainerStyled = styled.div<{$isDocked: boolean; $isHome: boolean; $isNavBarHidden: boolean}>`
	${(props) =>
		props.$isNavBarHidden &&
		css`
			display: none;
		`}
	position: relative;
	width: ${(props) => (props.$isDocked ? (props.$isHome ? panelWidths.home : panelWidths.workspace) : panelWidths.minimized)};
	height: 100vh;
	min-width: ${panelWidths.minimized};
`;

const NavOrganizationDropdownContainerStyled = styled.div<{$isHome: boolean}>`
	${FlexCenterStyle};
	gap: ${baseDistance.xs};
	justify-content: space-between;

	h3 {
		font-weight: ${fontWeight.bold};
		font-size: ${fontSize.md};
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.org-option {
		max-height: 400px;

		li {
			&:hover {
				background-color: ${colorPalette.gray.c200Light};
				color: ${colorPalette.gray.c950};
			}
		}
	}

	${(props) => {
		if (!props.$isHome) {
			return css`
				.IconButton:hover {
					background-color: ${colorPalette.blueGray.c200Light};
				}
			`;
		}
	}}
`;

const NavigationStyled = styled.div<TNavigationV5>`
	${VerticalFlexStyle}
	background-color: ${(props) => (props.$isHome ? colorPalette.white : colorPalette.blueGray.c100)};
	transition: ease-out width 0.2s;
	padding: ${baseDistance.md};
	box-sizing: border-box;
	gap: ${baseDistance.md};
	border-right: solid 1px ${colorPalette.gray.c300};
	cursor: pointer;
	width: ${(props) => (props.$navBarState === "docked" ? (props.$isHome ? panelWidths.home : panelWidths.workspace) : "100%")};
	position: absolute;
	z-index: 10;
	height: 100%;

	${NavOrganizationDropdownContainerStyled} > ${DropdownButtonStyled}, ${NavMenuStyled} > ${DropdownButtonStyled} {
		&:hover,
		&.isOpen {
			background-color: ${colorPalette.blueGray.c200Light};
		}
	}

	${NavOrganizationDropdownContainerStyled} > ${DropdownButtonStyled} {
		max-width: ${(props) => (props.$navBarState !== "closed" ? "calc(100% - 34px);" : "initial")};
	}

	${(props) => {
		if (props.$navBarState === "open") {
			return css`
				position: absolute;
				width: ${props.$isHome ? panelWidths.home : panelWidths.workspace};
				height: 100vh;
				z-index: 10;
			`;
		}
	}}

	${DropdownContentStyled} {
		left: 8px;
		top: -52px;
	}
`;

const OrganizationTitleStyled = styled.div<{$isMinimized: boolean; $label: string}>`
	${FlexCenterStyle}
	flex: 1;
	gap: ${baseDistance.sm};
	position: relative;
	border-radius: ${radius.sm};
	min-width: 32px;

	${(props) => css`
		padding: ${props.$isMinimized ? "4px" : "0 4px"};

		img {
			margin: ${props.$isMinimized ? "0" : "4px 0"};
		}
	`}

	img {
		border-radius: ${radius.sm};
		background-color: ${colorPalette.white};
	}

	${pseudoLabelTooltipStyle}
`;

const NavigationMidContentStyled = styled.div<{$onlyIcon: boolean}>`
	${VerticalFlexStyle};
	gap: ${baseDistance.md};
	overflow-y: ${(props) => (props.$onlyIcon ? "initial" : "auto")};
	&::-webkit-scrollbar {
		display: ${(props) => (props.$onlyIcon ? "initial" : "none")};
	}
`;

const SideBarWidgetsStyled = styled.div<ISideBarWidgetsProps>`
	${(props) => (props.$isHome || (!props.$isHome && props.$navBarState === "closed") ? VerticalFlexStyle : FlexStyle)};
	${(props) => (!props.$isHome && props.$navBarState !== "closed" ? "flex-direction: row-reverse" : "")};
	${(props) => (props.$navBarState !== "closed" ? "justify-content: flex-end" : "")};
	gap: ${(props) => (props.$isHome ? baseDistance.sm : baseDistance.xs)};
	align-items: ${(props) => (props.$isHome && props.$navBarState !== "closed" ? "flext-start" : "center")};
`;

const SeparatorV5Styled = styled.div<{$isDocked: boolean}>`
	${FlexCenterStyle}
	justify-content: center;
	align-self: normal;

	span {
		width: ${(props) => (!props.$isDocked ? "16px" : "100%")};
		height: 2px;
		border-radius: ${radius.sm};
		background-color: ${colorPalette.gray.c300};
	}
`;

const OrgItem = styled.div`
	display: flex;
	gap: ${baseDistance.sm};
	justify-content: space-between;
	width: 100%;

	.title {
		display: flex;
		gap: ${baseDistance.sm};
	}

	svg {
		min-width: 24px;
	}
`;
