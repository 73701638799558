import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import type {TransformObj} from "../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import {AppUtils} from "../../../../../utils/AppUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {MathUtils} from "../../../../../utils/math/MathUtils";
import {DomPortal} from "../../../../modules/abstract/portal/DomPortal";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import {useAppStore} from "../../../../../StateManager";
import {baseDistance, ELLIPSIS, FLEXCENTER, fontSize, radius, zIndex} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {useClickOutside} from "../../../utils";
import {SearchFieldStyled} from "../../../input/search/SearchField.styled";
import {SelectedOptionStyled} from "../../../input/search/SelectSearchFieldV5";
import {PillsStyle} from "../../../sharing/SharingPanel.styled";
import type {IMultiSelectOption} from "../../../../../data/models/field/datatypes/MultiSelect";
import RemoveAllIcon from "../../../icons/xmark.svg?react";
import {PopupV5} from "../../../popup/PopupV5";
import type {IFieldAdapter} from "../../../../../data/models/field/Field";
import {Functions} from "../../../../../utils/function/Functions";
import {ColorUtils} from "../../../../../utils/ColorUtils";
import {MultiSelectInputStyled} from "./MultiSelect.styled";

interface IMultiSelectInputProps<T> {
	readonly options: T[];
	readonly selected?: T[];
	readonly disabled?: boolean;
	readonly searchBar?: boolean;
	readonly noFixedPosition?: boolean;
	readonly focused?: boolean;
	readonly inline?: boolean;
	readonly isSameWidth?: boolean;
	readonly field?: IFieldAdapter;

	readonly render?: (item: T) => React.ReactNode;
	readonly onChange?: (selectedOptions: T[]) => void;
	readonly onClick?: (e: React.MouseEvent) => void;
}

enum MultiSelectInputState {
	Default = 1,
	Expanded = 2,
	Editing = 3,
}

export const MultiSelectInputV5 = <T,>({
	options = [],
	disabled = false,
	render = (option: any) => option,
	searchBar = true,
	isSameWidth = false,
	selected,
	noFixedPosition,
	focused,
	inline,
	field,
	onChange,
	onClick,
}: IMultiSelectInputProps<T>) => {
	let _element = useRef<HTMLDivElement>();
	let _list = useRef<HTMLDivElement>();

	const appState = useAppStore((store) => store.appState);

	const [search, setSearch] = useState<string>("");
	const [multiSelectState, setMultiSelectState] = useState<MultiSelectInputState>(MultiSelectInputState.Default);
	const [transform, setTransform] = useState<TransformObj>(null);
	const [isMultiSelectHovered, setIsMultiSelectHovered] = useState<boolean>(false);

	const onToggleValue = (value: T, event?: React.MouseEvent) => {
		// if T is an object selected does not includes value
		const valueToRemove = selected.find((o) => value === o);

		event?.stopPropagation();

		if (valueToRemove) {
			const newSelected = ArrayUtils.remove(selected, valueToRemove);

			dispatchChange(newSelected);
		} else {
			const newSelected = ArrayUtils.add(selected, value);

			dispatchChange(newSelected);
		}
	};

	const dispatchChange = (options: T[]) => {
		let values = options.filter((item) => item && !Array.isArray(item));
		values = ArrayUtils.uniq(values);
		onChange?.(values);
	};

	const onListClick = useCallback(
		(event?: React.MouseEvent) => {
			onClick?.(event);

			setMultiSelectState(MultiSelectInputState.Editing);

			AppUtils.disableScrolling(true);
		},
		[onClick],
	);

	const onClose = () => {
		AppUtils.disableScrolling(false);

		setMultiSelectState(MultiSelectInputState.Default);
		setSearch("");
	};

	const filterOption = (option: T) => {
		if (search) {
			const reactElement = render(option);
			const text = (ReactUtils.getTextContent(reactElement as string) || "").toLowerCase();

			return text.includes(search.toLowerCase());
		}

		return true;
	};

	const onSearchStringChange = (value: string) => {
		setSearch(value);
	};

	const onRemoveAll = (event: React.MouseEvent) => {
		event.stopPropagation();
		dispatchChange([]);
	};

	const expandList = (e: React.MouseEvent) => {
		e.stopPropagation();
		setMultiSelectState(MultiSelectInputState.Expanded);
	};

	const shrinkList = (e: React.MouseEvent) => {
		e.stopPropagation();
		setMultiSelectState(MultiSelectInputState.Default);
	};

	const getCurrentState = () => {
		const removedSelected = selected.filter((option) => !options.some((o) => option === o));
		const allOptions = [...options, ...removedSelected];
		const maxItemCount = 10;

		if (selected.length === 0 && multiSelectState !== MultiSelectInputState.Editing) {
			return null;
		} else {
			switch (multiSelectState) {
				case MultiSelectInputState.Default:
					return (
						<div onClick={onListClick}>
							<MultiSelectOptionStyled>
								{selected
									.toSorted((a, b) => StringUtils.sortIgnoreCase(render(a).toString(), render(b).toString()))
									.map((item, index) => {
										if (index < maxItemCount) {
											return (
												<PillsStyle
													className="selectedItem"
													key={`${(item as IMultiSelectOption).value ?? (item as string)}_${index}`}
													$color={(item as IMultiSelectOption).color ? `#${(item as IMultiSelectOption).color}` : colorPalette.gray.c100}
												>
													<span>{render(item)}</span>
													<span
														onClick={(e) => onToggleValue(item, e)}
														className="close"
													>
														&times;
													</span>
												</PillsStyle>
											);
										}
									})}
							</MultiSelectOptionStyled>
							<BelowItemsStyled>
								{selected.length > 0 && (
									<RemoveAllIconStyled
										$isHovered={isMultiSelectHovered}
										onClick={onRemoveAll}
									/>
								)}
								{selected.length > maxItemCount && (
									<MoreButtonStyled onClick={expandList}>{`Show ${selected.length - maxItemCount} More`}</MoreButtonStyled>
								)}
							</BelowItemsStyled>
						</div>
					);
				case MultiSelectInputState.Expanded:
					return (
						<div onClick={onListClick}>
							<MultiSelectOptionStyled>
								{selected
									.toSorted((a, b) => StringUtils.sortIgnoreCase(render(a).toString(), render(b).toString()))
									.map((item, index) => (
										<PillsStyle
											className="selectedItem"
											key={`${(item as IMultiSelectOption).value ?? (item as string)}_${index}`}
											$color={(item as IMultiSelectOption).color ? `#${(item as IMultiSelectOption).color}` : colorPalette.gray.c100}
										>
											<span>{render(item)}</span>
											<span
												onClick={(e) => onToggleValue(item, e)}
												className="close"
											>
												&times;
											</span>
										</PillsStyle>
									))}
							</MultiSelectOptionStyled>
							<BelowItemsStyled>
								{selected.length > 0 && (
									<RemoveAllIconStyled
										$isHovered={isMultiSelectHovered}
										onClick={onRemoveAll}
									/>
								)}
								{selected.length > maxItemCount && <MoreButtonStyled onClick={shrinkList}>Show less</MoreButtonStyled>}
							</BelowItemsStyled>
						</div>
					);
				case MultiSelectInputState.Editing:
					if (allOptions.length === 0) {
						return (
							<EmptyMultiSelectPopupStyled
								label={field?.name}
								onClose={onClose}
								buttonProps={{
									label: "Add Item",
									onClick: Functions.emptyFunction,
									hideIcon: true,
								}}
								parentRef={_element.current}
								isSmallPopup={true}
							>
								<AddButtonStyled>Add Multiple items</AddButtonStyled>
							</EmptyMultiSelectPopupStyled>
						);
					} else {
						const filteredOptions = allOptions.filter(filterOption);
						const realParentId = MathUtils.getNewRandomGUID();

						return (
							<div
								className="list"
								id={realParentId}
							>
								<DomPortal destination={appState.app.modalContainer}>
									<DropdownStyled
										className={ReactUtils.cls({inline})}
										ref={_list}
										style={{width: isSameWidth && _element.current.offsetWidth}}
										$noFixedPosition={noFixedPosition}
										$translate={transform?.translate ?? ""}
									>
										{options.length > 5 && searchBar && (
											<SearchFieldV5
												value={search}
												onInput={onSearchStringChange}
												placeholder="Find"
												autoFocus={true}
											/>
										)}
										<ListStyled>
											{filteredOptions.map((option, index) => (
												<ListItemOuterStyled
													key={`${(option as IMultiSelectOption).value ?? (option as string)}_${index}`}
													className={ReactUtils.cls({selected: selected.includes(option)})}
												>
													<ListItemStyled
														onClick={() => onToggleValue(option)}
														className={ReactUtils.cls({removed: !options.includes(option), selected: selected.includes(option)})}
														$color={`#${(option as IMultiSelectOption).color || colorPalette.gray.c100.substring(1)}`}
													>
														<div className="label">{render(option)}</div>
													</ListItemStyled>
												</ListItemOuterStyled>
											))}
										</ListStyled>
										{/* <OptionsLabelStyled>
											<ButtonV5
												onClick={() => true}
												label="Add Item"
											>
												<CirclePlusIcon />
											</ButtonV5>
											<ButtonV5
												onClick={() => true}
												label="Edit List"
											>
												<PenLineIcon />
											</ButtonV5>
										</OptionsLabelStyled> */}
									</DropdownStyled>
								</DomPortal>
							</div>
						);
					}
			}
		}
	};

	useClickOutside([_element, _list], onClose, "mousedown", []);

	useEffect(() => {
		if (_element.current && _list.current && multiSelectState === MultiSelectInputState.Editing) {
			setTransform(
				DomUtils.getFixedFloatingElementPosition(_element.current, _list.current, VerticalAlignment.bottomOuter, HorizontalAlignment.center, 8, 0),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_element.current, _list.current, setTransform, multiSelectState]);

	useEffect(() => {
		if (!disabled) {
			if (focused) {
				onListClick();
			} else {
				onClose();
			}
		}
	}, [focused, onListClick, disabled]);

	{
		return (
			!(disabled && selected.length === 0) && (
				<MultiSelectInputStyled
					ref={_element}
					onClick={onListClick}
					className={ReactUtils.cls({
						disabled,
						inline,
						empty: selected.length === 0,
						cellContent: inline,
					})}
					$emptyInline={inline && selected.length === 0}
					onMouseOver={() => setIsMultiSelectHovered(true)}
					onMouseLeave={() => setIsMultiSelectHovered(false)}
				>
					{getCurrentState()}
				</MultiSelectInputStyled>
			)
		);
	}
};

const MultiSelectOptionStyled = styled(SelectedOptionStyled)`
	display: flex;
	flex-direction: column;
`;

const DropdownStyled = styled.div<{$noFixedPosition: boolean; $translate: string}>`
	z-index: ${zIndex.dropdowns};
	background-color: ${colorPalette.white};
	max-height: 288px;
	transform: ${(props) => (props.$noFixedPosition ? "" : props.$translate)};
	position: ${(props) => (props.$noFixedPosition ? "absolute" : "fixed")};
	box-shadow: 0px 4px 8px 0px #00000033;
	padding: ${baseDistance.sm} ${baseDistance.sm} ${baseDistance.xs} ${baseDistance.sm};
	border-radius: ${radius.md};
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.sm};

	${SearchFieldStyled} {
		width: 100%;

		input {
			height: 32px;
		}
	}
`;

const ListStyled = styled.div`
	overflow: auto;
	display: flex;
	flex-direction: column;
`;

const ListItemOuterStyled = styled.div`
	min-height: 32px;
	border-radius: ${radius.sm};
	padding: ${baseDistance.xs};
	border: 1px solid transparent;
	background-color: ${colorPalette.white};
	${FLEXCENTER}

	&.selected {
		border-color: ${colorPalette.primary.c500Primary};
		background-color: ${colorPalette.primary.c200Light};
	}
`;

const ListItemStyled = styled.div<{$color?: string}>`
	display: flex;
	gap: ${baseDistance.sm};
	height: 24px;
	width: 100%;
	align-items: center;
	cursor: pointer;
	border-radius: ${radius.sm};
	background-color: ${(props) => props.$color ?? `${colorPalette.gray.c100}`};
	color: ${(props) => (ColorUtils.hex2hsl(props.$color).l > 0.9 ? "black" : colorPalette.white)};
	padding-left: ${baseDistance.xs};
	
	.label {
		${ELLIPSIS};
	}
`;

const MoreButtonStyled = styled.div`
	display: flex;
	padding-left: ${baseDistance.xs};
	color: ${colorPalette.primary.c500Primary};
	font-size: ${fontSize.sm};
	line-height: 16px;
	letter-spacing: 0.0025em;
	cursor: pointer;
`;

const BelowItemsStyled = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;

	svg {
		cursor: pointer;
	}
`;

const AddButtonStyled = styled.div`
	color: ${colorPalette.primary.c500Primary};
	text-decoration: underline;
	display: flex;
	flex-direction: row-reverse;
	cursor: pointer;
`;

const RemoveAllIconStyled = styled(RemoveAllIcon)<{$isHovered: boolean}>`
	visibility: ${(props) => (props.$isHovered ? "visible" : "hidden")};
	width: 16px;
	height: 16px;
	margin-right: 4px;
`;

const EmptyMultiSelectPopupStyled = styled(PopupV5)`
	.popupButton {
		${FLEXCENTER}
	}
`;
