import styled, {css} from "styled-components";
import {colorPalette} from "../styles/colorPalette";
import {baseDistance, ELLIPSIS, FlexCenterStyle, fontSize, radius, VerticalFlexStyle} from "../styles/styles";
import {ColorUtils} from "../../../utils/ColorUtils";

export const SharingStyled = styled.div`
	${VerticalFlexStyle};
	height: 478px;

	.container {
		display: flex;
		justify-content: space-between;
	}
`;

export const AddItemStyled = styled.div`
	height: 478px;

	.container {
		display: flex;
		justify-content: space-between;
	}
`;

export const SharingPanelStyled = styled.div<{$isDropdown?: boolean; $isGroupEditMode?: boolean}>`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: ${({$isDropdown}) => ($isDropdown ? "0px" : "8px 0")};
	border-radius: ${radius.sm};
	border: 1px solid ${colorPalette.gray.c300};

	height: ${({$isGroupEditMode}) => ($isGroupEditMode ? "432px" : "auto")};
	max-height: 432px;
	overflow-y: auto;

	.borderBottom {
		border-bottom: 1px solid ${colorPalette.gray.c300};
	}

	.section {
		padding: 0 ${baseDistance.sm};
	}

	${({$isDropdown}) =>
		$isDropdown &&
		css`
			overflow-y: auto;
		`}
`;

export const RowStyled = styled.div`
	${FlexCenterStyle};
	gap: ${baseDistance.xs};
	padding: ${baseDistance.sm};
	cursor: pointer;

	.unshare {
		background-color: ${colorPalette.negative.c500Primary};
		height: 24px;
		font-size: ${fontSize.sm};
		margin-left: auto;
		display: none;

		&:hover {
			background-color: ${colorPalette.negative.c700Dark};
		}
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
		border-radius: ${radius.sm};

		.unshare {
			display: flex;
		}
	}

	.sharedStyles {
		margin-left: auto;
		border: none;
		color: ${colorPalette.gray.c950};
		font-size: ${fontSize.sm};
		height: 24px;
		padding: ${baseDistance.xs};

		&.isOpen {
			border: 1px solid ${colorPalette.gray.c950};
		}

		&.disabled {
			margin-right: 10px;
		}

		svg {
			width: 12px;
			height: 12px;
		}
	}

	.shareOption {
		width: 80px;
	}

	&.selected {
		color: ${colorPalette.primary.c500Primary};
		background-color: ${colorPalette.primary.c200Light};
	}
`;

export const NameContainerStyled = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-left: ${baseDistance.sm};
	${ELLIPSIS};

	> div {
		max-width: 100%;
		${ELLIPSIS};
	}

	.name {
		line-height: 24px;
	}

	.counter,
	.email {
		color: ${colorPalette.gray.c700Dark};
		font-size: ${fontSize.sm};
		line-height: 16px;
	}
`;

export const SectionTitle = styled.div`
	padding: ${baseDistance.sm};
	font-size: ${fontSize.sm};
	color: ${colorPalette.gray.c700Dark};
`;

export const AddUserOrUserGroupStyled = styled.div`
	.SearchField {
		width: 448px;
		margin-right: ${baseDistance.sm};
	}
`;

export const DropdownSelectorStyled = styled.div<{$isDropdown?: boolean}>`
	padding: ${({$isDropdown}) => ($isDropdown ? "0px" : baseDistance.sm)};
	background: ${colorPalette.white};
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
	border-radius: ${radius.sm};
	position: absolute;
	width: 416px;
`;

export const PillsStyle = styled.div<{$color?: string}>`
	display: inline-flex;
	align-items: center;
	background-color: ${(props) => props.$color};
	color: ${(props) => (ColorUtils.hex2hsl(props.$color).l > 0.83 ? "black" : colorPalette.white)};
	border-radius: ${radius.sm};
	padding: ${baseDistance.xs};
	margin: ${baseDistance.xs};
	font-size: ${fontSize.md};
	cursor: pointer;
	height: 24px;
	justify-content: space-between;

	span {
		padding-right: ${baseDistance.sm};

		&:not(.close) {
			${ELLIPSIS}
		}

		&.close {
			visibility: hidden;
		}
	}

	&:hover {
		span.close {
			visibility: visible;
			padding-bottom: 2px;
		}
	}

	svg {
		max-width: 12px;
		height: 12px;
		fill: ${colorPalette.gray.c950};
		cursor: pointer;
	}
`;
