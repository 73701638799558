import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {Pointer} from "../../../../../../../utils/interaction/Pointer";
import {Tool} from "./Tool";
import type {ToolType} from "./Tools";

export class MoveBackgroundTool extends Tool {
	protected override _toolType: ToolType = "moveBackground";

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, false, "grab");
	}

	protected override onPointerDownCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		this._domElement.style.cursor = "grabbing";
		this._spaceViewRenderer.tileManager.onPointerDown(worldX, worldY);
	};

	protected override onPointerMoveCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		this._spaceViewRenderer.tileManager.onPointerMove(worldX, worldY);
	};

	protected override onPointerUpCallback = () => {
		this._domElement.style.cursor = "grab";
		this._spaceViewRenderer.tileManager.onPointerUp();
	};
}
