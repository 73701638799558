import {makeObservable, observable} from "mobx";
import {Signal} from "../utils/signal/Signal";
import type {PhotoSphereSceneManager} from "./PhotoSphereSceneManager";
import type {PhotoSphere} from "./PhotoSphere";
import {HotSpot} from "./css3d/HotSpot";
import type {ITransition} from "./PhotoSphereTypes";
import {ImageUtils} from "./utils/ImageUtils";

export interface ITextureData {
	textureData: Exclude<TexImageSource, VideoFrame>;
	viewBox: number[];
	photoSphere: PhotoSphere;
}

export class PhotoSphereManager {
	private readonly _photoSphereSceneManager: PhotoSphereSceneManager;
	private _imgCache: {[id: string]: Promise<ITextureData>} = {};

	@observable
	public activePhotoSphereMaybe: PhotoSphere | null = null;

	@observable
	public isPanoramaLoading: boolean = false;

	public signals = {
		onPhotoSphereLoaded: Signal.create<PhotoSphere>(),
	};

	constructor(photoSphereSceneManager: PhotoSphereSceneManager) {
		this._photoSphereSceneManager = photoSphereSceneManager;
		makeObservable(this);
	}

	private async loadImage(photoSphere: PhotoSphere, onProgress?: (url: string, ratio: number) => void): Promise<ITextureData | undefined> {
		try {
			if (!this._imgCache[photoSphere.file]) {
				const file = photoSphere.file;
				this._imgCache[photoSphere.file] = ImageUtils.getImageForTexture(
					file,
					photoSphere,
					this._photoSphereSceneManager.webGLRenderer.maxTextureSize,
					photoSphere.file,
					onProgress,
				);
			}

			return await this._imgCache[photoSphere.file];
		} catch (error) {
			console.log(error);
		}
	}

	public selectPhotoSphere = (photoSphere: PhotoSphere, headingAngle: number, playMovementEffect: boolean = false) => {
		return new Promise<boolean>((resolve, reject) => {
			requestAnimationFrame(async () => {
				this.isPanoramaLoading = true;
				const texData = await this.loadImage(photoSphere);
				this.isPanoramaLoading = false;

				const hotSpots = this.getHotSpots(photoSphere);
				if (texData) {
					this.activePhotoSphereMaybe = photoSphere;
					this._photoSphereSceneManager.loadPhotoSphere(texData, playMovementEffect, hotSpots, headingAngle ?? 0, this.selectPhotoSphere);
					this.signals.onPhotoSphereLoaded.dispatch(photoSphere);
				}

				requestAnimationFrame(() => {
					resolve(true);
				});
			});
		});
	};

	public getHotSpots(photoSphere: PhotoSphere) {
		return photoSphere.transitions.map((transition: ITransition) => new HotSpot(this, transition));
	}

	public get photoSphereSceneManager() {
		return this._photoSphereSceneManager;
	}
}
