import {inject, observer} from "mobx-react";
import * as React from "react";
import type {Boundary} from "../../../../data/models/Boundary";
import type {AppState} from "../../../../data/state/AppState";
import {Permission} from "../../../../generated/api/base";
import {FieldV5} from "../../details/FieldV5";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import type {Type} from "../../../../data/models/Type";
import {DefaultFieldsUtils} from "../../../modules/abstract/sidepanel/tabs/details/default/DefaultFieldsUtils";
import {ConfirmWindowV5} from "../../popup/ConfirmWindowV5";

interface IBoundaryDefaultFieldsProps {
	readonly item: Boundary;
	readonly appState?: AppState;
}

@inject("appState")
@observer
export class BoundaryDefaultFieldsV5 extends React.Component<IBoundaryDefaultFieldsProps> {
	private onTypeChange = async (type: Type) => {
		const {item, appState} = this.props;
		const {spaceViewRenderer} = appState.app;

		const title = "Confirm boundary type change";
		const message =
			"Once you change the boundary type, fields (and data) not assigned to the new boundary type will be removed. Do you wish to continue?";
		const config = {
			ok: "Change",
			cancel: "Cancel",
		};

		const confirmed = await ConfirmWindowV5.open(message, title, config);

		if (confirmed) {
			DefaultFieldsUtils.changeTypeOfBoundary(item, type, spaceViewRenderer);
		}
	};

	private get hasPermission() {
		const {item, appState} = this.props;

		return appState.actions.getModuleTypePermission(item.typeId, item.ownFeature) >= Permission.Update;
	}

	public override render() {
		const {item, appState} = this.props;

		return (
			<>
				<FieldV5
					label={appState.actions.getRefIdName(item.ownFeature)}
					className="stucked"
				>
					{item.refId}
				</FieldV5>
				<FieldV5
					className="TypeSelector"
					label="Type"
				>
					<SelectInputV5
						options={this.props.appState.types[item.ownFeature]}
						render={(item) => item.name}
						selected={appState.actions.getTypeById(item.typeId)}
						onChange={this.onTypeChange}
						disabled={!this.hasPermission}
						isSameWidth={true}
					/>
				</FieldV5>
			</>
		);
	}
}
