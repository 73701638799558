import {inject, observer} from "mobx-react";
import * as React from "react";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {Space} from "../../../../data/models/Space";
import type {AppState} from "../../../../data/state/AppState";
import {XyiconFeature, Permission, FieldDataType} from "../../../../generated/api/base";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {Functions} from "../../../../utils/function/Functions";
import {FieldV5} from "../../details/FieldV5";
import {ClickToEditInputV5} from "../../input/clicktoedit/ClickToEditInputV5";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import type {Type} from "../../../../data/models/Type";
import {ConfirmWindowV5} from "../../popup/ConfirmWindowV5";

interface ISpaceDefaultFieldsProps {
	readonly item: Space;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface ISpaceDefaultFieldsState {
	updating: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class SpaceDefaultFieldsV5 extends React.Component<ISpaceDefaultFieldsProps, ISpaceDefaultFieldsState> {
	constructor(props: ISpaceDefaultFieldsProps) {
		super(props);
		this.state = {
			updating: false,
		};
	}

	private isNameValid = (name: string) => {
		return this.props.appState.actions.isSpaceNameValid(name, this.props.item);
	};

	private getErrorMessage = (name: string) => {
		return !name.trim() ? "Name cannot be empty!" : this.isNameValid(name) ? "" : "Value needs to be unique!";
	};

	private onNameChange = async (value: string) => {
		const {item, transport} = this.props;

		if (this.isNameValid) {
			item.name = value;
			this.setState({updating: true});
			await TimeUtils.waitUpdate(transport.appState.actions.updateFields([item], {name: value}), this.props.appState.app.notificationContainer);
			this.setState({updating: false});
		}
	};

	private onChange = async (type: Type) => {
		const {item} = this.props;

		const title = "Confirm Space Type Change";
		const message = "Once you change the space type, fields (and data) not assigned to the new space type will be removed. Do you wish to continue?";
		const config = {
			ok: "Change",
			cancel: "Cancel",
		};

		const confirmed = await ConfirmWindowV5.open(message, title, config);

		if (confirmed) {
			item.setSpaceType(type.id);
			await this.props.transport.updateSpaceType(item);
		}
	};

	private get spacePermission() {
		return this.props.appState.actions.getModuleTypePermission(this.props.item.typeId, this.props.item.ownFeature);
	}

	public override render() {
		const {item, appState} = this.props;
		const {updating} = this.state;

		return (
			<>
				<FieldV5
					label={appState.actions.getRefIdName(item.ownFeature)}
					className="space"
				>
					{item.refId}
				</FieldV5>
				<FieldV5
					className="TypeSelector space"
					label="Type"
				>
					<SelectInputV5
						options={this.props.appState.types[XyiconFeature.Space]}
						render={(item) => <span>{item.name}</span>}
						selected={item.type}
						onChange={this.onChange}
						disabled={this.spacePermission < Permission.Update}
						isSameWidth={true}
					/>
				</FieldV5>
				<FieldV5
					label="Space Name"
					className="space stucked"
				>
					<ClickToEditInputV5
						value={item.name}
						onChange={this.onNameChange}
						dataType={FieldDataType.SingleLineText}
						updating={updating}
						onBlur={Functions.emptyFunction}
						getErrorMessage={this.getErrorMessage}
						disabled={this.spacePermission < Permission.Update}
					/>
				</FieldV5>
			</>
		);
	}
}
