import {NavigationEnum} from "../../../Enums";
import type {Space} from "../../../data/models/Space";
import type {View} from "../../../data/models/View";
import {XyiconFeature} from "../../../generated/api/base";
import {notify} from "../../../utils/Notify";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {NotificationType} from "../../notification/Notification";

export const EventNameForViewsChangeInLocalStorage = "viewsModifiedInLocalStorage";
const getLocalStorageKeyForWorkspaceViews = (userId: string, orgId: string) => `workspace_viewtabs_${userId}_${orgId}`;
const getLocalStorageKeyForActiveWorkspaceView = (userId: string, orgId: string) => `active_workspace_viewtabs_${userId}_${orgId}`;

interface IViewTab {
	viewId: string;
	lastUsed: number; // Date.now()
}

export const getWorkspaceViewTabsFromLocalStorage = (userId: string, orgId: string): IViewTab[] => {
	const localStorageKey = getLocalStorageKeyForWorkspaceViews(userId, orgId);
	const localStorageValue = localStorage.getItem(localStorageKey);
	const viewIdsInLocalStorage: IViewTab[] = localStorageValue ? JSON.parse(localStorageValue) : [];

	return viewIdsInLocalStorage;
};

export const getActiveWorkspaceViewTabFromLocalStorage = (userId: string, orgId: string): IViewTab | null => {
	const localStorageKey = getLocalStorageKeyForActiveWorkspaceView(userId, orgId);
	const localStorageValue = localStorage.getItem(localStorageKey);
	return localStorageValue ? JSON.parse(localStorageValue) : null;
};

export const removeAllWorkspaceViewTabsExceptSelected = (userId: string, orgId: string) => {
	const localStorageKeyForWorkspaceViews = getLocalStorageKeyForWorkspaceViews(userId, orgId);
	const activeViewTab = getActiveWorkspaceViewTabFromLocalStorage(userId, orgId);

	localStorage.setItem(localStorageKeyForWorkspaceViews, JSON.stringify([activeViewTab]));
	window.dispatchEvent(new Event(EventNameForViewsChangeInLocalStorage));
};

export const removeWorkspaceViewFromTabs = (view: View, setViewForOpenSpaceSelector: (value: View) => void) => {
	const appState = view.appState;
	const userId = appState.user?.id;
	const orgId = appState.organizationId;
	const viewTabsInLocalStorage = getWorkspaceViewTabsFromLocalStorage(userId, orgId);
	const activeViewTab = getActiveWorkspaceViewTabFromLocalStorage(userId, orgId);

	const indexOfViewToBeDeleted = viewTabsInLocalStorage.findIndex((v) => v.viewId === view.id);

	if (indexOfViewToBeDeleted !== -1) {
		viewTabsInLocalStorage.splice(indexOfViewToBeDeleted, 1);

		const localStorageKeyForWorkspaceViews = getLocalStorageKeyForWorkspaceViews(appState.user?.id, appState.organizationId);

		localStorage.setItem(localStorageKeyForWorkspaceViews, JSON.stringify(viewTabsInLocalStorage));

		if (activeViewTab.viewId === view.id) {
			if (viewTabsInLocalStorage.length === 0) {
				const newlySelected = appState.actions.getSelectedView(view.itemFeature);

				onWorkspaceViewClick(newlySelected);
			} else {
				let viewTabMostRecentlyUsed = viewTabsInLocalStorage[0];

				for (let i = 1; i < viewTabsInLocalStorage.length; ++i) {
					if (viewTabMostRecentlyUsed.lastUsed < viewTabsInLocalStorage[i].lastUsed) {
						viewTabMostRecentlyUsed = viewTabsInLocalStorage[i];
					}
				}

				onWorkspaceViewClick(appState.actions.getViewById(viewTabMostRecentlyUsed.viewId));
			}
		} else {
			window.dispatchEvent(new Event(EventNameForViewsChangeInLocalStorage));
		}
	}
};

export const onWorkspaceViewSelect = (view: View, forcePutInBeginningOfList: boolean = false) => {
	const appState = view.appState;

	appState.actions.selectView(view);
	const viewTabsInLocalStorage = getWorkspaceViewTabsFromLocalStorage(appState.user?.id, appState.organizationId);

	const newViewTab: IViewTab = {
		viewId: view.id,
		lastUsed: Date.now(),
	};

	const itemIndexInListMaybe = viewTabsInLocalStorage.findIndex((v) => v.viewId === view.id);

	if (itemIndexInListMaybe !== -1) {
		const itemInListMaybe = viewTabsInLocalStorage[itemIndexInListMaybe];

		itemInListMaybe.lastUsed = Date.now();

		if (forcePutInBeginningOfList) {
			viewTabsInLocalStorage.splice(itemIndexInListMaybe, 1);
			viewTabsInLocalStorage.unshift(newViewTab);
		}
	} else {
		viewTabsInLocalStorage.unshift(newViewTab);
	}

	const localStorageKeyForWorkspaceViews = getLocalStorageKeyForWorkspaceViews(appState.user?.id, appState.organizationId);

	localStorage.setItem(localStorageKeyForWorkspaceViews, JSON.stringify(viewTabsInLocalStorage));
	const localStorageKeyForActiveWorkspaceView = getLocalStorageKeyForActiveWorkspaceView(appState.user?.id, appState.organizationId);

	localStorage.setItem(localStorageKeyForActiveWorkspaceView, JSON.stringify(newViewTab));

	window.dispatchEvent(new Event(EventNameForViewsChangeInLocalStorage));
};

export const onWorkspaceViewClick = (view: View, forcePutInBeginningOfList: boolean = false, setViewForOpenSpaceSelector?: (value: View) => void) => {
	const appState = view.appState;

	if (view.itemFeature === XyiconFeature.SpaceEditor) {
		if (appState.app.spaceViewRenderer.isMounted) {
			onWorkspaceViewSelect(view, forcePutInBeginningOfList);
		} else {
			const userId = appState.user?.id;
			const orgId = appState.organizationId;
			const localStorageKeyForLastViewedSpace = appState.getLocalStorageKeyForLastViewedSpace(userId, orgId);
			const lastViewedSpaceId = localStorage.getItem(localStorageKeyForLastViewedSpace);

			const spaces = appState.actions
				.getList<Space>(XyiconFeature.Space)
				.toSorted((a: Space, b: Space) => StringUtils.sortIgnoreCase(a.name, b.name));

			if (spaces.length > 0) {
				if (setViewForOpenSpaceSelector) {
					setViewForOpenSpaceSelector(view);
				} else {
					if (lastViewedSpaceId) {
						appState.app.navigation.goApp(NavigationEnum.NAV_SPACE, lastViewedSpaceId);
					} else {
						appState.app.navigation.goApp(NavigationEnum.NAV_SPACE, spaces[0].id);
					}
				}

				onWorkspaceViewSelect(view, forcePutInBeginningOfList);
			} else {
				appState.app.navigation.goApp(NavigationEnum.NAV_SPACES);
				onWorkspaceViewSelect(view, forcePutInBeginningOfList);
				notify(appState.app.notificationContainer, {
					type: NotificationType.Warning,
					title: "No Spaces Created",
					lifeTime: Infinity,
					description: "This view is currently unavailable because there are no spaces to load. If you want to access this view, create a new space.",
				});
			}
		}
	} else {
		if (view.itemFeature === XyiconFeature.Xyicon) {
			appState.app.navigation.goApp(NavigationEnum.NAV_XYICONS);
			onWorkspaceViewSelect(view, forcePutInBeginningOfList);
		}
		if (view.itemFeature === XyiconFeature.Boundary) {
			appState.app.navigation.goApp(NavigationEnum.NAV_BOUNDARIES);
			onWorkspaceViewSelect(view, forcePutInBeginningOfList);
		}
	}
};
