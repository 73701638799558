import * as React from "react";
import type {Report} from "../../../../../../data/models/Report";
import {DynamicComponent} from "./DynamicComponent";

export interface ISpaceItem {
	readonly spaceID: string;
	readonly name: string;
}

interface ICustomReportInputProps {
	readonly report: Report;
	readonly onClose: () => void;
}

export const CustomReportInputV5: React.FC<ICustomReportInputProps> = (props) => {
	const getCustomInputFormNameByReport = (report: Report): string => {
		const customInputId = report.definition.entryPointParameters.reportIdentifier;

		switch (customInputId) {
			case "ProcurementCountsReportWithCustomInput":
				return "KaiserCustomInputsForReports";
			case "TestCustomInputReport":
				return "TestCustomInputsForReports";
			default:
				return "";
		}
	};

	const customInputComponentName = getCustomInputFormNameByReport(props.report);

	if (!customInputComponentName) {
		console.log("Custom input component not found!");
		return <></>;
	}

	return (
		<DynamicComponent
			url={customInputComponentName}
			report={props.report}
			onClose={props.onClose}
		/>
	);
};
