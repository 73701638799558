import type {PDFDocumentLoadingTask} from "pdfjs-dist";
import {VerbosityLevel} from "pdfjs-dist";
import type {DocumentInitParameters, PDFPageProxy, TypedArray} from "pdfjs-dist/types/src/display/api";
import {DebugInformation} from "../../utils/DebugInformation";
import type {SpaceFileInsertionInfo} from "../../generated/api/base";

export interface ITileData {
	context: CanvasRenderingContext2D | null;
	url: string;
	insertionInfo: SpaceFileInsertionInfo;
	tileId: string;
	zoomInfoObject: IZoomInfo;
}

export interface ISnapShotConfig {
	scale: number;
	offsetX: number;
	offsetY: number;
	maxSize: number;
	isBackground: boolean;
	isTransparent: boolean;
}

export interface IZoomInfo {
	columns: number;
	rows: number;
	tileSize: number;
}

export interface IPDFRenderer {
	page: PDFPageProxy;
	pdfPageWidth: number;
	pdfPageHeight: number;
}

interface ISnapShotConfigParams {
	tileId: string;
	zoomInfoObject: IZoomInfo;
	isTransparent: boolean;
	spaceToPDFRatio: number;
	desiredTileResolution: number;
	pdfPageHeight: number;
}

export const getSnapShotConfig = (params: ISnapShotConfigParams): ISnapShotConfig => {
	const {tileId, zoomInfoObject, isTransparent, spaceToPDFRatio, desiredTileResolution, pdfPageHeight} = params;
	const componentsOfTileID = tileId.split("_");
	const zoomLevelOfTile = parseInt(componentsOfTileID[0]);
	const x = parseInt(componentsOfTileID[1]);
	const y = parseInt(componentsOfTileID[2]);
	const scale = (spaceToPDFRatio * desiredTileResolution) / zoomInfoObject.tileSize;

	return {
		scale: scale,
		offsetX: x * desiredTileResolution,
		offsetY: scale * pdfPageHeight - desiredTileResolution * (y + 1),
		maxSize: desiredTileResolution,
		isBackground: false,
		isTransparent: isTransparent,
	};
};

export const getSpaceSize = async (
	url: string,
	getDocument: (src?: string | URL | TypedArray | ArrayBuffer | DocumentInitParameters) => PDFDocumentLoadingTask,
) => {
	DebugInformation.start(url);
	const pdf = await getDocument({url: url, verbosity: VerbosityLevel.ERRORS}).promise;

	DebugInformation.end(url);

	const page = await pdf.getPage(1);

	const viewBox = page.view;

	let cropLeft = 0;
	let cropBottom = 0;
	let pageWidth = 0;
	let pageHeight = 0;

	if ((page.rotate / 90) % 2 === 0) {
		cropLeft = viewBox[0];
		cropBottom = viewBox[1];
		pageWidth = Math.abs(viewBox[2] - cropLeft);
		pageHeight = Math.abs(viewBox[3] - cropBottom);
	} else {
		cropLeft = viewBox[1];
		cropBottom = viewBox[0];
		pageWidth = Math.abs(viewBox[3] - cropLeft);
		pageHeight = Math.abs(viewBox[2] - cropBottom);
	}

	return {
		page,
		cropLeft,
		cropBottom,
		pageWidth,
		pageHeight,
	};
};

const getViewport = (config: ISnapShotConfig, page: PDFPageProxy) => {
	return page.getViewport({
		scale: config.scale,
		rotation: page.rotate,
		offsetX: -config.offsetX,
		offsetY: -config.offsetY,
		dontFlip: false,
	});
};

export const getSnapShot = async (
	config: ISnapShotConfig,
	context: CanvasRenderingContext2D,
	pdfRenderer: IPDFRenderer,
): Promise<CanvasRenderingContext2D> => {
	const canvas = context.canvas;
	const viewport = getViewport(config, pdfRenderer.page);

	if (config.isBackground) {
		const aspect = pdfRenderer.pdfPageWidth / pdfRenderer.pdfPageHeight;

		if (aspect >= 1) {
			canvas.width = config.maxSize;
			canvas.height = canvas.width / aspect;
		} else {
			canvas.height = config.maxSize;
			canvas.width = canvas.height * aspect;
		}
	} else {
		// Tile
		canvas.width = config.maxSize;
		canvas.height = config.maxSize;
	}

	// Render PDF page into canvas context
	const renderContext = {
		canvasContext: context,
		background: config.isTransparent ? "rgb(255, 255, 255, 0)" : undefined,
		viewport: viewport,
		// enableWebGL: true
	};

	await pdfRenderer.page.render(renderContext).promise;

	return context;
};
