import styled, {css} from "styled-components";
import {baseDistance, ELLIPSIS, fontSize, fontWeight, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import type {Space} from "../../../../../data/models/Space";
import type {SpaceFile} from "../../../../../data/models/SpaceFile";
import {DropdownButtonStyled} from "../../../interaction/DropdownButtonV5";
import {SpaceVersionSelectorV5} from "../SpaceVersionSelectorV5";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import InfoIcon from "../../../icons/circle-information.svg?react";
import {ButtonStyled, ButtonV5} from "../../../button/ButtonV5";
import ExpandIcon from "../../../icons/expand-alt.svg?react";

type TopRight = "Details" | "VersionSelector";

interface ISpaceCardProps {
	readonly isSelected: boolean;
	readonly space: Space;
	readonly onClick: (space: Space) => void;
	readonly onSelect?: (space: Space) => void;
	readonly topRight?: TopRight;
	readonly onOpenSpace?: (space: Space) => void;
}

export const SpaceCard = (props: ISpaceCardProps) => {
	const {space, isSelected, onClick, topRight = "Details", onOpenSpace, onSelect} = props;

	const onVersionClick = (spaceFile: SpaceFile) => {
		space.setSelectedSpaceFile(spaceFile);
	};

	return (
		<SpaceItemStyled
			$active={isSelected}
			onClick={() => (topRight != "Details" ? onSelect(space) : onClick(space))}
			$isVersionSelectorHover={topRight != "Details"}
		>
			<SpaceItemBackground $imageUrl={space.thumbnailFileURL} />
			<SpaceItemHeader $active={isSelected}>
				<SpaceItemHeaderDetails>
					<SpaceItemName title={space.name}>{space.name}</SpaceItemName>
					{topRight != "Details" && (
						<SpaceItemVersionDetails>
							<ButtonV5
								onClick={() => onOpenSpace(space)}
								label="Open"
								title="Open"
								className="open"
								IconComponent={ExpandIcon}
							/>
						</SpaceItemVersionDetails>
					)}
				</SpaceItemHeaderDetails>
				{topRight != "Details" && (
					<SpaceItemHeaderDetails>
						<SpaceItemVersionDetails $active={isSelected}>
							<SpaceVersionSelectorV5
								onVersionClick={onVersionClick}
								space={space}
							/>
							<IconButtonV5
								IconComponent={InfoIcon}
								onClick={() => onClick(space)}
								className="info"
							/>
						</SpaceItemVersionDetails>
					</SpaceItemHeaderDetails>
				)}
			</SpaceItemHeader>
		</SpaceItemStyled>
	);
};

const SpaceItemName = styled.div`
	font-size: ${fontSize.lg};
	font-weight: ${fontWeight.bold};
	line-height: 24px;
	white-space: initial;
	display: block;
	color: ${colorPalette.gray.c950};
	max-width: 250px;
`;

const SpaceItemHeaderDetails = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	${ButtonStyled} {
		height: 24px;
		width: 59px;
		font-size: ${fontSize.sm};
		gap: ${baseDistance.xs};
		border-radius: ${radius.sm};
	}

	${IconButtonStyled} {
		height: 16px;
		width: 16px;
		min-width: 16px;
	}

	> div {
		width: 100%;
		${ELLIPSIS}
	}
`;

const SpaceItemBackground = styled.div<{$imageUrl: string}>`
	background: url(${(props) => props.$imageUrl});
	width: 250px;
	height: 200px;
	margin: 0 auto;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
`;

const SpaceItemVersionDetails = styled.div<{$active?: boolean}>`
	display: none;

	.VersionSelector {
		height: 24px;
		max-width: 232px;

		&:hover {
			background-color: ${(props) => (props.$active ? colorPalette.blueGray.c700Dark : colorPalette.gray.c200Light)};
			color: ${(props) => (props.$active ? colorPalette.white : colorPalette.black)};
		}
	}

	.info {
		height: 24px;
		width: 24px;

		svg {
			width: 16px;
			height: 16px;
		}

		&:hover {
			background-color: ${(props) => (props.$active ? colorPalette.blueGray.c700Dark : colorPalette.gray.c200Light)};
			color: ${(props) => (props.$active ? colorPalette.white : colorPalette.black)};
		}
	}
`;

const SpaceItemHeader = styled.div<{$active: boolean}>`
	height: 40px;
	border-radius: 0 0 ${radius.md} ${radius.md};
	padding: ${baseDistance.sm};
	gap: ${baseDistance.sm};
	display: flex;
	flex-direction: column;
	background-color: ${(props) => (props.$active ? colorPalette.primary.c200Light : colorPalette.gray.c100)};
	color: ${(props) => (props.$active ? colorPalette.primary.c500Primary : "")};

	${(props) =>
		props.$active &&
		css`
			${SpaceItemName} {
				color: ${colorPalette.primary.c500Primary};
			}
		`}
`;

const SpaceItemStyled = styled.div<{$active: boolean; $isVersionSelectorHover: boolean}>`
	display: flex;
	flex-direction: column;
	cursor: pointer;
	box-shadow: ${(props) => (props.$isVersionSelectorHover ? "0px 2px 4px 0px #00000080" : "none")};
	border-radius: ${radius.md};
	height: fit-content;
	border: 1px solid ${(props) => (props.$active && !props.$isVersionSelectorHover ? colorPalette.primary.c500Primary : colorPalette.gray.c100)};

	${DropdownButtonStyled} {
		padding: 0 ${baseDistance.xs};
		justify-content: flex-end;
	}

	&:hover {
		${SpaceItemVersionDetails} {
			display: contents;
		}

		${SpaceItemHeader} {
			height: ${(props) => props.$isVersionSelectorHover && "72px"};
			background-color: ${(props) =>
				props.$active ? colorPalette.primary.c200Light : !props.$isVersionSelectorHover && colorPalette.gray.c200Light};
		}
	}
`;
