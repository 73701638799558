import * as React from "react";
import {inject, observer} from "mobx-react";
import {Field} from "../../../../../../widgets/form/field/Field";
import type {AppState} from "../../../../../../../data/state/AppState";
import {IDateFieldFormat} from "../../../../../settings/modules/field/datatypes/DateFieldSettings";
import type {Markup} from "../../../../../../../data/models/Markup";
import {DateFormatter} from "../../../../../../../utils/format/DateFormatter";
import {SelectInput} from "../../../../../../widgets/input/select/SelectInput";
import {changeMarkupType, markupTypeToMarkupToolName} from "../../../../../space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import {MarkupType} from "../../../../../../../generated/api/base";
import {SVGIcon} from "../../../../../../widgets/button/SVGIcon";
import {MarkupsWithInterchangeableProperties} from "../../../../../space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import {zIndex} from "../../../../../../5.0/styles/styles";

interface IMarkupDefaultFieldsProps {
	readonly item: Markup;
	readonly appState?: AppState;
}

@inject("appState")
@observer
export class MarkupDefaultFields extends React.Component<IMarkupDefaultFieldsProps> {
	private get spaceViewRenderer() {
		return this.props.appState.app.graphicalTools.spaceViewRenderer;
	}

	private onMarkupTypeChange = (markupType: MarkupType) => {
		changeMarkupType([this.props.item], markupType, this.spaceViewRenderer);
	};

	private markupTypeRender = (markupType: MarkupType) => {
		const formattedMarkupType = `${MarkupType[markupType].replaceAll("_", " ")} Markup`;

		const tools = this.props.appState.app.graphicalTools.spaceViewRenderer.tools;
		const icon = (
			<SVGIcon
				icon={tools[markupTypeToMarkupToolName(markupType)].icon}
				style={{width: "24px", height: "24px", marginRight: "10px", fill: "initial"}}
			/>
		);

		return (
			<div className="hbox alignCenter">
				{icon}
				{formattedMarkupType}
			</div>
		);
	};

	public override render() {
		const {item, appState} = this.props;
		const {isTypeChangeable} = item;

		return (
			<>
				<Field label="Markup Type">
					{isTypeChangeable ? (
						<SelectInput
							options={MarkupsWithInterchangeableProperties}
							selected={item.type}
							render={this.markupTypeRender}
							onChange={this.onMarkupTypeChange}
							optionsZIndex={zIndex.anchoredHeader + 1}
						/>
					) : (
						item.typeName
					)}
				</Field>
				<Field label="Last Modified At">{DateFormatter.format(item.data.lastModifiedAt, IDateFieldFormat.DATETIME)}</Field>
				<Field label="Last Modified By">{appState.actions.findUser(item.data.lastModifiedBy)?.fullName || ""}</Field>
			</>
		);
	}
}
