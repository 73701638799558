import styled from "styled-components";
import {baseDistance, fontSize, fontWeight, VerticalFlexStyle} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import {SelectInputStyled} from "../input/select/SelectInputV5";

export const DetailsTabV5HeaderStyles = styled.div`
	${VerticalFlexStyle};
	gap: ${baseDistance.md};
	margin-bottom: ${baseDistance.md};
	padding-bottom: ${baseDistance.md};
	border-bottom: 2px solid ${colorPalette.gray.c200Light};

	h2 {
		flex: 1;
		font-size: ${fontSize.lg};
		font-weight: ${fontWeight.bold};
		line-height: 24px;
		text-transform: uppercase;
	}

	.Initials {
		position: relative;
		top: 0;
		left: 0;
		width: 48px;
		height: 48px;
		font-size: 24px;

		.boundaryThumbnail {
			height: 100%;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	.Field {
		.element {
			margin-right: 0;
			width: 178px;
		}

		&.space {
			.label {
				width: 140px;
			}
			.element {
				width: 158px;
			}
		}

		.SingleLineLabel {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: unset;
			line-height: 16px;
		}

		${SelectInputStyled} {
			svg {
				flex-shrink: 0;
			}
		}
	}

	.thumbnail:has(.SpaceThumbnail) {
		width: 80px;
		height: 80px;
		border: 1px solid ${colorPalette.gray.c300};
	}

	.SpaceThumbnail {
		width: 80px;
		height: 80px;
	}

	&.Boundary:not(.minimized) {
		min-height: 170px;
	}

	&.anchored {
		opacity: 0;
		visibility: hidden;
		max-height: 500px;
		transition:
			visibility 0.3s ease-in-out,
			opacity 0.3s ease-in-out,
			max-height 0.3s ease-in;
		position: absolute;
		background: white;
		width: 100%;
		box-shadow: 0px 8px 8px 0px rgba(50, 50, 71, 0.08);
		z-index: 1;
		top: 0;
		left: 0;
		padding: 16px 24px 32px 16px;

		& > .Initials {
			right: 28px;
		}

		&.visible {
			opacity: 1;
			visibility: visible;
		}

		.toggler {
			background: ${colorPalette.white};
			width: 24px;
			height: 32px;
			margin: 0 auto;
			position: absolute;
			border-radius: 50%;
			border: solid 2px lightgray;
			left: 50%;
			bottom: -16px;
			transform: translateX(-50%) rotate(180deg);
			transition: ease-in-out rotate 0.1s;

			svg {
				width: 12px;
				height: 12px;
			}
		}

		&.minimized {
			max-height: 120px;
			transition:
				visibility 0.3s ease-in-out,
				opacity 0.3s ease-in-out,
				max-height 0.3s ease-out;

			h4 {
				margin-bottom: 0;
			}

			.icons {
				display: none;
			}

			.toggler {
				transform: translateX(-50%) rotate(0deg);
			}

			.default-fields {
				.Field {
					margin-bottom: 0;

					&:not(.stucked) {
						opacity: 0;
						visibility: hidden;
						height: 0;
					}
				}

				&:not(.multi) {
					.Initials {
						transform: scale(0.6);
						left: 15px;
						top: -9px;
					}
				}

				&.multi {
					.thumbnail {
						width: 28px;
						height: 28px;
					}
				}
			}
		}
	}
`;
