import type {ReactNode} from "react";
import styled from "styled-components";
import type {IModel} from "../../../../../data/models/Model";
import {useAppStore} from "../../../../../StateManager";
import {baseDistance, ELLIPSIS, fontSize, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import type {Boundary} from "../../../../../data/models/Boundary";
import type {Markup} from "../../../../../data/models/Markup";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import InfoIcon from "../../../icons/circle-information.svg?react";
import {XyiconFeature} from "../../../../../generated/api/base";

interface ISpaceSearchItemProps {
	readonly item?: IModel;
	readonly isActive?: boolean;
	readonly queryString?: string;
	readonly onItemClick?: () => void;
	readonly onDetailsClick?: (item: IModel) => void;
	readonly onLocate?: (item: IModel) => ReactNode;
}

export const SpaceSearchItem = (props: ISpaceSearchItemProps) => {
	const {item, isActive, queryString, onItemClick, onDetailsClick, onLocate} = props;

	const appState = useAppStore((state) => state.appState);

	const rows = appState.actions?.getRowsForCardLayout(queryString, item);

	const getIcon = (featureItem: IModel) => {
		const item = featureItem as Xyicon | Boundary | Markup;

		return (
			<img
				src={item.thumbnail}
				style={{transform: (item as Xyicon).backgroundTransform || ""}}
			/>
		);
	};

	const renderCustomRow = (data: {key: string; value: string}) => {
		let html = "";

		if (data.value !== "") {
			html = data.key + (data.key !== "" ? ": " : "") + StringUtils.regexHighlight(data.value.toString(), queryString);
		}

		return (
			<div
				className="field"
				dangerouslySetInnerHTML={{
					__html: html,
				}}
			/>
		);
	};

	return (
		<>
			<SpaceSearchItemStyled
				key={item.id}
				className={isActive ? "active" : ""}
				onClick={onItemClick}
			>
				<div className="icon">{getIcon(item)}</div>
				<div className="fields">
					<div className="featureId">{renderCustomRow(rows[0])}</div>
					{renderCustomRow(rows[1])}
					{renderCustomRow(rows[2])}
				</div>
				<div className="hoverButtons hbox">
					<IconButtonV5
						IconComponent={InfoIcon}
						onClick={(event) => {
							event.stopPropagation();
							onDetailsClick(item);
						}}
					/>
					{(item.ownFeature === XyiconFeature.Xyicon || XyiconFeature.Boundary || XyiconFeature.Markup) &&
						(item as Boundary | Xyicon | Markup).space &&
						onLocate(item)}
				</div>
			</SpaceSearchItemStyled>
		</>
	);
};

const SpaceSearchItemStyled = styled.div`
	display: flex;
	width: 280px;
	${ELLIPSIS}
	height: 48px;
	border-radius: ${radius.sm};
	padding: ${baseDistance.xs} ${baseDistance.sm};
	gap: ${baseDistance.sm};
	font-size: ${fontSize.sm};
	color: ${colorPalette.gray.c700Dark};
	align-items: center;

	&.active {
		background-color: ${colorPalette.primary.c200Light};

		.fields .featureId {
			color: ${colorPalette.primary.c500Primary};
		}

		&:hover {
			background-color: ${colorPalette.primary.c200Light} !important ;

			.hoverButtons .IconButton svg path {
				stroke: ${colorPalette.primary.c500Primary};
			}
		}
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};

		.field {
			width: 130px;
			${ELLIPSIS}
		}

		.hoverButtons {
			margin-left: ${baseDistance.xs};
			display: flex;
			gap: ${baseDistance.sm};

			.IconButton {
				box-sizing: border-box;
				border: solid 2px transparent;
				transition: ease-in-out 0.2s border-color;

				&:hover {
					border-color: ${colorPalette.gray.c950};
				}
			}
		}
	}

	.hoverButtons {
		display: none;
	}

	.icon {
		width: 40px;
		min-width: 40px;
		height: 40px;

		img {
			width: 100%;
		}
	}

	.featureId {
		font-size: ${fontSize.md};
		color: ${colorPalette.gray.c950};
	}

	.field {
		width: 216px;
		${ELLIPSIS}
	}
`;
