import styled from "styled-components";
import {useEffect, useState} from "react";
import {baseDistance, fontSize, fontWeight, radius, zIndex} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import FilterIcon from "../../../icons/filter-search.svg?react";
import {useAppStore} from "../../../../../StateManager";
import type {IModel, ISpaceItemModel} from "../../../../../data/models/Model";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {Markup} from "../../../../../data/models/Markup";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import type {Boundary} from "../../../../../data/models/Boundary";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import PinIcon from "../../../icons/location-pin.svg?react";
import CloseIcon from "../../../icons/xmark.svg?react";
import MarkupIcon from "../../../icons/pen.svg?react";
import XyiconIcon from "../../../icons/xyicon-icon.svg?react";
import BoundaryIcon from "../../../icons/boundary.svg?react";
import {SpaceSearchItem} from "./SpaceSearchItem";

interface ISpaceSearchProps {
	readonly currentSpace?: string;
	readonly divRef?: React.RefObject<HTMLDivElement>;
	readonly style?: React.CSSProperties;
	readonly autoFocus?: boolean;
}

export const SpaceSearch = (props: ISpaceSearchProps) => {
	const {currentSpace, divRef, style, autoFocus} = props;

	const [visibleCount, setVisibleCount] = useState(25);
	const [filterState, setFilterState] = useState<boolean>(false);
	const [matchingCount, setMatchingCount] = useState(0);
	const [interactedHistory, setInteractedHistory] = useState<string[]>([]);
	const [activeItem, setActiveItem] = useState<string | null>("");

	const appState = useAppStore((state) => state.appState);
	const {spaceViewRenderer} = appState.app;
	const filteredItems = spaceViewRenderer?.spaceItemController?.getFilteredItems();

	//To get the selected view
	const selectedFeature = appState.selectedFeature;
	const selectedView = appState.actions.getSelectedView(selectedFeature);

	const initialFilters = JSON.parse(sessionStorage.getItem(`activeFilters_${currentSpace}_${selectedView.id}`) || "{}");
	const [activeFilters, setActiveFilters] = useState({
		xyicon: initialFilters.hasOwnProperty("xyicon") ? initialFilters.xyicon : true,
		boundary: initialFilters.hasOwnProperty("boundary") ? initialFilters.boundary : true,
		markup: initialFilters.hasOwnProperty("markup") ? initialFilters.markup : true,
	});

	//To get the last searched query
	const historyKey = `searchHistory_${currentSpace}_${selectedView.id}`;
	const saved = sessionStorage?.getItem(historyKey);
	const [queryString, setQueryString] = useState<string>(saved || "");

	//To differenciate a fresh opening and a re-rendering of the component
	const savedCount = parseInt(sessionStorage.getItem("renderCount") || "0", 10);
	useEffect(() => {
		const savedCount = sessionStorage.getItem("renderCount");
		const currentCount = savedCount ? parseInt(savedCount, 10) : 0;
		sessionStorage.setItem("renderCount", (currentCount + 1).toString());
	}, []);

	//Search Operations
	const categorizeItemsByFeature = (items: ISpaceItemModel[] = []) => {
		const categories = {
			xyicon: [] as IModel[],
			boundary: [] as IModel[],
			markup: [] as IModel[],
		};

		items.forEach((item) => {
			switch (item.ownFeature) {
				case XyiconFeature.Xyicon:
					categories.xyicon.push(item);
					break;
				case XyiconFeature.Boundary:
					categories.boundary.push(item);
					break;
				case XyiconFeature.Markup:
					categories.markup.push(item);
					break;
				default:
					break;
			}
		});

		return categories;
	};

	const performSearch = (query: string) => {
		const {xyicon, boundary, markup} = categorizeItemsByFeature(filteredItems);

		if (!query) {
			// Return filtered items based on active filters if no query is provided
			return [...(activeFilters.xyicon ? xyicon : []), ...(activeFilters.boundary ? boundary : []), ...(activeFilters.markup ? markup : [])];
		}

		const matchingXyicon = activeFilters.xyicon ? appState.actions.searchModelsCached(xyicon, query, XyiconFeature.Xyicon) : [];

		const matchingBoundary = activeFilters.boundary ? appState.actions.searchModelsCached(boundary, query, XyiconFeature.Boundary) : [];

		const matchingMarkup = activeFilters.markup
			? (markup as Markup[]).filter(
					(item) =>
						StringUtils.containsIgnoreCase(item.textContent, query) ||
						StringUtils.containsIgnoreCase(item.typeName, query) ||
						StringUtils.containsIgnoreCase(item.space?.name, query),
				)
			: [];

		return [...matchingXyicon, ...matchingBoundary, ...matchingMarkup];
	};

	const onChangeSearch = (value: string, event?: React.FormEvent<HTMLInputElement>) => {
		setQueryString(value);

		if (!value) {
			setActiveItem(null);
		}

		const historyKey = `searchHistory_${currentSpace}_${selectedView.id}`;
		sessionStorage.setItem(historyKey, value);
	};

	const onDetailsClick = (item: IModel) => {
		const interactedKey = `interacted_${currentSpace}_${selectedView.id}`;

		const existingItems = sessionStorage.getItem(interactedKey);
		const itemsArray = existingItems ? JSON.parse(existingItems) : [];
		itemsArray.push(item.id);
		sessionStorage.setItem(interactedKey, JSON.stringify(itemsArray));

		appState.app.onDetailsClick(item);
		setActiveItem(item.id);
	};

	const locateItemInSpaceEditor = (item: IModel) => {
		const interactedKey = `interacted_${currentSpace}_${selectedView.id}`;

		const existingItems = sessionStorage.getItem(interactedKey);
		const itemsArray = existingItems ? JSON.parse(existingItems) : [];
		itemsArray.push(item.id);
		sessionStorage.setItem(interactedKey, JSON.stringify(itemsArray));

		setActiveItem(item.id);
		appState.actions.navigateToSpaceItem(item as IModel as Boundary | Markup | Xyicon, true);
	};

	const getPinButton = (item: IModel) => {
		return (
			<IconButtonV5
				IconComponent={PinIcon}
				onClick={(event: React.MouseEvent<HTMLDivElement>) => {
					event.stopPropagation();
					locateItemInSpaceEditor(item as Xyicon | Boundary | Markup);
				}}
			/>
		);
	};

	const renderMatchingItems = () => {
		const matchingItems = performSearch(queryString);
		const newMatchingCount = matchingItems.length;

		if (matchingCount !== newMatchingCount) {
			setMatchingCount(newMatchingCount);
		}

		//To get results of batch of 25
		const handleLoadMore = () => {
			setVisibleCount((prevCount) => Math.min(prevCount + 25, matchingCount));
		};

		//To get the recent results
		const interactedKey = `interacted_${currentSpace}_${selectedView.id}`;
		const newInteractedHistory = JSON.parse(sessionStorage.getItem(interactedKey) || "[]");

		if (JSON.stringify(interactedHistory) !== JSON.stringify(newInteractedHistory)) {
			setInteractedHistory(newInteractedHistory);
		}

		const recentItems = interactedHistory
			.slice()
			.reverse()
			.filter((id, index, array) => array.indexOf(id) === index)
			.map((id) => matchingItems.find((item) => item.id === id))
			.filter((item) => item !== undefined);

		const getItemsToRender = () => {
			if (interactedHistory.length === 0 && !queryString) {
				return matchingItems;
			} else if (interactedHistory && queryString) {
				return matchingItems;
			} else if (interactedHistory && interactedHistory.length > 0 && !queryString) {
				if (savedCount > 1) {
					return recentItems.slice(0, 10);
				} else {
					return matchingItems;
				}
			}
			return [];
		};

		const renderItems = (items: IModel[]) => {
			if (items.length === 0) {
				return (
					<div className="no-results">
						No matching results found. <br />
						Try clearing a filter or switching views.
					</div>
				);
			}

			return (
				<>
					{items.slice(0, visibleCount).map((item, index) => (
						<SpaceSearchItem
							key={item.id}
							item={item}
							isActive={activeItem === item.id}
							queryString={queryString}
							onItemClick={() => setActiveItem(null)}
							onDetailsClick={onDetailsClick}
							onLocate={getPinButton}
						/>
					))}
					{visibleCount < matchingCount && items.length > 25 && (
						<button
							className="loadButton"
							onClick={handleLoadMore}
						>
							Load More...
						</button>
					)}
				</>
			);
		};

		return <>{renderItems(getItemsToRender())}</>;
	};

	const displayText = () => {
		if (interactedHistory.length === 0 && !queryString) {
			return <p>{`${matchingCount} Results`}</p>;
		} else if (interactedHistory && queryString) {
			return <p>{`${matchingCount} Results`}</p>;
		} else if (interactedHistory && interactedHistory.length > 0 && !queryString) {
			if (savedCount > 1) {
				return <p>Recent results</p>;
			} else {
				return <p>{`${matchingCount} Results`}</p>;
			}
		} else {
			return null;
		}
	};

	//Filter Operations
	const handleFilterToggle = (filter: "xyicon" | "boundary" | "markup") => {
		setActiveFilters((prev) => {
			const newFilters = {...prev, [filter]: !prev[filter]};
			sessionStorage.setItem(`activeFilters_${currentSpace}_${selectedView.id}`, JSON.stringify(newFilters)); // Save entire object at once
			return newFilters;
		});
	};

	const resetFilters = () => {
		const defaultFilters = {xyicon: true, boundary: true, markup: true};
		setActiveFilters(defaultFilters);
		sessionStorage.setItem(`activeFilters_${currentSpace}_${selectedView.id}`, JSON.stringify(defaultFilters));
	};

	return (
		<SpaceSearchStyled
			ref={divRef}
			style={style}
		>
			<SearchFieldV5
				placeholder="Find..."
				value={queryString}
				onInput={onChangeSearch}
				autoFocus={autoFocus}
			/>
			<FilterWrapperStyled>
				{displayText()}
				{!filterState && activeFilters.xyicon && activeFilters.boundary && activeFilters.markup ? (
					<div className="filterHide">
						<FilterIcon onClick={() => setFilterState(true)} />
					</div>
				) : !filterState && (!activeFilters.xyicon || !activeFilters.boundary || !activeFilters.markup) ? (
					<div className="filterHideActive">
						<FilterIcon onClick={() => setFilterState(true)} />
						<CloseIcon onClick={resetFilters} />
					</div>
				) : (
					<div
						className="filterHide"
						onClick={() => setFilterState(false)}
					>
						<FilterIcon />
						<p>Hide</p>
					</div>
				)}
			</FilterWrapperStyled>
			<FilterButtonStyled>
				{filterState && (
					<div className="filterButtons">
						<IconButtonV5
							IconComponent={XyiconIcon}
							onClick={() => handleFilterToggle("xyicon")}
							label="Xyicons"
							className={activeFilters.xyicon ? "active" : "inactive"}
						/>
						<IconButtonV5
							IconComponent={BoundaryIcon}
							onClick={() => handleFilterToggle("boundary")}
							label="Boundaries"
							className={activeFilters.boundary ? "active" : "inactive"}
						/>
						<IconButtonV5
							IconComponent={MarkupIcon}
							onClick={() => handleFilterToggle("markup")}
							label="Markups"
							className={activeFilters.markup ? "active" : "inactive"}
						/>
					</div>
				)}
			</FilterButtonStyled>
			<SearchItemsStyled>
				<ul>{renderMatchingItems()}</ul>
			</SearchItemsStyled>
		</SpaceSearchStyled>
	);
};

const FilterWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	min-height: 24px;
	justify-content: space-between;

	> p {
		font-size: ${fontSize.sm};
	}

	svg {
		width: ${baseDistance.md};
		height: ${baseDistance.md};
	}

	.filterHide {
		display: flex;
		gap: ${baseDistance.xs};
		align-items: center;
		padding: ${baseDistance.xs};
		cursor: pointer;

		> p {
			font-size: ${fontSize.sm};
		}
	}

	.filterHide:hover {
		background-color: ${colorPalette.gray.c200Light};
		border-radius: ${radius.sm};
	}

	.filterHideActive {
		display: flex;
		padding: ${baseDistance.xs};
		border-radius: ${radius.sm};
		background-color: ${colorPalette.primary.c200Light};
		gap: ${baseDistance.xs};
		cursor: pointer;

		svg path {
			stroke: ${colorPalette.primary.c500Primary};
		}
	}
`;

const FilterButtonStyled = styled.div`
	.filterButtons {
		display: flex;
		gap: ${baseDistance.xs};
		font-size: ${fontSize.sm};

		div {
			border-radius: ${radius.xl};
			padding: ${baseDistance.xs} ${baseDistance.sm};
			gap: ${baseDistance.xs};
			height: 24px;

			svg {
				width: 16px;
				height: 16px;
			}
		}

		.active {
			color: ${colorPalette.primary.c500Primary};
			background-color: ${colorPalette.primary.c200Light};
			border: 1px solid ${colorPalette.primary.c500Primary};

			svg {
				path {
					fill: ${colorPalette.primary.c500Primary};
				}
			}
		}

		.inactive {
			color: ${colorPalette.gray.c700Dark};
			background-color: ${colorPalette.white};
			border: 1px solid ${colorPalette.gray.c700Dark};

			svg {
				path {
					fill: ${colorPalette.gray.c700Dark};
				}
			}
		}

		.inactive:hover {
			background-color: ${colorPalette.gray.c200Light};
		}
	}
`;

const SearchItemsStyled = styled.div`
	overflow-y: auto;
	overflow-x: hidden;

	p {
		font-size: ${fontSize.sm};
		color: ${colorPalette.gray.c500Primary};
		margin-bottom: ${baseDistance.oh};
	}

	ul {
		p {
			transform: translateY(-30px);
		}

		.no-results {
			font-size: ${fontSize.md};
			color: #000000;
			font-weight: ${fontWeight.bold};
			text-align: center;
		}
	}

	.loadButton {
		all: unset;
		font-size: ${fontSize.md};
		color: ${colorPalette.primary.c500Primary};
		padding-top: ${baseDistance.md};
		padding-bottom: ${baseDistance.sm};
		margin-left: 95px;
		text-decoration: underline;
		text-transform: uppercase;
		font-weight: ${fontWeight.bold};
	}
`;

const SpaceSearchStyled = styled.div`
	margin-top: ${baseDistance.sm};
	border-radius: ${radius.md};
	background-color: ${colorPalette.white};
	box-shadow: 0px 4px 8px 0px #00000080;
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.sm};
	padding: ${baseDistance.sm};
	z-index: ${zIndex.popup};
	width: 296px;
	height: 352px;
`;
