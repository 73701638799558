import type {Lambda} from "mobx";
import type {FunctionComponent, SVGProps} from "react";
import * as React from "react";
import {inject, observer} from "mobx-react";
import {observable, observe, makeObservable} from "mobx";
import type {CSSProperties} from "styled-components";
import styled from "styled-components";
import type {AppState} from "../../../data/state/AppState";
import type {IModel} from "../../../data/models/Model";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {XyiconDto} from "../../../generated/api/base";
import type {App} from "../../../App";
import type {PermissionSet} from "../../../data/models/permission/PermissionSet";
import type {Catalog} from "../../../data/models/Catalog";
import type {IIconConfig} from "../../modules/catalog/create/CatalogTypes";
import type {Boundary} from "../../../data/models/Boundary";
import type {BoundarySpaceMap} from "../../../data/models/BoundarySpaceMap";
import type {Xyicon} from "../../../data/models/Xyicon";
import {CatalogIconType, Permission, XyiconFeature} from "../../../generated/api/base";
import {ScreenType} from "../../modules/abstract/ScreenType";
import {Debouncer} from "../../../utils/function/Debouncer";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import {notify} from "../../../utils/Notify";
import {NotificationType} from "../../notification/Notification";
import {filterModels} from "../../../data/models/filter/Filter";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import {DockableV5} from "../modules/spaceeditor/dockable/DockableV5";
import {Functions} from "../../../utils/function/Functions";
import {ReactUtils} from "../../utils/ReactUtils";
import {ActionBarV5} from "../actionbar/ActionBarV5";
import {featureTitlePlurals} from "../../../data/state/AppStateConstants";
import {WarningWindowV5} from "../popup/WarningWindowV5";
import {PopupUtilsV5} from "../popup/PopupUtilsV5";
import {ViewSharingPopup} from "../sharing/ViewSharingPopup";
import {VerticalFlexStyle} from "../styles/styles";
import {DetailsTabV5} from "../details/DetailsTabV5";
import {CardViewV5} from "../modules/CardViewV5";
import {colorPalette} from "../styles/colorPalette";
import {ConfirmWindowV5} from "../popup/ConfirmWindowV5";
import {extendActionBarButtonsWithDetailsButton} from "../modules/ActionBarUtils";
import {NavigationEnum} from "../../../Enums";
import {SplitterV5Tamas} from "../layout/SplitterV5Tamas";
import {CreateSpaceUtils} from "../modules/space/CreateSpaceUtils";
import {LoaderV5} from "../loader/LoaderV5";
import {CatalogCreateV5} from "../modules/catalog/CatalogCreateV5";
import {PopupWindowV5} from "../popup/PopupWindowV5";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import type {Navigation} from "../../../Navigation";
import {GridViewV5} from "./GridViewV5";
import {ManageColumnsPanelV5} from "./table/ManageColumnsPanelV5";
import {CatalogItemPanelV5} from "./view/catalog/CatalogItemPanelV5";
import {Panel} from "./Panel";

interface ICreateUnplottedXyiconParam {
	catalogId: string;
	parentXyiconId: string | null;
}

export interface IModulePanelProps {
	appState?: AppState;
	transport?: TransportLayer;
	onClose: (createdId?: string) => void;
}

export interface IActionBarItem<T extends IModel> {
	id:
		| "add"
		| "delete"
		| "duplicate"
		| "play"
		| "edit"
		| "import"
		| "manageVersionSets"
		| "details"
		| "id"
		| "unplot"
		| "locate"
		| "fieldUpdate"
		| "favorite"
		| "share";
	title: string;
	label?: string;
	className?: string;
	IconComponent: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	isActive?: boolean;
	isVisible?: (selectedItems: T[]) => boolean;
	componentFactory?: (selectedItems: T[]) => React.JSX.Element;
	isEnabled?: (selectedItems: T[]) => boolean;
	onClick?: (selectedItems: T[]) => void;
}

interface IModuleViewProps<T extends IModel> {
	readonly feature: XyiconFeature;
	// You either handle this state outside of the ModuleView, and then you should provide this,
	// along with "setFocusedItems", or you omit it, and then it will be handled in internal state
	readonly focusedItems?: T[];
	// You either handle this state outside of the ModuleView, and then you should provide this,
	// along with "setSelectedItems", or you omit it, and then it will be handled in internal state
	readonly selectedItems?: T[];
	readonly className?: string;
	readonly actionBar?: IActionBarItem<T>[];
	readonly createButtonRef?: React.MutableRefObject<HTMLDivElement>;
	readonly emptyListText?: React.ReactNode;
	readonly isCatalogPanelDocked?: boolean;
	readonly isCatalogPanelOpen?: boolean;
	readonly manageColumns?: boolean;
	readonly manageDetailsPanel?: boolean;
	readonly isSideGrid?: boolean; // next to the spaceeditor
	readonly navigation?: Navigation;
	readonly transport?: TransportLayer;
	readonly app?: App;
	readonly appState?: AppState;

	readonly setFocusedItems?: (items: T[]) => void;
	readonly setSelectedItems?: (items: T[]) => void;
	readonly create?: (onClose: () => void) => React.ReactElement;
	readonly onAdd?: () => void;
	readonly onEdit?: (model: T) => void;
	readonly filterPermissions?: (model: T) => boolean;
	readonly setCatalogPanelDocked?: (isDocked: boolean) => void;
	readonly setCatalogPanelOpen?: (isOpen: boolean) => void;
	readonly canDeleteReports?: (selectedItems: T[]) => boolean;
}

interface IModuleViewState<T extends IModel> {
	focusedItems: T[];
	selectedItems: T[];
	creating: boolean;
	managingColumns: boolean;
	searchString: string;
	isSearching: boolean;
	isCellContentsWrappingOn: boolean;
	initialLoading: boolean;
	isLoadingDependencies: boolean;
	sharingViewId: string;
	iconConfig: IIconConfig;
	editedCatalog: Catalog; // the icon of this catalog is being edited
	catalogMode: "create" | "edit" | null;
	isFeatureImportPanelOpen: boolean;
	loadingViewId: string;
	isPortTemplateEditorOpen: boolean;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@inject("app")
@observer
export class ModuleViewV5<T extends IModel = IModel> extends React.Component<IModuleViewProps<T>, IModuleViewState<T>> {
	public static readonly defaultProps: Partial<IModuleViewProps<IModel>> = {
		manageDetailsPanel: true,
		isSideGrid: false,
	};

	@observable
	private _isLoadingCatalogEditor: boolean = false;

	private _isDeletePopupWindowOpen: boolean = false;
	private _gridView = React.createRef<GridViewV5<T>>();
	private _manageColumnsButtonRef = React.createRef<HTMLDivElement>();
	private _disposer: Lambda;
	private _portfolioId = "";

	private _creationCount = 0;
	private _isMounted: boolean = false;

	private get selectedScreenType(): ScreenType {
		return this.props.feature === XyiconFeature.Space ? ScreenType.CARD : ScreenType.GRID;
	}

	private readonly _actionBar: {
		handlers: {
			[action: string]: () => void;
		};
		enabled?: {
			[action: string]: (items: T[]) => boolean;
		};
	};

	private readonly _listDebouncer = new Debouncer(1000);
	private readonly _typingDebouncer = new Debouncer(1000);

	// We assume ModuleView constructor runs every time a module is opened (is the case currently)
	private _lastViewId: string = "";

	constructor(props: IModuleViewProps<T>) {
		super(props);
		makeObservable(this);
		this.state = {
			focusedItems: [],
			selectedItems: [],
			creating: false,
			managingColumns: false,
			searchString: "",
			isSearching: false,
			// It is always true for now, maybe this will be changed in the future
			isCellContentsWrappingOn: true,
			initialLoading: props.appState.lists[props.feature]?.array.length > 100,
			isLoadingDependencies: true, // we set it to false when all the dependencies are loaded 100%
			sharingViewId: null,
			editedCatalog: null,
			iconConfig: null,
			catalogMode: null,
			isFeatureImportPanelOpen: false,
			loadingViewId: "",
			isPortTemplateEditorOpen: false,
		};

		props.app.moduleViews[props.feature] = this;

		this._actionBar = {
			handlers: {
				add: this.onAddClick,
				import: this.onImportClick,
				duplicate: this.onActionBarDuplicateClick,
				edit: this.onActionBarEditClick,
				delete: this.onDeleteClick,
				locate: this.locateItemInSpaceEditor,
			},
			enabled: {
				add: () => true,
				import: this.canImport,
				duplicate: (items) => items.length === 1,
				edit: (items) => items.length === 1,
				delete: (items) => items.length > 0,
				manageVersionSets: () => true,
			},
		};

		this.resetFilters();
	}

	private get selectedItems() {
		return this.props.selectedItems ?? this.state.selectedItems;
	}

	private get focusedItems() {
		return this.props.focusedItems ?? this.state.focusedItems;
	}

	private setSelectedItems = (items: T[]) => {
		return this.props.setSelectedItems ? this.props.setSelectedItems(items) : this.setState({selectedItems: items});
	};

	private setFocusedItems = (items: T[]) => {
		return this.props.setFocusedItems ? this.props.setFocusedItems(items) : this.setState({focusedItems: items});
	};

	private resetFilters() {
		const selectedView = this.selectedView;

		if (selectedView.id !== this._lastViewId) {
			// Selected view changed, or opening view the first time
			// -> copy filters from view
			this._lastViewId = selectedView.id;

			this.forceResetFilters();
		}
	}

	private locateItemInSpaceEditor = () => {
		this.props.appState.actions.navigateToSpaceItem(this.selectedItems[0] as IModel as Boundary | BoundarySpaceMap | Xyicon, true);
	};

	private onCloseGridActionBar = () => {
		this.setSelectedItems([]);
		this.setFocusedItems([]);
	};

	private onCloseCardActionBar = () => {
		this.setSelectedItems([]);
		this.setFocusedItems([]);
	};

	private forceResetFilters() {
		const {selectedView} = this;
		const savedFilters = selectedView.getSavedFilters();

		selectedView.setFilters(ObjectUtils.deepClone(savedFilters));
	}

	public focusItem(item: T) {
		// Select the item
		this.setFocusedItems([item]);

		// Go to the page on which the item is located
		this.table?.goToItem(item);
	}

	private toggleSharingPanel = (viewId?: string) => {
		this.setState({sharingViewId: viewId});
	};

	private onManageColumns = () => {
		this.setState({managingColumns: true});
	};

	private onCloseManageColumns = () => {
		this.setState({managingColumns: false});
	};

	private onCloseCreate = (createdId?: string) => {
		if (createdId) {
			const {feature, appState} = this.props;

			this.onFocus(
				appState.actions
					.getList(feature)
					.filter((item) => item.id === createdId) // TODO optimize
					.filter((item) => Boolean(item)) as T[],
			);

			this.table?.scrollToItemId(createdId);
		}
	};

	private async refreshList() {
		const {feature, transport} = this.props;

		this.setSelectedItems([]);
		this.setFocusedItems([]);

		this.setState({
			isLoadingDependencies: true,
		});

		await transport.services.feature.refreshList(feature);

		this.setState({
			isLoadingDependencies: false,
		});
	}

	private onSelect = (items: T[]) => {
		this.setSelectedItems(items);
	};

	private onFocus = (items: T[], isGoToItemNeeded: boolean = true) => {
		this.setFocusedItems(items);

		if (items.length) {
			// is there anything that need the items[0] if items.length > 1?
			isGoToItemNeeded && this.table?.goToItem(items[items.length - 1]);
		}

		if (items.length === 1) {
			if (items[0].ownFeature === XyiconFeature.Portfolio) {
				this.switchToSelectedPortfolio(items[0].id);
			}
		}
	};

	private onCreateUnplottedXyicons = async (params: ICreateUnplottedXyiconParam[]) => {
		const {result, error} = await this.props.transport.requestForOrganization<XyiconDto>({
			url: "xyicons/createunplotted",
			method: XHRLoader.METHOD_PUT,
			params: {
				portfolioID: this.props.appState.portfolioId,
				xyiconCreateDetail: params.map((p) => ({xyiconCatalogID: p.catalogId, parentXyiconID: p.parentXyiconId, fieldValues: {}})),
			},
		});

		if (Array.isArray(result) && result.length > 0) {
			const newXyicons: T[] = [];

			for (const element of result) {
				newXyicons.push(this.props.appState.actions.addToList(element, XyiconFeature.Xyicon) as T);
			}
			const {items: filteredItems} = this.getFilteredItems(newXyicons);

			if (filteredItems.length < result.length) {
				notify(this.props.app.notificationContainer, {
					lifeTime: Infinity,
					type: NotificationType.Warning,
					title: "Current view will hide your new xyicon!",
					description:
						"The current view prevents your new xyicon from displaying on the space. Click Edit Details and change the fields to make your new xyicon visible.",
					buttonLabel: "Edit Details",
					onActionButtonClick: () => this.onFocus(newXyicons),
				});
			} else {
				this.onFocus(newXyicons);
			}
		} else {
			notify(this.props.app.notificationContainer, {
				type: NotificationType.Error,
				title: "Error",
				description: "Sorry, something went wrong",
				lifeTime: Infinity,
			});
		}
	};

	private switchToSelectedPortfolio = (portfolioId: string) => {
		const {transport, feature} = this.props;

		if (feature === XyiconFeature.Portfolio) {
			transport.services.auth.switchPortfolio(portfolioId);
			this.props.transport.services.feature.refreshList(XyiconFeature.PortfolioDocument, true);
		}
	};

	private onDoubleClick = (item: T) => {
		const {navigation} = this.props;

		if (item.ownFeature === XyiconFeature.Space) {
			navigation.goApp(NavigationEnum.NAV_SPACE, item.id);
		} else if (item.ownFeature === XyiconFeature.Portfolio) {
			this.onFocus([item]);
			navigation.goApp(NavigationEnum.NAV_SPACES);
		} else if (item.ownFeature === XyiconFeature.Boundary || item.ownFeature === XyiconFeature.Xyicon) {
			this.props.appState.actions.navigateToSpaceItemById(item.id, item.ownFeature, true);
		}
	};

	private getFilteredItems(unfilteredItems: T[]) {
		if (this.state.isSearching) {
			// Only showing the search spinner currently -> don't do any heavy calculations,
			// just return empty result
			return {
				items: [] as T[],
				filterSuppression: false,
			};
		}
		const {filterPermissions, appState, isSideGrid, feature} = this.props;

		let items = unfilteredItems;

		if (isSideGrid) {
			const spaceItems = appState.app.spaceViewRenderer.spaceItemController.getFilteredItems() as unknown as T[];

			items = spaceItems.filter((item) => item.ownFeature === feature);
		} else {
			// Use local, applied filters
			items = filterModels(items, this.selectedView.filters, appState);
		}

		let filterSuppression = false;
		const itemCount = items.length;

		if (items.length !== itemCount) {
			filterSuppression = true;
		}

		// Permissions
		if (filterPermissions) {
			items = items.filter(filterPermissions);
		}

		return {
			items,
			filterSuppression: filterSuppression,
		};
	}

	private onKeyUp = (event: KeyboardEvent) => {
		const {overlayedDetailedItems} = this.props.appState.app.state;

		switch (event.key) {
			case KeyboardListener.KEY_BACKSPACE:
			case KeyboardListener.KEY_DELETE:
				if (this.selectedItems.length > 0) {
					this.onDeleteClick();
				}
				break;
			case KeyboardListener.KEY_ESCAPE:
				if (!KeyboardListener.isEventTargetAnInput(event) && overlayedDetailedItems?.length === 0) {
					this.setFocusedItems([]);
					if (this.selectedScreenType === ScreenType.CARD) {
						this.setSelectedItems([]);
					}
					this.setState({
						creating: false,
					});
				}
				break;
		}
	};

	private getNumberOfSelectedItems() {
		return this.props.appState.actions.getNumberOfModels(this.selectedItems);
	}

	private onDeleteClick = async () => {
		if (!this._isDeletePopupWindowOpen && PopupWindowV5.numberOfOpenPopups < 1) {
			const {feature, canDeleteReports, transport} = this.props;
			const {selectedItems} = this;
			let isDeletingAllowed = true;

			this._isDeletePopupWindowOpen = true;

			if (feature === XyiconFeature.XyiconCatalog) {
				const isSafeToDeleteResponse = await transport.requestForOrganization({
					url: "xyiconcatalogs/issafetodelete",
					method: XHRLoader.METHOD_POST,
					params: {
						xyiconCatalogIDList: selectedItems.map((catalog) => catalog.id),
					},
				});

				if (isSafeToDeleteResponse.result?.inUseList.length === selectedItems.length) {
					isDeletingAllowed = false;
					const isSingle = selectedItems.length === 1;

					await WarningWindowV5.open(
						`You can't delete ${isSingle ? "this" : "these"} item${isSingle ? "" : "s"}, because ${isSingle ? "it's" : "they're"} already used as xyicons.`,
					);
				}
			} else if (feature === XyiconFeature.Report && !canDeleteReports(selectedItems)) {
				isDeletingAllowed = false;

				await WarningWindowV5.open(`Sorry, you don't have permission to delete this report.`);
			}

			if (isDeletingAllowed) {
				const count = this.getNumberOfSelectedItems();
				const confirmed = await PopupUtilsV5.getDeleteConfirmationPopupV5(feature, count);

				if (confirmed) {
					if (feature === XyiconFeature.Space) {
						const userId = transport.appState.user?.id;
						const orgId = transport.appState.organizationId;
						const localStorageKeyForLastViewedSpace = transport.appState.getLocalStorageKeyForLastViewedSpace(userId, orgId);
						localStorage.removeItem(localStorageKeyForLastViewedSpace);
					}
					await this.deleteItems(selectedItems);
				}
			}

			this._isDeletePopupWindowOpen = false;
		}
	};

	private async deleteItems(items: T[]) {
		const res = await this.props.appState.actions.deleteItems(items, this.props.feature);

		return this.refreshList();
	}

	// ActionBar

	private onActionBarClick = (item: IActionBarItem<T>) => {
		if (item.onClick) {
			item.onClick(this.selectedItems);
		} else {
			const handler = this._actionBar.handlers[item.id];

			if (handler) {
				handler();
			} else {
				console.warn("No handler and default onClick for actionBar", item);
			}
		}
	};

	private canImport = () => {
		const {appState, feature} = this.props;
		const {actions, user} = appState;

		if (user?.isAdmin) {
			return true;
		}

		//TODO?: add this logic to getFieldPermission and hasAnyTypeTheGivenPermissionInModule
		const permissionSet: PermissionSet = actions.getIndividualPortfolioPermissionSet(appState.portfolioId);

		if (permissionSet) {
			if (!permissionSet.isAccessEnabled(feature)) {
				return false;
			}
		} else {
			const groupPermissionSet = actions.getPortfolioGroupPermissionSets(appState.portfolioId)[0];

			if (!groupPermissionSet?.isAccessEnabled(feature)) {
				return false;
			}
		}

		if (actions.hasAnyTypeTheGivenPermissionInModule(feature, Permission.Update)) {
			const fields = actions.getFieldsByFeature(feature);

			return fields.some((field) => actions.getFieldPermission(field) >= Permission.Update);
		}
		return false;
	};

	private onDuplicateClick = (model: T, isCreatePanelNeeded: boolean) => {
		if (model) {
			this._creationCount++;
			this.setState({creating: isCreatePanelNeeded});
		}
	};

	private onActionBarEditClick = () => {
		const model = this.selectedItems[0];

		if (model) {
			if (model.ownFeature === XyiconFeature.XyiconCatalog) {
				this.onCatalogEditClick(model as IModel as Catalog);
			} else {
				this._creationCount++;
				this.setState({creating: true});
				this.props.onEdit(model);
			}
		}
	};

	private onActionBarDuplicateClick = async (item?: T) => {
		if (this.props.feature === XyiconFeature.Portfolio) {
			const confirm = await ConfirmWindowV5.open(
				"Duplicating the selected portfolio will also duplicate the connected spaces, xyicons, boundaries, links, and markups. Do you wish to continue?",
				"Confirm Portfolio Duplication",
			);

			if (confirm) {
				this.onDuplicateClick(item ?? this.selectedItems[0], false);
			}
		} else if (this.props.feature === XyiconFeature.XyiconCatalog) {
			this.onDuplicateCatalogClick((item ?? this.selectedItems[0]) as IModel as Catalog);
		} else {
			this.onDuplicateClick(item ?? this.selectedItems[0], true);
		}
	};

	private onAddClick = () => {
		if (this.props.feature === XyiconFeature.Xyicon) {
			this.props.setCatalogPanelOpen?.(true);
		} else if (this.props.feature === XyiconFeature.Space) {
			CreateSpaceUtils.triggerSpaceCreatePopup();
		} else {
			this.switchToCreatingMode();
			this.props.onAdd?.();
		}
	};

	private onImportClick = () => {
		this.setState({
			isFeatureImportPanelOpen: true,
		});
	};

	public switchToCreatingMode() {
		this._creationCount++;
		this.setState({creating: true});
	}

	// private onSplitterChange = (ratios: number[]) =>
	// {
	// 	const {transport} = this.props;

	// 	transport.services.localStorage.setSplitterRatios(ratios);
	// };

	private onTableSearch = (value: string) => {
		const {feature, appState} = this.props;
		const list = appState.actions.getList(feature);

		if (list.length > Debouncer.limit) {
			// search would be too slow to trigger for each keystroke if the list is long -> add debouncer
			// First wait until user stopped typing
			this._typingDebouncer.debounce(() => {
				// User stopped typing

				if (this.props.appState.actions.areModelsCached()) {
					// models are cached -> no need to show spinner because the search will be fast
					this.setState({
						searchString: value,
						isSearching: false,
					});
				} else {
					// show searching is in progress
					this.setState({isSearching: true});

					// Wait one more second before search starts to allow the search spinner to be rendered
					// (UI gets frozen during search so it helps the user to see a spinner)
					this._listDebouncer.debounceList(() => {
						// spinner is now visible, now start the search by setting the state
						this.setState({
							searchString: value,
							isSearching: false,
						});
					}, list);
				}
			});
		} else {
			this.setState({
				searchString: value,
				isSearching: false,
			});
		}
	};

	private subscribeViewSelection() {
		this._disposer = observe(this.props.appState.selectedViewId, (this.props.feature as any).toString(), (a) => {
			this.resetFilters();
		});
	}

	private unsubscribeViewSelection() {
		this._disposer?.();
		this._disposer = null;
	}

	private get selectedView() {
		const viewFeature = this.props.isSideGrid ? XyiconFeature.SpaceEditor : this.props.feature;

		return this.props.appState.actions.getSelectedView(viewFeature);
	}

	private onCatalogEditClick = async (catalog: Catalog) => {
		if (!this._isLoadingCatalogEditor) {
			this._isLoadingCatalogEditor = true;

			const iconConfig = await this.props.transport.getIconConfigOfCatalog(catalog);

			this.setState({
				editedCatalog: catalog,
				iconConfig: iconConfig,
				catalogMode: "edit",
			});

			this._isLoadingCatalogEditor = false;
		}
	};

	private onDuplicateCatalogClick = async (catalog: Catalog) => {
		if (!this._isLoadingCatalogEditor) {
			this._isLoadingCatalogEditor = true;
			const iconConfig = await this.props.transport.getIconConfigOfCatalog(catalog);

			this.setState({
				editedCatalog: catalog,
				iconConfig: iconConfig,
				catalogMode: "create",
			});

			this._isLoadingCatalogEditor = false;
		}
	};

	private onAddCatalogClick = (event: React.MouseEvent) => {
		if (event.target !== this.props.appState.catalogEditorParentMaybe) {
			this.props.appState.catalogEditorParentMaybe = event.currentTarget as HTMLDivElement;
		}

		this.setState({
			editedCatalog: null,
			iconConfig: null,
			catalogMode: "create",
		});
	};

	private onCloseCatalogEditor = () => {
		this.setState({
			editedCatalog: null,
			iconConfig: null,
			catalogMode: null,
		});
	};

	private getCatalogPanel() {
		const style: CSSProperties = {};

		if (this.props.isCatalogPanelDocked) {
			style.height = "100%";
			style.transform = "initial";
			style.borderRight = `1px solid ${colorPalette.gray.c300}`;
		} else {
			style.left = "initial";
			style.right = "16px";
			style.top = "65px";
			style.transform = "initial";
		}

		return (
			<DockableV5
				style={style}
				spaceViewRenderer={this.props.app.spaceViewRenderer}
				gridView={this._gridView}
				setDocked={this.props.setCatalogPanelDocked}
				setOpen={this.props.setCatalogPanelOpen}
				isDocked={this.props.isCatalogPanelDocked}
				title="Catalog"
				setActiveTool={Functions.emptyFunction}
				onAddCatalogClick={this.onAddCatalogClick}
				onDuplicateCatalogClick={this.onDuplicateCatalogClick}
				onCreateUnplottedXyicons={this.onCreateUnplottedXyicons}
			/>
		);
	}

	private get table() {
		return this.props.appState.tableComponent.current;
	}

	public override async componentDidMount() {
		this.subscribeViewSelection();

		this._isMounted = true;
		await this.refreshList();

		if (this._isMounted) {
			KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
		}
	}

	public override async componentDidUpdate(prevProps: IModuleViewProps<T>, prevState: IModuleViewState<T>) {
		const {appState, transport, feature} = this.props;

		if (appState.lists[feature]?.loaded === false) {
			await transport.services.feature.refreshList(feature);
		}

		if ((this.props.selectedItems && !this.props.setSelectedItems) || (!this.props.selectedItems && this.props.setSelectedItems)) {
			console.warn("You should either provide both selectedItems and setSelectedItems, or shouldn't provide either of them!");
		}

		if ((this.props.focusedItems && !this.props.setFocusedItems) || (!this.props.focusedItems && this.props.setFocusedItems)) {
			console.warn("You should either provide both focusedItems and setFocusedItems, or shouldn't provide either of them!");
		}

		if (this.state.focusedItems.length > 0 && this._portfolioId !== appState.portfolioId && this.props.feature !== XyiconFeature.Portfolio) {
			this.setState({focusedItems: []});
		}

		if (prevState.isPortTemplateEditorOpen && !ObjectUtils.compare(prevState.focusedItems, this.focusedItems)) {
			this.setState({isPortTemplateEditorOpen: false});
		}

		this._portfolioId = appState.portfolioId;
	}

	public override componentWillUnmount() {
		this.unsubscribeViewSelection();
		this.forceResetFilters();
		this._isMounted = false;
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	private resetFocusedItemsArray = () => {
		this.setFocusedItems([]);
		if (this.selectedScreenType === ScreenType.CARD) {
			this.setSelectedItems([]);
		}
	};

	private sortItemsById = (a: T, b: T) => StringUtils.sortIgnoreCase(a.id, b.id);

	private get areSelectedAndFocusedItemsTheSame() {
		return ObjectUtils.compare(this.focusedItems.toSorted(this.sortItemsById), this.selectedItems.toSorted(this.sortItemsById));
	}

	public override render() {
		const {feature, appState, className, actionBar, isSideGrid} = this.props;
		const {creating, searchString, isSearching, initialLoading, sharingViewId, loadingViewId} = this.state;
		const {selectedItems, focusedItems} = this;
		const isLoadingList = !appState.lists[feature].loaded || initialLoading || this.state.isLoadingDependencies;

		const loadingView = appState.actions.getViewById(loadingViewId);

		if (initialLoading) {
			window.setTimeout(() => {
				this.setState({initialLoading: false});
			}, 500);
		}

		const selectedView = this.selectedView;
		const unfilteredItems: T[] = appState.actions.getList(feature);
		const {items, filterSuppression} = this.getFilteredItems(unfilteredItems);
		const creatingOrEditingOrImporting = creating || this.state.catalogMode != null || this.state.isFeatureImportPanelOpen;
		const extendedActionBarButtons = extendActionBarButtonsWithDetailsButton(
			actionBar,
			focusedItems.length > 0 && this.areSelectedAndFocusedItemsTheSame,
			() => this.setFocusedItems(!!this.focusedItems.length && this.areSelectedAndFocusedItemsTheSame ? [] : selectedItems),
		);

		const {type: filterType} = selectedView.filters;
		const filters = selectedView.filters.filters[filterType];
		let currentFiltersCount = 0;

		if (feature !== XyiconFeature.Report) {
			const cachedFilters = appState.actions.filterActions.getCachedObject(
				selectedItems,
				unfilteredItems,
				[this.props.feature],
				filters,
				this.props.feature,
			);

			currentFiltersCount = selectedView.getActiveFilterCount(filterType, cachedFilters);
		}

		return (
			<ModuleViewStyled className={`ModuleView ${className || ""}`}>
				{!this.props.isCatalogPanelDocked && this.props.isCatalogPanelOpen && this.getCatalogPanel()}
				<div
					className={ReactUtils.cls("createPanel", {
						open: creatingOrEditingOrImporting || this.state.managingColumns,
						manageColumns: this.state.managingColumns,
						import: this.state.isFeatureImportPanelOpen,
					})}
				>
					{this.state.catalogMode === "create" && (
						<CatalogCreateV5
							isOpen={true}
							onClose={this.onCloseCatalogEditor}
							editedCatalogMaybe={{
								catalog: this.state.editedCatalog,
								config: this.state.iconConfig,
							}}
						/>
					)}
					{this.state.catalogMode === "edit" && (
						<CatalogItemPanelV5
							onClose={this.onCloseCatalogEditor}
							catalog={this.state.editedCatalog}
							iconConfig={this.state.iconConfig}
							mode={this.state.catalogMode}
							shape={this.state.editedCatalog?.iconCategory === CatalogIconType.ModelParameter ? "Custom Shape" : "Default Shape"}
							step={this.state.editedCatalog?.iconCategory === CatalogIconType.ModelParameter ? "Item properties" : "Select model"}
						/>
					)}
					{this.state.managingColumns && (
						<ManageColumnsPanelV5
							feature={feature}
							view={selectedView}
							onClose={this.onCloseManageColumns}
							parentRef={this._manageColumnsButtonRef}
						/>
					)}
				</div>
				{sharingViewId && (
					<ViewSharingPopup
						view={appState.actions.getViewById(sharingViewId)}
						onClose={() => this.toggleSharingPanel()}
					/>
				)}
				<SplitterV5Tamas>
					{this.props.isCatalogPanelDocked && this.props.isCatalogPanelOpen && this.getCatalogPanel()}
					<MainPanelStyled data-min-width={500}>
						<ActionBarV5
							feature={feature}
							items={items}
							onSearchChange={this.onTableSearch}
							createButtonRef={this.props.createButtonRef}
							manageColumnsButtonRef={this._manageColumnsButtonRef}
							create={this.props.create}
							onCloseCreate={this.onCloseCreate}
							filterCount={currentFiltersCount}
							onResetFilters={() => selectedView.removeAllFiltersFromActiveFilterType()}
							isSideGrid={isSideGrid}
							searchString={searchString}
							isLoadingList={isLoadingList}
							onSelect={this.onSelect}
						/>
						{isLoadingList ? (
							<LoaderV5 label={`Loading ${featureTitlePlurals[feature as keyof typeof featureTitlePlurals]}...`} />
						) : (
							<>
								{loadingView && <LoaderV5 label={`Loading "${loadingView.name}" View`} />}
								{this.selectedScreenType === ScreenType.CARD ? (
									<CardViewV5
										feature={feature}
										items={items}
										filterSuppression={filterSuppression}
										selected={selectedItems}
										focused={focusedItems}
										onSelect={this.onSelect}
										onFocus={this.onFocus}
										onOpenSpace={this.onDoubleClick}
										onAddClick={this.onAddClick}
										searchString={searchString}
										actionBarButtons={extendedActionBarButtons}
										actionBarDefaultClick={this.onActionBarClick}
										onCloseGridActionBar={this.onCloseCardActionBar}
									/>
								) : (
									<GridViewV5
										ref={this._gridView}
										feature={feature}
										items={items}
										filterSuppression={filterSuppression}
										selected={selectedItems}
										focused={focusedItems}
										view={selectedView}
										onSelect={this.onSelect}
										onFocus={this.onFocus}
										onDoubleClick={this.onDoubleClick}
										onAddClick={this.props.onAdd && this.onAddClick}
										searchString={searchString}
										isSearching={isSearching}
										tableRowIcon={true}
										isCellContentsWrappingOn={this.state.isCellContentsWrappingOn}
										emptyListText={this.props.emptyListText}
										onManageColumns={this.props.manageColumns !== false && this.onManageColumns}
										actionBarButtons={extendedActionBarButtons}
										actionBarDefaultClick={this.onActionBarClick}
										onCloseGridActionBar={this.onCloseGridActionBar}
										onDuplicateClick={this.onActionBarDuplicateClick}
										selectedScreen={this.selectedScreenType}
									/>
								)}
							</>
						)}
					</MainPanelStyled>
				</SplitterV5Tamas>
				{this.props.manageDetailsPanel && (
					<Panel isOpen={focusedItems.length > 0 && !initialLoading}>
						<DetailsTabV5
							items={focusedItems}
							feature={feature}
							features={[feature]}
							isPortTemplateEditorOpen={this.state.isPortTemplateEditorOpen}
							setPortTemplateEditorOpen={(value) => this.setState({isPortTemplateEditorOpen: value})}
							onCatalogIconEditClick={this.onCatalogEditClick}
							onSelect={this.onSelect}
							onFocus={this.onFocus}
							onCloseOverlayedDetaislPanel={this.resetFocusedItemsArray}
						/>
					</Panel>
				)}
			</ModuleViewStyled>
		);
	}
}

const ModuleViewStyled = styled.div`
	position: relative;
	min-width: 0;
	flex: 1;

	.sharingPanel,
	.createPanel {
		display: none;
	}
`;

const MainPanelStyled = styled.div`
	${VerticalFlexStyle};
	max-height: calc(100vh - 64px);
`;
