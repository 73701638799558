import {Permission} from "../../../generated/api/base";
import {ShareOption} from "../../modules/abstract/view/sharing/ShareOption";
import {SelectInputV5} from "../input/select/SelectInputV5";
import {HorizontalAlignment} from "../../../utils/dom/DomUtils";

interface IOption {
	id: number;
	label: string;
}

interface IShareOptionProps {
	readonly value: number;
	readonly onChange: (value: number) => void;
	readonly hasOwner: boolean;
	readonly disabled?: boolean;
	readonly hasEdit?: boolean;
}

export const ShareOptionV5 = (props: IShareOptionProps) => {
	const {value, onChange, hasOwner, hasEdit, disabled = false} = props;

	const getOptions = () => {
		const options = [];

		if (hasOwner) {
			options.push({
				id: ShareOption.OWNER,
				label: "Owner",
			});
		}

		if (hasEdit !== false) {
			options.push({
				id: Permission.Update,
				label: "Can Edit",
			});
		}

		options.push({
			id: Permission.View,
			label: "Can View",
		});

		return options;
	};

	const permissionValue: Permission = props.value;
	let placeholder: string;

	if (permissionValue == Permission.Update) {
		placeholder = "Can Edit";
	} else if (permissionValue == Permission.View) {
		placeholder = "Can View";
	}

	const options = getOptions();

	return (
		<SelectInputV5
			className="sharedStyles shareOption"
			selected={options.find((o) => o.id === value)}
			onChange={(option) => onChange(option.id)}
			options={options}
			disabled={disabled}
			horizontalAlignment={HorizontalAlignment.right}
			placeholder={placeholder}
			dropdownFixedWidth="128px"
			optionFontSize="14px"
			render={(option) => {
				return (
					<div className="hbox alignCenter shareOptionItem">
						<div className="label flex_1">{option.label}</div>
					</div>
				);
			}}
		/>
	);
};
