import styled from "styled-components";
import {Observer} from "mobx-react";
import type {RefObject} from "react";
import {useRef} from "react";
import type {View} from "../../../../data/models/View";
import {useAppStore} from "../../../../StateManager";
import {ViewPreferenceCategory, XyiconFeature} from "../../../../generated/api/base";
import {ELLIPSIS, fontSize} from "../../styles/styles";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import type {IViewFolder} from "../../../../data/models/ViewUtils";
import {doesStructureContainElement, rootFolderId} from "../../../../data/models/ViewUtils";
import {onWorkspaceViewClick} from "../../topbar/ViewTabsCommon";
import type {WorkspaceViewType} from "./WorkspaceViewCommon";
import {ViewFolderStructureReactV5} from "./ViewFolderStructureReactV5";
import {ViewItemV5} from "./ViewItemV5";
import type {ViewFolderV5} from "./ViewFolderV5";

const getTextForEmpty = (type: WorkspaceViewType): string => {
	switch (type) {
		case "Favorites":
			return "There are no favorite views";
		case "Global":
			return "There are no global views";
		case "My Views":
			return "No views created";
		case "Shared With Me":
			return "There are no views shared with you";
	}
};

interface IViewContainerProps {
	readonly type: WorkspaceViewType;
	readonly forceUpdateParent: () => void;
	readonly onAddClick?: (viewFolderId: string) => void;
	readonly onShareClick: (viewItem: View | IViewFolder) => void;
	readonly isOpen: boolean;
	readonly searchString: string;
	readonly lastElementInList: RefObject<ViewFolderV5>;
}

export const WorkspaceViewContainer = (props: IViewContainerProps) => {
	const {forceUpdateParent, onShareClick, searchString, isOpen, lastElementInList} = props;
	const appState = useAppStore((state) => state.appState);
	const setViewForOpenSpaceSelector = useAppStore((state) => state.setViewForOpenSpaceSelector);
	const timerRef = useRef<number>(-1);

	const onViewClick = (view: View) => {
		onWorkspaceViewClick(view, false, setViewForOpenSpaceSelector);
	};

	return (
		<Observer>
			{() => {
				if (!isOpen && !searchString) {
					return <></>;
				}

				const user = appState.user;

				const views = [
					...appState.actions.getViews(XyiconFeature.Xyicon),
					...appState.actions.getViews(XyiconFeature.Boundary),
					...appState.actions.getViews(XyiconFeature.SpaceEditor),
				];

				let filteredViews = views
					.filter((view) => {
						switch (props.type) {
							case "Favorites":
								return view.isFavorite;
							case "Global":
								return view.isGlobal;
							case "My Views":
								return view.ownedBy === user?.id;
							case "Shared With Me":
								return view.ownedBy !== user?.id && !view.isGlobal;
						}
					})
					.toSorted((a: View, b: View) => StringUtils.sortIgnoreCase(a.name, b.name));

				if (
					(filteredViews.length === 0 && (props.type === "My Views" || props.type === "Shared With Me")) ||
					(props.type === "Global" && appState.organization?.globalViews?.length === 0) ||
					(props.type === "Favorites" && user?.favoriteViews?.length === 0)
				) {
					return (
						<ViewContainerStyled>
							<TextTruncatedStyled style={{marginLeft: "16px"}}>{getTextForEmpty(props.type)}</TextTruncatedStyled>
						</ViewContainerStyled>
					);
				}

				// Do this after we show the "empty" messages for the sections, otherwise there's no difference whether the section is really empty,
				// Or the items are just filtered out because of the searchString
				filteredViews = filteredViews.filter((v: View) => StringUtils.containsIgnoreCase(v.name, searchString));

				const viewFolderStructure =
					props.type === "Favorites" ? user.favoriteViews : props.type === "Global" ? appState.organization.globalViews : [];

				if (searchString) {
					const filteredViewFolderStructure = viewFolderStructure.filter((v) =>
						doesStructureContainElement([v].flat(), searchString, appState.actions),
					);
					const showNoResultsMessage: boolean =
						(props.type === "Favorites" || props.type === "Global" ? filteredViewFolderStructure : filteredViews).length === 0;

					if (showNoResultsMessage) {
						return (
							<ViewContainerStyled>
								<TextTruncatedStyled style={{marginLeft: "16px"}}>No matching results found.</TextTruncatedStyled>
							</ViewContainerStyled>
						);
					}
				}

				if (props.type === "Favorites" || props.type === "Global") {
					const saveViewFolderStructureToDatabase = () => {
						// This function gets called by all levels of the ViewFolderStructureReact component
						// Eg.: if you move a view from a folder to root (or vica versa), this will get called once
						// from within the "folder" component (which modifies only the items within the folder),
						// then almost immediately the parent (root) element receives the item (from the folder),
						// so it gets called again. We don't want to save the first one to the database, as it's not an
						// in-between state: the item has been already moved out from the folder, but it's not present in the
						// root yet.
						// So for these scenarios, I applied this little timeout hack below...
						clearTimeout(timerRef.current);
						timerRef.current = window.setTimeout(() => {
							if (props.type === "Favorites") {
								user.setFavoriteViews(user.favoriteViews);
							} else if (props.type === "Global") {
								appState.organization.setGlobalViews(appState.organization.globalViews);
							}
						}, 50);
					};

					const removeViewElementFromStructure = (viewElementId: string): boolean => {
						if (props.type === "Favorites") {
							return user.removeElementFromFavoriteViews(viewElementId);
						} else if (props.type === "Global") {
							return appState.organization.removeElementFromGlobalViews(viewElementId);
						}
					};

					return (
						<ViewFolderStructureReactV5
							level={0}
							type={props.type}
							viewFolder={{id: rootFolderId, name: "", category: ViewPreferenceCategory.Folder, children: viewFolderStructure, isOpen: true}}
							saveViewFolderStructureToDatabase={saveViewFolderStructureToDatabase}
							removeViewElementFromStructure={removeViewElementFromStructure}
							lastElementInList={lastElementInList}
							onAddClick={props.onAddClick}
							onViewClick={onViewClick}
							onShareClick={onShareClick}
							forceUpdateParent={forceUpdateParent}
							searchString={searchString}
						/>
					);
				} else {
					return (
						<ViewContainerStyled>
							{filteredViews.map((view) => (
								<ViewItemV5
									key={view.id}
									view={view}
									type={props.type}
									onViewClick={onViewClick}
									onShareClick={onShareClick}
									forceUpdateParent={forceUpdateParent}
									searchString={searchString}
								/>
							))}
						</ViewContainerStyled>
					);
				}
			}}
		</Observer>
	);
};

const TextTruncatedStyled = styled.div`
	${ELLIPSIS}
`;

const ViewContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	font-size: ${fontSize.md};
	line-height: 16px;
`;
