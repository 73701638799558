import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
// import {LoaderIcon} from "../../widgets/button/LoaderIcon";
import type {AppState} from "../../../data/state/AppState";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {Button} from "../../widgets/button/Button";
import {handleLogin} from "../../../utils/LoginUtils";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {ReactUtils} from "../../utils/ReactUtils";
import {LoaderIcon} from "../../widgets/button/LoaderIcon";
import type {XyiconLoginAndSSOLoginState} from "./LoginWindow";

interface IILoginWindowProps {
	readonly navigation?: Navigation;
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
	readonly isOrganizationSwitchView?: boolean;
	readonly handleOrganizationSwitch?: () => Promise<void>;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class SSOLoginWindow extends React.Component<IILoginWindowProps, XyiconLoginAndSSOLoginState> {
	constructor(props: IILoginWindowProps) {
		super(props);
		this.state = {
			isValidEmail: false,
			inputString: "",
		};
	}

	public override componentDidMount() {
		window.addEventListener("hashchange", this.onHashChange);
	}

	public override componentWillUnmount() {
		window.removeEventListener("hashchange", this.onHashChange);
	}

	private onHashChange = () => {
		this.props.appState.isLoggingIn = false;
	};

	private goBackToNormalLogin = () => {
		this.props.navigation.go("auth/login");
	};

	private validateInputs = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {
			target: {value},
		} = event;
		const isValid = StringUtils.emailValidator(value);
		this.setState({isValidEmail: isValid, inputString: value});
	};

	public override render() {
		const {isOrganizationSwitchView = false} = this.props;
		const {user, isLoggingIn} = this.props.appState;
		const {isValidEmail, inputString} = this.state;
		const isDisabled = isLoggingIn || !isValidEmail;

		return (
			<div className="LoginWindow">
				<div className="loginContainer">
					<div className="formContainer hbox alignCenter">
						<SVGIcon
							classNames="xyicon-logo"
							icon="xyiconlogo"
						/>
						<form
							className="vbox alignCenter"
							role="form"
							name="formLogin"
							noValidate
							autoCapitalize="off"
							onSubmit={this.onSubmit}
						>
							<div className="inputGroup">
								<input
									id="userName"
									name="userName"
									type="text"
									className="Input"
									placeholder="Email"
									onChange={(event) => this.validateInputs(event)}
									value={isOrganizationSwitchView ? user?.email : inputString}
								/>
							</div>
							<button
								name="submitButton"
								className={ReactUtils.cls("Button primary", {disabled: isDisabled, loginButton: true})}
								type="submit"
								disabled={isDisabled}
							>
								{isLoggingIn ? <LoaderIcon /> : "Log in with SSO"}
							</button>
							<div className="sso-button-container">
								<button
									type="button"
									className="sso-login-text"
									disabled={isLoggingIn}
									onClick={this.goBackToNormalLogin}
								>
									Go back to login page
								</button>
							</div>
							<Button
								onClick={() => this.props.navigation.openInNewTab("https://support.xyicon.com/docs")}
								className="naked small helpbutton"
								icon="help"
								label="Help"
							/>
						</form>
					</div>
				</div>
			</div>
		);
	}

	private handleLoginStateChange = (state: XyiconLoginAndSSOLoginState) => {
		this.setState(state);
	};

	private onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();
		const {transport, appState, isOrganizationSwitchView, navigation, handleOrganizationSwitch} = this.props;
		this.props.appState.lastError = "";

		handleLogin({
			event,
			transport,
			appState,
			navigation,
			isOrganizationSwitchView,
			loginWithSSO: true,
			handleOrganizationSwitch,
			handleLoginStateChange: this.handleLoginStateChange,
		});
	};
}
