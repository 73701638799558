import {useReducer} from "react";
import {useAppStore} from "../../../StateManager";
import type {Portfolio} from "../../../data/models/Portfolio";
import type {Report} from "../../../data/models/Report";
import {XyiconFeature} from "../../../generated/api/base";
import {ReportScope} from "../../../generated/api/reports";
import {SelectInputV5} from "../input/select/SelectInputV5";
import {FieldV5} from "./FieldV5";
import {MultiSelectInputV5} from "./datatypes/multi/MultiSelectInputV5";

interface IScopeOption {
	id: ReportScope;
	label: string;
}

const _scopeOptions: IScopeOption[] = [
	{
		id: ReportScope.CurrentPortfolio,
		label: "Current Portfolio",
	},
	{
		id: ReportScope.PortfolioIDList,
		label: "Portfolio list",
	},
	{
		id: ReportScope.Organization,
		label: "Organization",
	},
];

interface IReportScopeWidgetProps {
	readonly report: Report;
	readonly onChange?: () => void;
	readonly disabled?: boolean;
}

export const ReportScopeWidgetV5 = (props: IReportScopeWidgetProps) => {
	const appState = useAppStore((state) => state.appState);
	const {report, onChange, disabled = false} = props;
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const onChangeScope = (option: IScopeOption) => {
		report.scope = option.id;
		onChange?.();
		forceUpdate();
	};

	const onChangePortfolioIDList = (value: Portfolio[]) => {
		report.portfolioIDList = value.map((p) => p.id);
		onChange?.();
		forceUpdate();
	};

	const getSelectedOptions = () => {
		return appState.actions.getList(XyiconFeature.Portfolio).filter((option) => report.portfolioIDList.includes(option.id)) as Portfolio[];
	};

	const selectedScope = _scopeOptions.find((option) => option.id === report.scope);

	const finalDisabled = disabled || report.reportFeature === XyiconFeature.XyiconCatalog;

	return (
		<>
			<FieldV5 label="Scope">
				{disabled ? (
					selectedScope.label
				) : (
					<SelectInputV5
						sort={false}
						options={_scopeOptions}
						render={(option) => option.label}
						selected={selectedScope}
						onChange={onChangeScope}
						disabled={finalDisabled}
						isSameWidth={true}
						// verticalAlignment={VerticalAlignment.bottomOuter}
					/>
				)}
			</FieldV5>
			{report.scope === ReportScope.CurrentPortfolio && (
				<FieldV5
					label="Portfolio"
					className="text currenPortfolio"
				>
					{appState.actions.getCurrentPortfolioName()}
				</FieldV5>
			)}
			{report.scope === ReportScope.Organization && (
				<FieldV5
					label="Organization"
					className="text"
				>
					{appState.actions.getCurrentOrganizationName()}
				</FieldV5>
			)}
			{report.scope === ReportScope.PortfolioIDList && (
				<FieldV5 label="List">
					<MultiSelectInputV5
						options={appState.actions.getList(XyiconFeature.Portfolio) as Portfolio[]}
						render={(option) => option.name}
						selected={getSelectedOptions()}
						onChange={onChangePortfolioIDList}
						disabled={finalDisabled}
						isSameWidth={true}
					/>
				</FieldV5>
			)}
		</>
	);
};
