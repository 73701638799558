import {lazy, Suspense} from "react";
import {LoaderV5} from "../../../../loader/LoaderV5";
import type {Report} from "../../../../../../data/models/Report";

interface ILazyLoaderProps {
	readonly url: string;
	readonly report: Report;
	readonly onClose: () => void;
}

const componentMap: Record<string, () => Promise<{default: React.ComponentType<any>}>> = {
	KaiserCustomInputsForReports: () => import("./KaiserCustomInputsForReports"),
	TestCustomInputsForReports: () => import("./TestCustomInputsForReports"),
};

export const DynamicComponent = (props: ILazyLoaderProps) => {
	const loader = componentMap[props.url];
	const LazyLoadedComponent = loader ? lazy(loader) : () => <div>Invalid component type</div>;

	return (
		<Suspense fallback={<LoaderV5 />}>
			<LazyLoadedComponent {...props} />
		</Suspense>
	);
};
