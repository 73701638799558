import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import type {IModel} from "../../../../data/models/Model";
import {useAppStore} from "../../../../StateManager";
import {XyiconFeature} from "../../../../generated/api/base";
import {NavigationEnum} from "../../../../Enums";
import {
	MultiDefaultFieldSortIcons,
	MultiDefaultFieldSortOrder,
	maxNumberOfIconsV5,
} from "../../../modules/abstract/sidepanel/tabs/details/default/MultiDefaultFieldUtils";
import type {Xyicon} from "../../../../data/models/Xyicon";
import {InitialsV5} from "../../widgets/InitialsV5";
import {FieldV5} from "../../details/FieldV5";
import {XyiconModelFieldStyled, XyiconModelFieldV5} from "../../details/datatypes/XyiconModelFieldV5";
import {MoreButtonStyled} from "../../details/datatypes/multi/MultiLineLabelV5";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {CardLayoutToolTip} from "../../../modules/space/spaceeditor/ui/toolbar/CardLayoutToolTip";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {Functions} from "../../../../utils/function/Functions";
import {baseDistance} from "../../styles/styles";
import {MoreIconsPopupV5} from "./MoreIconsPopupV5";

interface IMultiDefaultFieldsV5Props {
	readonly items: IModel[];
	readonly onFocus?: (items: IModel[]) => void;
	readonly onSelect?: (items: IModel[]) => void;
}

export const MultiDefaultFieldsV5 = (props: IMultiDefaultFieldsV5Props) => {
	const {items, onFocus, onSelect} = props;
	const appState = useAppStore((state) => state.appState);
	const {navigation} = appState.app;

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [itemIndex, setItemIndex] = useState<number>(-1);
	const [tooltipTransform, setTooltipTransform] = useState<TransformObj>(null);

	const _showAllDivRef = useRef<HTMLDivElement>();
	const _cardLayoutTooltipRef = useRef<HTMLDivElement>();
	const _initialsRefArray = useRef([]);

	const onClickCheckboxWithinMoreIconsPopup = (items: IModel[]) => {
		onFocus?.(items);
		onSelect?.(items);
	};

	const onClickIcon = (model: IModel) => {
		if ([XyiconFeature.Boundary, XyiconFeature.Xyicon, XyiconFeature.Markup].includes(model.ownFeature)) {
			appState.actions.navigateToSpaceItemById(model.id, model.ownFeature as XyiconFeature.Xyicon | XyiconFeature.Boundary | XyiconFeature.Markup);
		} else if (model.ownFeature === XyiconFeature.Portfolio) {
			onFocus?.([model]);
		} else if (model.ownFeature === XyiconFeature.Space) {
			navigation.goApp(NavigationEnum.NAV_SPACE, model.id);
		}
	};

	const onMouseOver = (index: number) => {
		setItemIndex(index);
	};

	const onMouseLeave = () => {
		setItemIndex(-1);
	};

	useEffect(() => {
		if (itemIndex !== -1) {
			setTooltipTransform(
				DomUtils.getFixedFloatingElementPosition(
					_initialsRefArray.current[itemIndex],
					_cardLayoutTooltipRef.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
				),
			);
		}
	}, [itemIndex]);

	{
		const allowedItems = items.filter((item) => MultiDefaultFieldSortOrder.includes(item.ownFeature));

		if (!allowedItems.length) {
			return null;
		}

		const sortedItems = allowedItems.toSorted(MultiDefaultFieldSortIcons);
		const xyicons = allowedItems.filter((item) => item.ownFeature === XyiconFeature.Xyicon) as Xyicon[];

		const inlineStyle: React.CSSProperties = _cardLayoutTooltipRef && {
			position: "absolute",
			top: 0,
			left: 0,
			transform: tooltipTransform?.translate,
		};

		return (
			<MultiDefaultFieldsStyled className="default-fields multi">
				<InitialsStyled>
					{sortedItems.map((item: IModel, index: number) => {
						const detailedItem = appState.actions.getTypeById(item?.typeId);
						const color = detailedItem?.settings.color.hex || "FFFFFF";

						return (
							<div
								key={item.id}
								ref={(ref) => (_initialsRefArray.current[index] = ref)}
								className="thumbnail"
								onClick={() => onClickIcon(item)}
								onMouseOver={() => onMouseOver(index)}
								onMouseLeave={onMouseLeave}
							>
								<InitialsV5
									item={item}
									color={color}
									name={detailedItem?.name}
									className={item.ownFeature === XyiconFeature.XyiconCatalog ? "noClick" : ""}
									thumbnailSize={50}
								/>
								{itemIndex === index && (
									<DomPortal destination={appState.app.modalContainer}>
										<CardLayoutToolTip
											item={item as Xyicon}
											divRef={_cardLayoutTooltipRef}
											style={inlineStyle}
										/>
									</DomPortal>
								)}
							</div>
						);
					})}
				</InitialsStyled>
				{sortedItems.length > maxNumberOfIconsV5 && (
					<ShowAllButtonStyled
						ref={_showAllDivRef}
						className="moreButton"
						onClick={() => setIsOpen((o) => !o)}
						onMouseDown={Functions.stopPropagation} // to prevent useClickOutSide from triggering "open" again
					>
						<u>show all</u>
					</ShowAllButtonStyled>
				)}
				{isOpen && (
					<MoreIconsPopupV5
						items={items}
						parentRef={_showAllDivRef}
						onClose={() => setIsOpen(false)}
						onClickCheckbox={onClickCheckboxWithinMoreIconsPopup}
					/>
				)}
				{xyicons.length > 0 && (
					<FieldV5 label="Model">
						<XyiconModelFieldV5
							xyicons={xyicons}
							disabled={false}
						/>
					</FieldV5>
				)}
			</MultiDefaultFieldsStyled>
		);
	}
};

const MultiDefaultFieldsStyled = styled.div`
	${XyiconModelFieldStyled} {
		left: 0;
	}
`;

const InitialsStyled = styled.div`
	display: flex;
	gap: ${baseDistance.xs};
	overflow: hidden;
	margin-bottom: ${baseDistance.sm};

	&:hover {
		overflow-x: scroll;
		margin-bottom: 0;
	}
`;

const ShowAllButtonStyled = styled(MoreButtonStyled)`
	display: flex;
	flex-direction: row-reverse;
	margin-top: 0;
	margin-bottom: ${baseDistance.sm};
	margin-left: auto;
	width: fit-content;
`;
