import {Observer} from "mobx-react";
import type {CSSProperties, MouseEvent as ReactMouseEvent} from "react";
import {useCallback, useRef, useState} from "react";
import styled from "styled-components";
import {PortfolioAvatar} from "../PortfolioAvatar";
import {DropdownButtonV5} from "../interaction/DropdownButtonV5";
import {useAppStore} from "../../../StateManager";
import {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import type {Portfolio} from "../../../data/models/Portfolio";
import {XyiconFeature} from "../../../generated/api/base";
import {baseDistance, navBarZIndex, zIndex} from "../styles/styles";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {useClickOutside} from "../utils";
import type {IViewFolder} from "../../../data/models/ViewUtils";
import type {View} from "../../../data/models/View";
import {getWorkspaceViewTabsFromLocalStorage, onWorkspaceViewClick} from "../topbar/ViewTabsCommon";
import {NavigationEnum} from "../../../Enums";
import {colorPalette} from "../styles/colorPalette";
import {ClassNameOfViewPopout} from "./FloatingMenu";
import {NavigationViewSection} from "./views/NavigationViewSection";
import type {WorkspaceViewType} from "./views/WorkspaceViewCommon";

interface IPortfolioSelectorProps {
	readonly isHome: boolean;
	readonly openCreateViewPopup: (type: WorkspaceViewType, viewFolderId: string) => void;
	readonly onViewItemShareClick: (viewItem: View | IViewFolder) => void;
}

export const PortfolioSelector = (props: IPortfolioSelectorProps) => {
	const appState = useAppStore((state) => state.appState);
	const navBarState = useAppStore((state) => state.navBarState);
	const setViewForOpenSpaceSelector = useAppStore((state) => state.setViewForOpenSpaceSelector);
	const {isHome, openCreateViewPopup, onViewItemShareClick} = props;
	const [showFullFloatingMenu, setShowFullFloatingMenu] = useState<boolean>(false);
	const timeOutIdForHover = useRef<number>(-1);
	const ref = useRef<HTMLDivElement>();
	const floatingMenuRef = useRef<HTMLDivElement>();
	const modalContainerRef = useRef<HTMLDivElement>();
	const isPortfolioNameAvailable = !!appState.actions.getPortfolioById(appState.portfolioId)?.name;

	modalContainerRef.current = appState.app.modalContainer;

	const onSwitchPortfolio = (portfolio: Portfolio) => {
		return appState.app.transport.services.auth.switchPortfolio(portfolio.id);
	};

	const closeFullFloatingMenu = useCallback(
		(event: MouseEvent) => {
			if (!appState.app.modalContainer?.contains(event.target as Element)) {
				setShowFullFloatingMenu(false);
			}
		},
		[appState],
	);

	useClickOutside([ref, floatingMenuRef, modalContainerRef], closeFullFloatingMenu);

	const floatingMenuInlineStyle: CSSProperties = {
		left: navBarState === "docked" || navBarState === "open" ? "200px" : "64px",
	};

	const handleViewItemShareClick = (event: View | IViewFolder) => {
		onViewItemShareClick(event);
		onMouseLeave();
	};

	const onMouseEnter = () => {
		clearTimeout(timeOutIdForHover.current);
		timeOutIdForHover.current = window.setTimeout(() => {
			setShowFullFloatingMenu(true);
		}, 400);
	};

	const onMouseLeave = () => {
		clearTimeout(timeOutIdForHover.current);
		timeOutIdForHover.current = window.setTimeout(() => {
			setShowFullFloatingMenu(false);
		}, 400);
	};

	const onPortfolioAvatarClick = (event: ReactMouseEvent) => {
		event.stopPropagation();

		const viewTabsInLocalStorage = getWorkspaceViewTabsFromLocalStorage(appState.user?.id, appState.organizationId);

		if (viewTabsInLocalStorage.length > 0) {
			let viewTabToChoose = viewTabsInLocalStorage[0];

			for (let i = 1; i < viewTabsInLocalStorage.length; ++i) {
				const viewTabToChooseMaybe = viewTabsInLocalStorage[i];

				if (viewTabToChoose.lastUsed < viewTabToChooseMaybe.lastUsed) {
					viewTabToChoose = viewTabToChooseMaybe;
				}
			}

			onWorkspaceViewClick(appState.actions.getViewById(viewTabToChoose.viewId), false);
		} else {
			appState.app.navigation.goApp(NavigationEnum.NAV_BLANK);
		}
	};

	return (
		<Observer>
			{() => {
				const portfolios = appState.actions
					.getList<Portfolio>(XyiconFeature.Portfolio)
					.toSorted((a: Portfolio, b: Portfolio) => StringUtils.sortIgnoreCase(a.name, b.name));

				if (isHome) {
					return (
						<>
							{isPortfolioNameAvailable && (
								<PortfolioAvatar
									name={(appState.actions.getPortfolioById(appState.portfolioId)?.name ?? "").trim()}
									thumbnailOnly={navBarState === "closed"}
									onClick={onPortfolioAvatarClick}
									onMouseEnter={onMouseEnter}
									onMouseLeave={onMouseLeave}
									divRef={ref}
								/>
							)}
							{showFullFloatingMenu && (
								<ViewPopoutStyled
									ref={floatingMenuRef}
									style={floatingMenuInlineStyle}
									className={ClassNameOfViewPopout}
									onMouseEnter={onMouseEnter}
									onMouseLeave={onMouseLeave}
								>
									<DropdownButtonV5
										button={
											<PortfolioAvatar
												name={(appState.actions.getPortfolioById(appState.portfolioId)?.name ?? "").trim()}
												thumbnailOnly={false}
											/>
										}
										optionsZIndex={navBarZIndex + 1}
										options={portfolios.map((p) => ({
											label: p.name,
											onClick: () => onSwitchPortfolio(p),
											isActive: p.id === appState.portfolioId,
										}))}
										verticalAlignment={VerticalAlignment.bottom}
										horizontalAlignment={HorizontalAlignment.outerRight}
										offsetX={8}
										offsetY={0}
										width="272px"
										showSearch={true}
										closeOnMouseLeave={true}
									/>
									<NavigationViewSection
										openCreateViewPopup={openCreateViewPopup}
										onlyIcon={false}
										isHome={isHome}
										onViewItemShareClick={handleViewItemShareClick}
										searchString=""
									/>
								</ViewPopoutStyled>
							)}
						</>
					);
				} else {
					return (
						<DropdownButtonV5
							button={
								<PortfolioAvatar
									name={(appState.actions.getPortfolioById(appState.portfolioId)?.name || "").trim()}
									thumbnailOnly={navBarState === "closed"}
								/>
							}
							optionsZIndex={navBarZIndex + 1}
							options={portfolios.map((p) => ({
								label: p.name,
								onClick: () => onSwitchPortfolio(p),
								isActive: p.id === appState.portfolioId,
							}))}
							verticalAlignment={VerticalAlignment.top}
							horizontalAlignment={HorizontalAlignment.outerRight}
							offsetX={8}
							width="272px"
							showSearch={true}
							closeOnMouseLeave={true}
						/>
					);
				}
			}}
		</Observer>
	);
};

const ViewPopoutStyled = styled.div`
	position: absolute;
	top: 65px;
	width: 336px;
	height: calc(100vh - 65px);
	background-color: ${colorPalette.blueGray.c100};
	padding: ${baseDistance.sm};
	overflow-y: auto;
	z-index: ${zIndex.primaryNavigation + 1};
`;
