import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {App} from "../../../../App";
import type {AppState} from "../../../../data/state/AppState";
import type {Markup} from "../../../../data/models/Markup";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import {FieldV5} from "../../details/FieldV5";
import {IDateFieldFormat} from "../../details/datatypes/DateFieldSettingsV5";
import {SelectInputStyled, SelectInputV5} from "../../input/select/SelectInputV5";
import {MarkupsWithInterchangeableProperties} from "../../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import type {Markup3D} from "../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {MarkupType} from "../../../../generated/api/base";
import {changeMarkupType, markupTypeToMarkupToolName} from "../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import type {ExtendedToolIconKey} from "../../modules/spaceeditor/toolbar/ToolButtonV5";
import {ToolButtonStyled, ToolButtonV5} from "../../modules/spaceeditor/toolbar/ToolButtonV5";
import {FieldStyled} from "../../details/Field.styled";
import {baseDistance, ELLIPSIS, radius} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";

interface IMarkupDefaultFieldsProps {
	readonly item: Markup;
	readonly app?: App;
	readonly appState?: AppState;
}

@inject("app")
@inject("appState")
@observer
export class MarkupDefaultFieldsV5 extends React.Component<IMarkupDefaultFieldsProps> {
	private get spaceViewRenderer() {
		return this.props.appState.app.graphicalTools.spaceViewRenderer;
	}

	private get markup3DMaybe(): Markup3D | null {
		return this.spaceViewRenderer.markupManager.getItemById(this.props.item.id) as Markup3D;
	}

	private onMarkupTypeChange = (markupType: MarkupType) => {
		changeMarkupType([this.props.item], markupType, this.spaceViewRenderer);
	};

	private markupTypeRender = (markupType: MarkupType, selected: boolean) => {
		const formattedMarkupType = `${MarkupType[markupType].replaceAll("_", " ")} Markup`;

		const tools = this.props.appState.app.graphicalTools.spaceViewRenderer.tools;
		const icon = (
			<ToolButtonV5
				color={{hex: this.markup3DMaybe?.color ?? this.props.item?.color ?? "FF0000"}}
				icon={tools[markupTypeToMarkupToolName(markupType)].icon as ExtendedToolIconKey}
			/>
		);

		return (
			<MarkupTypeRowStyled>
				{!selected && icon}
				<span>{formattedMarkupType}</span>
			</MarkupTypeRowStyled>
		);
	};

	public override render() {
		const {item, appState} = this.props;

		return (
			<MarkupDefaultFieldsStyled>
				<MarkupTypeChangerStyled className="stucked">
					<LabelStyled>Markup Type</LabelStyled>
					{item.isTypeChangeable ? (
						<SelectInputV5
							options={MarkupsWithInterchangeableProperties}
							selected={item.type}
							render={(markupType: MarkupType) => this.markupTypeRender(markupType, false)}
							onChange={this.onMarkupTypeChange}
							isSameWidth={true}
							searchBarMode="always off"
							renderSelectedWhenClosed={(markupType: MarkupType) => this.markupTypeRender(markupType, true)}
							dropdownMaxHeight="initial"
						/>
					) : (
						item.typeName
					)}
				</MarkupTypeChangerStyled>
				<FieldV5 label="Last Modified At">{DateFormatter.format(item.data.lastModifiedAt, IDateFieldFormat.DATETIME)}</FieldV5>
				<FieldV5 label="Last Modified By">{appState.actions.findUser(item.data.lastModifiedBy)?.fullName || ""}</FieldV5>
			</MarkupDefaultFieldsStyled>
		);
	}
}

const MarkupTypeRowStyled = styled.div`
	display: flex;
	align-items: center;
	gap: ${baseDistance.sm};
	width: 100%;

	span {
		${ELLIPSIS}
	}

	${ToolButtonStyled} {
		min-width: 32px;
	}
`;

const LabelStyled = styled.div`
	width: 150px;
`;

const MarkupTypeChangerStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${SelectInputStyled} {
		flex: 1;
	}
`;

const MarkupDefaultFieldsStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.sm};

	${FieldStyled} {
		.element {
			height: 32px;
			border: 1px solid ${colorPalette.gray.c300};
			border-radius: ${radius.sm};
			padding-left: ${baseDistance.sm};

			${SelectInputStyled} {
				border: none;
				padding-left: 0;
			}
		}
	}
`;
