import type {ISingleSelectFieldSettingsDefinition} from "../../../../data/models/field/datatypes/SingleSelect";
import {HorizontalAlignment} from "../../../../utils/dom/DomUtils";
import {SelectInputV5, SingleSelectPillStyled} from "../../input/select/SelectInputV5";
import type {IInputPropsV5} from "../../../widgets/input/clicktoedit/InputUtils";
import Flag from "../../icons/flag.svg?react";
import Circle from "../../icons/circle.svg?react";

export const renderIconComponent = (type: string) => {
	switch (type) {
		case "flag":
			return <Flag />;
		case "pill":
			return <Circle />;
		case "colorBar":
		case "colorBarFill":
			return <div className="colorline"></div>;
		default:
			return null;
	}
};

export const SingleSelectInputV5 = (props: IInputPropsV5<ISingleSelectFieldSettingsDefinition>) => {
	const {value, onChange, disabled, inline, focused} = props;
	const list = [...props.dataTypeSettings.choiceList];
	const type = props.dataTypeSettings?.type;

	const selectedOption = list.find((item) => item.value === value);
	const selectedIsRemoved = value && !selectedOption;

	if (selectedIsRemoved) {
		list.push({value: value, color: ""});
	}

	let focus = focused;

	const onFocus = (value: boolean) => {
		if (!value) {
			focus = false;
		}
	};

	const onClick = () => {
		props.onClick?.();
	};

	const renderPill = (option: {color: string; value: string}) => {
		return (
			<SingleSelectPillStyled
				$color={option.color ? `#${option.color}` : ""}
				$type={type}
			>
				{renderIconComponent(type)}
				{option.value}
			</SingleSelectPillStyled>
		);
	};

	const renderSelectedOption = (selected: string | null) => {
		const optionsClone = list.slice();

		return (
			<>
				{optionsClone.map((option) => {
					if (typeof option === "object" && option !== null) {
						const result = renderColorValue(option, selected);

						if (result && result.value === selected) {
							return (
								<SingleSelectPillStyled
									key={`${option.value}_${option.color}_${option.id}`}
									$color={result.color ? `#${result.color}` : ""}
									$type={type}
									$inline={inline}
									$selected={true}
								>
									{renderIconComponent(type)}
									{selected}
								</SingleSelectPillStyled>
							);
						}
					}
					return null;
				})}
				{!selected && (
					<SingleSelectPillStyled
						className="nullLabel"
						$type={type}
						$inline={inline}
					>
						{"-"}
					</SingleSelectPillStyled>
				)}
			</>
		);
	};

	const renderColorValue = (option: {color: string; value: string}, selected: string) => {
		if (option.value === selected) {
			return {color: option.color, value: option.value};
		}
	};

	const fetchValueFromOption = (option: {color: string; value: string} | null) => {
		if (option === null) {
			onChange?.(null);
		} else {
			onChange?.(option.value);
		}
	};

	const renderOptionValue = (option: {color: string; value: string}) => {
		return option.value;
	};

	return (
		<SelectInputV5
			options={list}
			selected={value}
			type={type}
			disabled={disabled}
			nullOption={true}
			fullWidth={true}
			sort={false}
			focused={focus}
			onClick={onClick}
			onFocus={onFocus}
			horizontalAlignment={inline ? HorizontalAlignment.center : undefined}
			isSameWidth={false}
			inline={inline}
			dropdownIcon={false}
			isSingleSelect={true}
			render={renderPill}
			onChange={fetchValueFromOption}
			dropdownMaxHeight={"230px"}
			renderOptionValue={renderOptionValue}
			renderSelectedWhenClosed={renderSelectedOption}
			nullLabel="-"
			closeIcon={true}
			stringifyOption={renderOptionValue}
			selectedIsRemoved={selectedIsRemoved}
			dropdownFixedWidth="272px"
		/>
	);
};
