import * as React from "react";
import {inject, observer} from "mobx-react";
import {SpaceThumbnail} from "../modules/abstract/sidepanel/tabs/details/default/spacethumbnail/SpaceThumbnail";
import {ReactUtils} from "../utils/ReactUtils";
import type {Space} from "../../data/models/Space";
import type {Catalog} from "../../data/models/Catalog";
import type {Xyicon} from "../../data/models/Xyicon";
import {XyiconFeature, Permission, MarkupType} from "../../generated/api/base";
import type {IModel} from "../../data/models/Model";
import {ColorUtils} from "../../utils/ColorUtils";
import {Functions} from "../../utils/function/Functions";
import {BoundaryUtils} from "../../data/models/BoundaryUtils";
import type {Boundary} from "../../data/models/Boundary";
import type {AppState} from "../../data/state/AppState";
import type {LibraryModel} from "../../data/models/LibraryModel";
import {DomPortal} from "../modules/abstract/portal/DomPortal";
import type {BoundarySpaceMap} from "../../data/models/BoundarySpaceMap";
import type {BoundarySpaceMap3D} from "../modules/space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import type {Markup} from "../../data/models/Markup";
import {triggerPhoto360Previewer} from "../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupPhoto360Utils";
import {zIndex} from "../5.0/styles/styles";
import {SVGIcon} from "./button/SVGIcon";
import {BoundarySpaceMapSelector} from "./BoundarySpaceMapSelector";

interface IInitialsProps {
	readonly color?: string;
	readonly name: string;
	readonly item?: IModel;
	readonly className?: string;
	readonly thumbnailSize?: number;
	readonly appState?: AppState;
	readonly onCatalogIconEditClick?: (catalog: Catalog) => void;
	readonly size?: number;
	readonly onSpaceIconClick?: (spaceId: string) => void;
	readonly headerMinimized?: boolean;
}

interface IInitialsState {
	isBoundarySelectorOpen: boolean;
}

@inject("appState")
@observer
export class Initials extends React.Component<IInitialsProps, IInitialsState> {
	public static readonly defaultProps: Partial<IInitialsProps> = {
		thumbnailSize: 128,
	};

	constructor(props: IInitialsProps) {
		super(props);
		this.state = {
			isBoundarySelectorOpen: false,
		};
	}

	// If character in [a-z] or in [0-9]
	private okChar(c: string) {
		c = c || "";
		const char = c.toLowerCase().charCodeAt(0);

		return (96 < char && char < 123) || (47 < char && char < 58);
	}

	// Make Initials
	private makeInitialsFromString(s: string) {
		s = s || "";
		// First delete quotes, split on " " characters and then filter out special characters.
		let string = s.replace(/"|'/gi, "");
		const sArr = string.split(" ").filter((word) => this.okChar(word[0]));

		if (sArr.length === 0) {
			return "Xy";
		} else {
			if (sArr.length === 1) {
				if (sArr[0].length === 1) {
					return sArr[0][0].toLocaleUpperCase();
				} else {
					return sArr[0][0].toLocaleUpperCase() + sArr[0][1];
				}
			} else {
				return sArr[0][0].toLocaleUpperCase() + sArr[1][0].toLocaleUpperCase();
			}
		}
	}

	private getSpaceThumbnail(item: Space) {
		const fullURL = item.thumbnailFileURL;

		return (
			<SpaceThumbnail
				url={fullURL}
				spaceId={item.id}
				onClick={this.props.onSpaceIconClick}
			/>
		);
	}

	private onShowBoundarySpaceMaps = () => {
		this.setState({
			isBoundarySelectorOpen: true,
		});
	};

	private onHideBoundarySpaceMaps = () => {
		this.setState({
			isBoundarySelectorOpen: false,
		});
	};

	private renderInitial() {
		const {name, item, size, thumbnailSize, headerMinimized} = this.props;
		const thumbSize = size ? size : thumbnailSize;

		switch (item?.ownFeature) {
			case XyiconFeature.Space:
				return this.getSpaceThumbnail(item as Space);
			case XyiconFeature.Xyicon:
				const xyiconItem = item as Xyicon;

				return (
					<div
						style={{
							width: thumbSize,
							height: thumbSize,
							backgroundImage: `url('${xyiconItem.thumbnail}')`,
							transform: xyiconItem.backgroundTransform,
						}}
					/>
				);
			case XyiconFeature.XyiconCatalog:
				const catalogItem = item as Catalog;
				let thumbnail = catalogItem.thumbnail;

				if (catalogItem.source === "Revit" && catalogItem?.iconData?.modelParameters?.libraryModelID) {
					const model = this.props.appState.actions
						.getList(XyiconFeature.LibraryModel)
						.find((a: LibraryModel) => a.id === catalogItem.iconData.modelParameters.libraryModelID);

					thumbnail = model?.thumbnail;
				}

				return (
					<div className={ReactUtils.cls({"catalog-thumbnail": catalogItem, favoriteCatalog: catalogItem.isFavorite})}>
						<img
							src={thumbnail}
							width={thumbSize}
							height={thumbSize}
							title="Edit Icon"
							onClick={() => this.props.onCatalogIconEditClick?.(catalogItem)}
							className={ReactUtils.cls("button", {disabled: this.catalogPermission < Permission.Update})}
						/>
						{catalogItem.isFavorite && (
							<SVGIcon
								icon="dogear-favorite"
								classNames="dogear favoriteIcon"
							/>
						)}
					</div>
				);

			case XyiconFeature.Boundary:
				const boundaryItem = item as Boundary;
				const doesHaveMultipleSpaceMaps = (item as BoundarySpaceMap).parent ? false : BoundaryUtils.doesHaveMultipleSpaceMaps(item);
				const {selectedItems} = this.props.appState.app.spaceViewRenderer.spaceItemController;

				// If 2 (or more) boundaryspacemaps are selected with the same parent boundary, we consider them as one item
				const selectedItemIds: Set<string> = new Set<string>();

				for (const item of selectedItems) {
					selectedItemIds.add(((item.modelData as BoundarySpaceMap).isBoundarySpaceMap ? (item.modelData as BoundarySpaceMap).parent : item).id);
				}

				const areMultipleItemsSelected = selectedItemIds.size > 1;
				const isButtonEnabled = doesHaveMultipleSpaceMaps && !areMultipleItemsSelected;
				let boundaryThumbnail = boundaryItem.thumbnail;

				if (selectedItems.length === 1) {
					boundaryThumbnail = ((selectedItems[0] as BoundarySpaceMap3D)?.modelData as BoundarySpaceMap)?.thumbnail ?? boundaryThumbnail;
				}

				return (
					<div
						className={ReactUtils.cls("boundaryThumbnail", {btn: isButtonEnabled})}
						onClick={isButtonEnabled ? this.onShowBoundarySpaceMaps : Functions.emptyFunction}
					>
						<img
							src={boundaryItem.thumbnail}
							width={thumbSize}
							height={thumbSize}
						/>
						{doesHaveMultipleSpaceMaps && (
							<SVGIcon
								icon="dogear-cards"
								classNames="mergedIndicator"
							/>
						)}
						{this.state.isBoundarySelectorOpen && (
							<DomPortal destination={this.props.appState.app.modalContainer}>
								<BoundarySpaceMapSelector
									item={boundaryItem}
									className="detailsPanelBoundarySpaceMapSelector"
									onClose={this.onHideBoundarySpaceMaps}
									headerMinimized={headerMinimized}
									zIndex={(document.querySelector(".shadowDiv") ? zIndex.anchoredHeader : zIndex.shadowDiv) + 1}
								/>
							</DomPortal>
						)}
					</div>
				);
			case XyiconFeature.Markup: {
				const isMarkup360Photo = (item as Markup).type === MarkupType.Photo360;
				return (
					<div
						onClick={isMarkup360Photo ? () => triggerPhoto360Previewer(item as Markup) : Functions.emptyFunction}
						className={ReactUtils.cls("thumbnail", {btn: isMarkup360Photo})}
						style={{
							width: thumbSize,
							height: thumbSize,
							backgroundImage: `url('${item.thumbnail}')`,
						}}
					/>
				);
			}
			default:
				return name ? this.makeInitialsFromString(name) : "Xy";
		}
	}

	private get catalogPermission() {
		return this.props.appState.user?.getOrganizationPermission(XyiconFeature.XyiconCatalog);
	}

	public override async componentDidMount() {
		await this.props.appState.app.transport.services.feature.refreshList(XyiconFeature.LibraryModel);
	}

	public override render() {
		const {item, name, className} = this.props;
		let color = this.props.color || ColorUtils.hexColorByString(name || "Xyicon");

		const darkerColor = `#${ColorUtils.darkenColor(color, 30)}`;

		const style = {
			backgroundColor: `#${color}`,
			borderColor: darkerColor,
			color: darkerColor,
		};

		const isItemWithThumbnail = [
			XyiconFeature.Xyicon,
			XyiconFeature.XyiconCatalog,
			XyiconFeature.Space,
			XyiconFeature.Boundary,
			XyiconFeature.Markup,
		].includes(item?.ownFeature);

		return (
			<div
				className={ReactUtils.cls(`Initials ${className || ""}`, {thumbnail: isItemWithThumbnail})}
				style={!isItemWithThumbnail ? style : {}}
			>
				{this.renderInitial()}
			</div>
		);
	}
}
