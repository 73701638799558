import styled from "styled-components";
import {useState} from "react";
import {useAppStore} from "../../../../../StateManager";
import {DomPortal} from "../../../../modules/abstract/portal/DomPortal";
import {colorPalette} from "../../../styles/colorPalette";
import {baseDistance, fontSize, fontWeight, radius, zIndex} from "../../../styles/styles";
import {PopupBackdropV5} from "../../../popup/PopupBackdropV5";
import CloseIcon from "../../../icons/xmark-large.svg?react";
import CirclePlusIcon from "../../../icons/circle-plus.svg?react";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {Functions} from "../../../../../utils/function/Functions";
import {ViewPreferenceCategory, XyiconFeature} from "../../../../../generated/api/base";
import {TextInputV5} from "../../../details/datatypes/TextInputV5";
import {CheckboxInputStyled, CheckboxInputV5} from "../../../details/datatypes/CheckboxInputV5";
import {ButtonV5} from "../../../button/ButtonV5";
import {SelectInputStyled, SelectInputV5} from "../../../input/select/SelectInputV5";
import {InfoButtonStyled} from "../../../button/InfoButtonV5";
import {View} from "../../../../../data/models/View";
import type {IViewFolder} from "../../../../../data/models/ViewUtils";
import {
	getFoldersFromViewFolderStructure,
	getItemByIdInViewFolderStructure,
	removeElementFromViewFolderStructureById,
	rootFolderId,
} from "../../../../../data/models/ViewUtils";
import type {WorkspaceViewType} from "../../../navigation/views/WorkspaceViewCommon";
import {ObjectUtils} from "../../../../../utils/data/ObjectUtils";
import type {ViewActionType} from "../../../sharing/GlobalSharingPopup";
import {GlobalSharingPopup} from "../../../sharing/GlobalSharingPopup";
import {onWorkspaceViewClick} from "../../../topbar/ViewTabsCommon";
import {ViewTemplateCard} from "./ViewTemplateCard";
import {getTypeFromFeatureForCard} from "./CardUtils";

const getPlaceholderForFeature = (feature: XyiconFeature): string => {
	switch (feature) {
		case XyiconFeature.Xyicon:
			return "Xyicon List View Name";
		case XyiconFeature.Boundary:
			return "Boundary List View Name";
		case XyiconFeature.SpaceEditor:
			return "Space View Name";
	}
};

const arrayOfTemplates: XyiconFeature[] = [XyiconFeature.Xyicon, XyiconFeature.Boundary, XyiconFeature.SpaceEditor];

const favoriteRootFolder: IViewFolder = {
	name: "Favorites",
	id: rootFolderId,
	isOpen: true,
	category: ViewPreferenceCategory.Folder,
	children: [],
};

interface ICreateViewPopupProps {
	readonly type: WorkspaceViewType;
	readonly viewFolderIdToSaveNewViewInto: string;
	readonly onClose: () => void;
	readonly defaultTemplateFeature?: XyiconFeature;
	readonly onCreateCallback?: (view: View) => void;
}

export const CreateViewPopup = (props: ICreateViewPopupProps) => {
	const {onClose, onCreateCallback, type, viewFolderIdToSaveNewViewInto, defaultTemplateFeature = XyiconFeature.Xyicon} = props;
	const isGlobal = type === "Global";
	const appState = useAppStore((state) => state.appState);
	const [selectedTemplateFeature, setSelectedTemplateFeature] = useState<XyiconFeature>(defaultTemplateFeature);
	const [newViewName, setNewViewName] = useState<string>("");
	const [addToFavorites, setAddToFavorites] = useState<boolean>(type === "Favorites");
	const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);
	const [isGlobalActionPanelOpen, setIsGlobalActionPanelOpen] = useState<boolean>(false);
	const [globalActionType, setGlobalActionType] = useState<ViewActionType>("modify");
	const setViewForOpenSpaceSelector = useAppStore((state) => state.setViewForOpenSpaceSelector);

	const favoriteViews = appState.user?.favoriteViews || [];

	favoriteRootFolder.children = favoriteViews;
	const folders: IViewFolder[] = [favoriteRootFolder, ...getFoldersFromViewFolderStructure(favoriteViews)];

	const [selectedFavoriteFolder, setSelectedFavoriteFolder] = useState<IViewFolder>(
		(viewFolderIdToSaveNewViewInto ? (getItemByIdInViewFolderStructure(favoriteViews, viewFolderIdToSaveNewViewInto) as IViewFolder) : folders[0]) ??
			folders[0],
	);

	const createView = async () => {
		setIsWaitingForResponse(true);
		const newDefaultView = View.createNew(selectedTemplateFeature, newViewName, appState);
		const newDefaultViewData = newDefaultView.getData();
		const {result: viewData, error} = await appState.app.transport.services.view.create(
			{
				feature: selectedTemplateFeature,
				name: newViewName,
				columns: newDefaultViewData.columns,
				isGlobal,
			},
			selectedTemplateFeature,
		);

		if (viewData) {
			const newView = appState.actions.getViewById(viewData.viewID);

			if (newView) {
				appState.actions.selectViewById(newView.id);

				if (addToFavorites) {
					await newView.setFavorite(true, selectedFavoriteFolder.id);
				}

				const {organization} = appState;

				if (isGlobal && organization && viewFolderIdToSaveNewViewInto) {
					const globalViews = ObjectUtils.deepClone(organization.globalViews);
					removeElementFromViewFolderStructureById(globalViews, newView.id);
					const viewFolderToSaveNewViewInto = getItemByIdInViewFolderStructure(globalViews, viewFolderIdToSaveNewViewInto) as IViewFolder;

					viewFolderToSaveNewViewInto.children.push({
						id: newView.id,
						category: ViewPreferenceCategory.View,
					});

					await appState.organization.setGlobalViews(globalViews);
				}

				onCreateCallback?.(newView);
				onClose();
				onWorkspaceViewClick(newView, false);
			}
			setIsWaitingForResponse(false);
		}
	};

	const onCreateClick = () => {
		setIsWaitingForResponse(true);

		if (props.type === "Global") {
			setIsGlobalActionPanelOpen(true);
			setGlobalActionType("create");
		} else {
			createView();
		}
	};

	const onCreateCancelClick = () => {
		setIsGlobalActionPanelOpen(false);
		setIsWaitingForResponse(false);
	};

	const getErrorMessage = () => {
		return !isNameValid ? (newViewName === "" ? "Name cannot be empty!" : "Name needs to be unique!") : "";
	};

	const isNameValid = appState.actions.isNameValidForView(newViewName, selectedTemplateFeature);

	return (
		<>
			{isGlobalActionPanelOpen && (
				<GlobalSharingPopup
					onClose={onCreateCancelClick}
					viewActionType={globalActionType}
					isReports={false}
					onSaveClicked={createView}
				/>
			)}
			<DomPortal destination={appState.app.modalContainer}>
				<PopupBackdropV5 onClick={Functions.stopPropagation}>
					<CreateViewPopupStyled>
						<HeaderStyled>
							<TitleStyled>Create a New {isGlobal ? "global " : ""}View</TitleStyled>
							<IconButtonV5
								IconComponent={CloseIcon}
								onClick={onClose}
							/>
						</HeaderStyled>
						<MainStyled>
							<TemplateContainerStyled>
								<H3Styled>Templates</H3Styled>
								<GridStyled>
									{arrayOfTemplates.map((f) => (
										<ViewTemplateCard
											key={f}
											feature={f}
											isActive={selectedTemplateFeature === f}
											onClick={() => setSelectedTemplateFeature(f)}
										/>
									))}
								</GridStyled>
							</TemplateContainerStyled>
							<SelectedTemplateCardContainerStyled>
								<H3Styled>{getTypeFromFeatureForCard(selectedTemplateFeature)}</H3Styled>
								<ViewNameContainerStyled>
									<NameLabelStyled>Name</NameLabelStyled>
									<TextInputV5
										placeholder={getPlaceholderForFeature(selectedTemplateFeature)}
										value={newViewName}
										onInput={setNewViewName}
										getErrorMessage={getErrorMessage}
									/>
								</ViewNameContainerStyled>
								<LowerPartStyled>
									<MidContainerStyled>
										<ViewTemplateCard
											isInFocus={true}
											feature={selectedTemplateFeature}
											onClick={Functions.emptyFunction}
											isActive={false}
										/>
										<AddToFavoritesContainerStyled>
											<CheckboxInputV5
												label="Add to Favorites"
												value={addToFavorites}
												onChange={setAddToFavorites}
											/>
										</AddToFavoritesContainerStyled>
										{addToFavorites && (
											<SelectContainerStyled>
												<SelectInputV5
													selected={selectedFavoriteFolder}
													onChange={setSelectedFavoriteFolder}
													options={folders}
													optionsZIndex={zIndex.popup + 1}
													isSameWidth={true}
													render={(viewFolder: IViewFolder) => viewFolder.name}
												/>
											</SelectContainerStyled>
										)}
									</MidContainerStyled>
									<BottomPartStyled>
										<ButtonV5
											label={isWaitingForResponse ? "CREATING..." : "CREATE"}
											onClick={onCreateClick}
											disabled={!isNameValid || isWaitingForResponse}
										>
											<CirclePlusIcon />
										</ButtonV5>
									</BottomPartStyled>
								</LowerPartStyled>
							</SelectedTemplateCardContainerStyled>
						</MainStyled>
					</CreateViewPopupStyled>
				</PopupBackdropV5>
			</DomPortal>
		</>
	);
};

const SelectContainerStyled = styled.div`
	display: flex;
	justify-content: center;

	${SelectInputStyled} {
		min-width: 180px;
		margin-left: 120px;
	}
`;

const LowerPartStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
`;

const BottomPartStyled = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const MidContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.md};
`;

const AddToFavoritesContainerStyled = styled.div`
	display: flex;

	${CheckboxInputStyled} {
		display: flex;
		gap: 48px;
		color: ${colorPalette.gray.c950};
	}
`;

const NameLabelStyled = styled.div`
	font-size: ${fontSize.lg};
	line-height: 24px;
	color: ${colorPalette.gray.c950};
	font-weight: ${fontWeight.normal};
`;

const ViewNameContainerStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	gap: ${baseDistance.md};

	${InfoButtonStyled} {
		position: absolute;
		left: initial;
		right: 0;
		top: 7px;
	}
`;

const SelectedTemplateCardContainerStyled = styled.div`
	flex: 1;
	padding: ${baseDistance.md} ${baseDistance.lg};
	display: flex;
	flex-direction: column;
	max-width: 456px;
	gap: ${baseDistance.md};
`;

const GridStyled = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: ${baseDistance.md};
`;

const H3Styled = styled.h3`
	font-size: ${fontSize.xl};
	line-height: 24px;
	font-weight: ${fontWeight.bold};
	text-transform: capitalize;
`;

const TemplateContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${baseDistance.md};
	border-right: 1px solid ${colorPalette.gray.c200Light};
	padding: ${baseDistance.md} ${baseDistance.lg};
	width: 624px;
`;

const MainStyled = styled.div`
	display: flex;
`;

const TitleStyled = styled.div`
	font-size: 24px;
	line-height: 32px;
	font-weight: ${fontWeight.bold};
	color: ${colorPalette.gray.c950};
`;

const HeaderStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 96px;
	padding: ${baseDistance.lg};
	border-bottom: 1px solid ${colorPalette.gray.c200Light};
`;

const CreateViewPopupStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 1080px;
	background-color: ${colorPalette.white};
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
	border-radius: ${radius.xxl};
	padding-bottom: ${baseDistance.md};
`;
