import * as React from "react";
import {inject, observer} from "mobx-react";
import type {AppState} from "../../../../../../../data/state/AppState";
import {FocusLoss} from "../../../../../../../utils/ui/focus/FocusLoss";
import {ReactUtils} from "../../../../../../utils/ReactUtils";
import type {Xyicon} from "../../../../../../../data/models/Xyicon";
import {SVGIcon} from "../../../../../../widgets/button/SVGIcon";
import {Catalog} from "../../../../../../../data/models/Catalog";
import {XyiconFeature} from "../../../../../../../generated/api/base";
import {TextInput} from "../../../../../../widgets/input/text/TextInput";
import {StringUtils} from "../../../../../../../utils/data/string/StringUtils";
import {SearchField} from "../../../../../../widgets/input/search/SearchField";
import {DomPortal} from "../../../../portal/DomPortal";
import {InfoBubble} from "../../../../common/infobutton/InfoBubble";
import type {TransformObj} from "../../../../../../../utils/dom/DomUtils";
import {HorizontalAlignment, VerticalAlignment, DomUtils} from "../../../../../../../utils/dom/DomUtils";
import {AppUtils} from "../../../../../../../utils/AppUtils";
import {KeyboardListener} from "../../../../../../../utils/interaction/key/KeyboardListener";
import {ConfirmXyiconModelChangesForm} from "./ConfirmXyiconModelChangesForm";

interface IXyiconModelFieldProps {
	readonly xyicons: Xyicon[];
	readonly item?: Xyicon;
	readonly appState?: AppState;
	readonly disabled: boolean;
	readonly inline?: boolean;
	readonly optionsZIndex?: number;
}

interface IXyiconModelFieldState {
	open: boolean;
	search: string;
	updating: boolean;
	xyiconsToBeChanged: Xyicon[];
	catalogIdToChangeTo: string;
	toolTipTransform: TransformObj;
	selectorTransform: TransformObj;
	isToolTipOpen: boolean;
}

@inject("appState")
@observer
export class XyiconModelField extends React.Component<IXyiconModelFieldProps, IXyiconModelFieldState> {
	private _container = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _selector = React.createRef<HTMLDivElement>();
	private _timeoutId: number;

	constructor(props: IXyiconModelFieldProps) {
		super(props);
		this.state = {
			open: false,
			search: "",
			updating: false,
			xyiconsToBeChanged: [],
			catalogIdToChangeTo: "",
			toolTipTransform: null,
			selectorTransform: null,
			isToolTipOpen: false,
		};
	}

	private onEditClick = () => {
		AppUtils.disableScrolling(true);
		if (!this.props.disabled) {
			this.setState({
				open: !this.state.open,
			});
		}
	};

	private onKeyPressed = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				AppUtils.disableScrolling(false);
				if (this.state.open) {
					this.setState({open: false, search: ""});
				}
				break;
		}
	};

	private onSelectCatalog = (catalogId: string) => {
		const {xyicons, inline, item} = this.props;

		// in case of SpaceEditor table xyicon model change (xyicons is empty) or if some items are selected and other element's model is changed
		const xyiconsToCheck = inline && (xyicons.length === 0 || !xyicons.includes(item)) ? [item] : xyicons;
		const xyiconsToBeChanged = xyiconsToCheck.filter((xyicon) => xyicon.catalogId !== catalogId);

		this.setState({
			xyiconsToBeChanged: xyiconsToBeChanged,
			catalogIdToChangeTo: catalogId,
		});
	};

	private areAllModelsMatching() {
		const {xyicons} = this.props;
		const firstCatalogId = xyicons[0]?.catalogId;

		for (let i = 1; i < xyicons.length; ++i) {
			if (xyicons[i].catalogId !== firstCatalogId) {
				return false;
			}
		}

		return true;
	}

	private onFocusLoss = (e: MouseEvent) => {
		AppUtils.disableScrolling(false);

		let eventTargetInModalContainer = false;

		if (e.target instanceof Element) {
			eventTargetInModalContainer = this.props.appState.app.modalContainer.contains(e.target);

			if (!eventTargetInModalContainer && (this.state.open || this.state.search)) {
				this.setState({
					open: false,
					search: "",
				});
			}
		}

		return false;
	};

	private onSearchInput = (value: string) => {
		this.setState({
			search: value,
		});
	};

	private onCloseConfirmForm = () => {
		this.setState({
			xyiconsToBeChanged: [],
			catalogIdToChangeTo: "",
			open: false,
		});
	};

	private onMouseOver = (e: React.MouseEvent) => {
		const input = e.currentTarget.querySelector("input") as HTMLInputElement;

		if (input.scrollWidth > input.clientWidth) {
			if (this._timeoutId) {
				clearTimeout(this._timeoutId);
			}

			this._timeoutId = window.setTimeout(() => {
				this.setState({isToolTipOpen: true});
			}, 500);
		}
	};

	private onMouseLeave = () => {
		clearTimeout(this._timeoutId);
		this.setState({isToolTipOpen: false});
	};

	public override componentDidMount() {
		FocusLoss.listen(this._container.current, this.onFocusLoss);
		KeyboardListener.getInstance().signals.up.add(this.onKeyPressed);
	}

	public override componentWillUnmount() {
		FocusLoss.stopListen(this._container.current, this.onFocusLoss);
		KeyboardListener.getInstance().signals.up.remove(this.onKeyPressed);
	}

	public override componentDidUpdate(prevProps: Readonly<IXyiconModelFieldProps>, prevState: Readonly<IXyiconModelFieldState>): void {
		if (!prevState.open && this.state.open && this._container.current && this._selector.current) {
			this.setState({
				selectorTransform: DomUtils.getFixedFloatingElementPosition(
					this._container.current,
					this._selector.current,
					VerticalAlignment.bottomOuter,
					HorizontalAlignment.center,
					3,
					0,
				),
			});
		}

		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._container.current && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._container.current,
					this._floating.current,
					VerticalAlignment.top,
					HorizontalAlignment.center,
				),
			});
		}
	}

	private onFieldTexInputClick = (e: React.MouseEvent) => {
		e.preventDefault();
	};

	public override render() {
		const {xyicons, appState, disabled, inline, item, optionsZIndex = 9000} = this.props;
		const {open, search, updating, isToolTipOpen, toolTipTransform, selectorTransform} = this.state;

		const selectorStyle: React.CSSProperties = this._selector && {
			transform: selectorTransform?.translate,
			position: "fixed",
			zIndex: optionsZIndex,
			minWidth: 300,
			width: this._container?.current?.getBoundingClientRect().width,
			maxWidth: 500,
		};

		const inlineStyle: React.CSSProperties = this._floating && {
			transform: toolTipTransform?.translate,
			top: 0,
			left: 0,
			position: "absolute",
		};

		let catalogs = appState.actions.getList(XyiconFeature.XyiconCatalog) as Catalog[];

		const allModelsAreMatching = this.areAllModelsMatching();
		let matchingCatalog: Catalog;

		if (inline || allModelsAreMatching) {
			const firstXyicon = inline ? item : xyicons[0];

			catalogs = catalogs.filter((catalog) => {
				return catalog.id !== firstXyicon.catalogId && Catalog.search(catalog, search);
			});

			matchingCatalog = appState.actions.findInheritedObject(firstXyicon, firstXyicon.ownFeature) as Catalog;
		}

		return (
			<div
				ref={this._container}
				className={ReactUtils.cls("XyiconModelField", {open, inline})}
				onMouseOver={this.onMouseOver}
				onMouseLeave={this.onMouseLeave}
			>
				<div
					className={ReactUtils.cls("modelField", {disabled})}
					onClick={this.onEditClick}
				>
					<TextInput
						onClick={this.onFieldTexInputClick}
						value={matchingCatalog ? matchingCatalog.model : "Multiple values"}
						className={ReactUtils.cls({cellContent: inline})}
					/>
					{updating ? <span className="spinner" /> : !inline && <SVGIcon icon="edit" />}
				</div>
				{this.state.xyiconsToBeChanged.length > 0 && this.state.catalogIdToChangeTo && (
					<ConfirmXyiconModelChangesForm
						onClose={this.onCloseConfirmForm}
						xyiconsToBeChanged={this.state.xyiconsToBeChanged}
						catalogIdToChangeTo={this.state.catalogIdToChangeTo}
					/>
				)}
				{open && (
					<DomPortal destination={this.props.appState.app.modalContainer}>
						<div
							className={ReactUtils.cls("XyiconModelField__selector", {inline})}
							style={selectorStyle}
							ref={this._selector}
						>
							<SearchField
								onInput={this.onSearchInput}
								value={search}
							/>
							<div className="list">
								{catalogs.length ? (
									catalogs
										.toSorted((a: Catalog, b: Catalog) => StringUtils.sortIgnoreCase(a.model, b.model))
										.filter((c: Catalog) => StringUtils.containsIgnoreCase(c.model, search))
										.map((catalog) => (
											<div
												key={catalog.id}
												className="listItem"
												onClick={(e) => this.onSelectCatalog(catalog.id)}
											>
												<img
													src={catalog.thumbnail}
													alt={`${catalog.id} thumbnail`}
												/>
												<div className="fields">
													{search.length !== 0 ? (
														<div
															className="model"
															dangerouslySetInnerHTML={{__html: StringUtils.regexHighlight(catalog.model, search)}}
														/>
													) : (
														<div className="model">{catalog.model}</div>
													)}
													<div className="type">{appState.actions.getTypeName(catalog.typeId)}</div>
												</div>
											</div>
										))
								) : (
									<>No results.</>
								)}
							</div>
						</div>
					</DomPortal>
				)}
				{isToolTipOpen && matchingCatalog && !inline && (
					<DomPortal destination={this.props.appState.app.modalContainer}>
						<InfoBubble
							divRef={this._floating}
							content={matchingCatalog.model}
							style={inlineStyle}
							className="DeleteButtonToolTip"
						/>
					</DomPortal>
				)}
			</div>
		);
	}
}
