/* eslint-disable react-hooks/rules-of-hooks */

import {Observer} from "mobx-react";
import type {CSSProperties} from "styled-components";
import styled from "styled-components";
import type {MutableRefObject} from "react";
import {useEffect, useRef, useState} from "react";
import {Matrix4, PerspectiveCamera, Vector3} from "three";
import {Tween} from "@tweenjs/tween.js";
import type {PhotoSphere} from "../../../photosphere/PhotoSphere";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {IconButtonStyled, IconButtonV5} from "../interaction/IconButtonV5";
import CloseIcon from "../../5.0/icons/xmark-large.svg?react";
import {ShadowDivStyled} from "../details/ShadowDiv";
import {ButtonStyled} from "../button/ButtonV5";
import {useAppStore} from "../../../StateManager";
import type {Markup} from "../../../data/models/Markup";
import type {SpaceViewRenderer} from "../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {THREEUtils, type IVec3} from "../../../utils/THREEUtils";
import {HTMLUtils} from "../../../utils/HTML/HTMLUtils";
import {SliderV5} from "../colors/SliderV5";
import {Functions} from "../../../utils/function/Functions";
import {SelectInputStyled, SelectInputV5} from "../input/select/SelectInputV5";
import type {PhotoSphereViewMode} from "../../../photosphere/PhotoSphereTypes";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {TimeUtils} from "../../../utils/TimeUtils";
import {DefaultHeightOfMarkupPhotosInMeters} from "../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupPhoto360Utils";
import {MathUtils} from "../../../utils/math/MathUtils";
import {Constants} from "../../modules/space/spaceeditor/logic3d/Constants";
import {ToggleSwitchFieldStyled, ToggleSwitchFieldV5} from "../details/ToggleSwitchFieldV5";
import type {SpaceItem} from "../../modules/space/spaceeditor/logic3d/elements3d/SpaceItem";
import {MarkupType} from "../../../generated/api/base";
import {FullscreenLoader} from "../../../FullscreenLoader";
import {zIndex} from "../styles/styles";

const dummyCamera = new PerspectiveCamera();

const moveSpaceEditorCameraToMarkup = (markupPhoto360: Markup, isCameraMovingFromOneMarkupToAnother: MutableRefObject<boolean>) => {
	return new Promise<void>((resolve, reject) => {
		const spaceViewRenderer = markupPhoto360.appState.app.spaceViewRenderer;
		const {cameraPosition} = markupPhoto360.appState.app.graphicalTools.photoSphereSceneManager.cameraControls;

		const activeCamera = spaceViewRenderer.activeCamera;

		cameraPosition.x.reset(activeCamera.position.x);
		cameraPosition.y.reset(activeCamera.position.y);
		cameraPosition.z.reset(activeCamera.position.z);

		const cameraEndPos: IVec3 = {
			...markupPhoto360.position,
			z: markupPhoto360.heightOffsetFromFloorplan * spaceViewRenderer.space.spaceUnitsPerMeter,
		};

		isCameraMovingFromOneMarkupToAnother.current = true;

		cameraPosition.x.setEnd(cameraEndPos.x);
		cameraPosition.y.setEnd(cameraEndPos.y);
		cameraPosition.z.setEnd(cameraEndPos.z);

		const ms = Math.max(cameraPosition.x.animationDuration, cameraPosition.y.animationDuration, cameraPosition.z.animationDuration);

		setTimeout(() => {
			const deepestZoomLevel = spaceViewRenderer.tileManager.zoomInfo.length - 1;
			spaceViewRenderer.tileManager.updateTileVisibility(deepestZoomLevel);
			spaceViewRenderer.tileManager.update(activeCamera.position, deepestZoomLevel, true);
			isCameraMovingFromOneMarkupToAnother.current = false;
			spaceViewRenderer.transport.appState.app.graphicalTools.photoSphereSceneManager.needsRender = true;
			resolve();
		}, ms);
	});
};

const transformSpaceViewRendererCameraToMixedOrSplitMode = (
	spaceViewRenderer: SpaceViewRenderer,
	markupPhoto360: Markup,
	newViewMode: PhotoSphereViewMode,
	savedCameraType: MutableRefObject<"perspective" | "orthographic">,
	savedCameraMatrix: MutableRefObject<Matrix4>,
) => {
	return new Promise<void>((resolve, reject) => {
		if (spaceViewRenderer.isMounted) {
			const {photoSphereSceneManager} = spaceViewRenderer.transport.appState.app.graphicalTools;
			if (photoSphereSceneManager.viewMode === "normal") {
				photoSphereSceneManager.viewMode = "transitioning";
				const {activeCamera: prevActiveCamera} = spaceViewRenderer;
				savedCameraType.current = (prevActiveCamera as PerspectiveCamera).isPerspectiveCamera ? "perspective" : "orthographic";
				savedCameraMatrix.current = new Matrix4().copy(prevActiveCamera.matrix);
				spaceViewRenderer.toolManager.deactivateCameraControls();
				spaceViewRenderer.toolManager.activeTool.deactivate();
				spaceViewRenderer.changeCameraType("perspective");

				const {activeCamera} = spaceViewRenderer;

				const targetPos = markupPhoto360.position;
				const targetPosZ =
					spaceViewRenderer.spaceOffset.z + targetPos.z + markupPhoto360.heightOffsetFromFloorplan * spaceViewRenderer.space.spaceUnitsPerMeter;
				dummyCamera.position.setX(targetPos.x);
				dummyCamera.position.setY(targetPos.y);
				dummyCamera.position.setZ(targetPosZ);
				dummyCamera.lookAt(new Vector3(targetPos.x, targetPos.y + 1, targetPosZ));
				dummyCamera.updateMatrix();

				const startData = [...activeCamera.matrix.elements];
				const endData = [...dummyCamera.matrix.elements];

				const tween = new Tween<number[]>(startData).to(endData, Constants.DURATIONS.CAMERA_MOVEMENT);
				photoSphereSceneManager.tweenGroup.add(tween);

				tween.onUpdate((matrixElements: number[]) => {
					activeCamera.matrix.fromArray(matrixElements);
					activeCamera.position.setFromMatrixPosition(activeCamera.matrix);
					activeCamera.rotation.setFromRotationMatrix(activeCamera.matrix);
					spaceViewRenderer.needsRender = true;
				});

				tween.onComplete(() => {
					photoSphereSceneManager.viewMode = newViewMode;
					photoSphereSceneManager.tweenGroup.remove(tween);
					resolve();
				});

				window.setTimeout(() => {
					tween.start();
				}, 500);
			} else {
				photoSphereSceneManager.viewMode = newViewMode;
				resolve();
			}
		}
	});
};

const transformSpaceViewRendererCameraToNormalMode = (
	spaceViewRenderer: SpaceViewRenderer,
	savedCameraType: MutableRefObject<"perspective" | "orthographic">,
	savedCameraMatrix: MutableRefObject<Matrix4>,
) => {
	return new Promise<void>((resolve, reject) => {
		if (spaceViewRenderer.isMounted) {
			const {photoSphereSceneManager} = spaceViewRenderer.transport.appState.app.graphicalTools;
			if (["mixed", "split"].includes(photoSphereSceneManager.viewMode)) {
				photoSphereSceneManager.viewMode = "transitioning";
				const activeCamera = spaceViewRenderer.activeCamera;

				const startData = [...activeCamera.matrix.elements];
				const endData = [...savedCameraMatrix.current.elements];

				const tween = new Tween<number[]>(startData).to(endData, Constants.DURATIONS.CAMERA_MOVEMENT);

				spaceViewRenderer.tweenGroup.add(tween);

				tween.onUpdate((matrixElements: number[]) => {
					activeCamera.matrix.fromArray(matrixElements);
					activeCamera.position.setFromMatrixPosition(activeCamera.matrix);
					activeCamera.rotation.setFromRotationMatrix(activeCamera.matrix);
					spaceViewRenderer.needsRender = true;
				});

				tween.onComplete(() => {
					spaceViewRenderer.tweenGroup.remove(tween);
					spaceViewRenderer.toolManager.activateCameraControls();
					spaceViewRenderer.toolManager.activeTool.activate();
					spaceViewRenderer.changeCameraType(savedCameraType.current);
					photoSphereSceneManager.viewMode = "normal";
					resolve();
				});

				window.setTimeout(() => {
					tween.start();
				}, 500);
			} else {
				resolve();
			}
		}
	});
};

interface IPhotoSphereViewerProps {
	readonly activePhotoSphere: PhotoSphere;
}

export const PhotoSphereViewer = (props: IPhotoSphereViewerProps) => {
	const {activePhotoSphere} = props;
	const isCameraMovingFromOneMarkupToAnother = useRef<boolean>(false);
	const savedCameraMatrix = useRef<Matrix4>(new Matrix4());
	const savedCameraType = useRef<"orthographic" | "perspective">("orthographic");
	const markup = activePhotoSphere.itemModel;
	const appState = useAppStore((state) => state.appState);
	const [canvasOpacity, setCanvasOpacity] = useState<number>(0.4);
	const {photoSphereManager, photoSphereSceneManager} = appState.app.graphicalTools;
	const {spaceViewRenderer} = activePhotoSphere;
	const divRef = useRef<HTMLDivElement>();

	const persistMarkupChange = () => {
		const {markupManager} = spaceViewRenderer;
		const markup3DMaybe = markupManager.getItemById(markup.id);
		if (markup3DMaybe) {
			return markupManager.updateItems([markup3DMaybe]);
		}
	};

	useEffect(() => {
		return () => {
			photoSphereSceneManager.unmount();
		};
	}, [photoSphereSceneManager]);

	const onCloseClick = async () => {
		await transformSpaceViewRendererCameraToNormalMode(spaceViewRenderer, savedCameraType, savedCameraMatrix);
		if (spaceViewRenderer.resizeDetector) {
			spaceViewRenderer.resizeDetector.isActive = true;
		}
		await TimeUtils.waitForNextFrame();

		if (spaceViewRenderer.isMounted) {
			spaceViewRenderer.onResize();
		}

		// Ugly workaround to prevent stretched canvas in V5... call it again
		await TimeUtils.waitForNextFrame();

		if (spaceViewRenderer.isMounted) {
			spaceViewRenderer.onResize();
		}
		photoSphereManager.activePhotoSphereMaybe = null;
	};

	const onViewModeChange = async (viewMode: PhotoSphereViewMode) => {
		if (viewMode !== "normal") {
			if (divRef.current) {
				divRef.current.style.visibility = "hidden";
			}
			if (activePhotoSphere?.itemModel?.spaceId && activePhotoSphere.itemModel.spaceId !== spaceViewRenderer.space?.id) {
				await spaceViewRenderer.actions.navigateToSpaceItem(activePhotoSphere.itemModel, true);
			}
			if (spaceViewRenderer.isMounted) {
				divRef.current.style.visibility = "";
				await transformSpaceViewRendererCameraToMixedOrSplitMode(spaceViewRenderer, markup, viewMode, savedCameraType, savedCameraMatrix);
			}
		} else {
			transformSpaceViewRendererCameraToNormalMode(spaceViewRenderer, savedCameraType, savedCameraMatrix);
		}
	};

	return (
		<Observer>
			{() => {
				const {viewMode, cameraControls} = photoSphereSceneManager;
				const {activeCamera, space} = spaceViewRenderer;
				const onPhotoSphereLoadedSignal = photoSphereManager.signals.onPhotoSphereLoaded;

				const {heightOffsetFromFloorplan, floorplanRotationOffset} = markup;
				const setHeightOffset = (value: number) => {
					markup.setHeightOffsetFromFloorplan(value);
				};

				const setRotationOffset = (value: number) => {
					markup.setFloorplanRotationOffset(value);
				};

				useEffect(() => {
					const moveSpaceEditorCameraToPhotoSphere = (photoSphere: PhotoSphere) => {
						if (["mixed", "split"].includes(viewMode)) {
							moveSpaceEditorCameraToMarkup(photoSphere.itemModel, isCameraMovingFromOneMarkupToAnother);
						}
					};

					onPhotoSphereLoadedSignal.add(moveSpaceEditorCameraToPhotoSphere);

					return () => {
						onPhotoSphereLoadedSignal.remove(moveSpaceEditorCameraToPhotoSphere);
					};
				}, [onPhotoSphereLoadedSignal, viewMode]);

				useEffect(() => {
					if (space && cameraControls) {
						cameraControls.cameraPosition.z.reset(
							space.spaceUnitsPerMeter * heightOffsetFromFloorplan,
							space.spaceUnitsPerMeter * heightOffsetFromFloorplan,
						);
						spaceViewRenderer.needsRender = true;
						spaceViewRenderer.update();
					}
				}, [heightOffsetFromFloorplan, space, cameraControls]);

				if (photoSphereSceneManager.rotationOffset !== floorplanRotationOffset) {
					photoSphereSceneManager.rotationOffset = floorplanRotationOffset;
					photoSphereSceneManager.cameraControlsNeedsUpdate = true;
					photoSphereSceneManager.needsRender = true;
				}

				useEffect(() => {
					const onUpdateSpaceEditorCamera = () => {
						if (photoSphereSceneManager.needsRender && ["split", "mixed"].includes(viewMode)) {
							const {cameraControls} = photoSphereSceneManager;
							const {uValue, vValue, cameraPosition} = cameraControls;
							activeCamera.position.set(cameraPosition.x.value, cameraPosition.y.value, cameraPosition.z.value);
							const forwardAsNumArray = MathUtils.getSphereSurfacePointFromUV(uValue, vValue);
							const forward = new Vector3(forwardAsNumArray[0], forwardAsNumArray[1], forwardAsNumArray[2]);
							forward.applyAxisAngle(new Vector3(1, 0, 0), Math.PI / 2);
							forward.applyAxisAngle(new Vector3(0, 0, 1), Math.PI / 2);
							activeCamera.lookAt(activeCamera.position.x + forward.x, activeCamera.position.y + forward.y, activeCamera.position.z + forward.z);
							if (photoSphereSceneManager.userZoomFactor.value !== activeCamera.zoom) {
								activeCamera.zoom = photoSphereSceneManager.userZoomFactor.value;
								activeCamera.updateProjectionMatrix();
							}
							THREEUtils.updateMatrices(activeCamera);
							spaceViewRenderer.needsRender = true;
							spaceViewRenderer.update();
						}
					};

					if (spaceViewRenderer.resizeDetector) {
						spaceViewRenderer.resizeDetector.isActive = false;
					}

					if (viewMode === "normal") {
						photoSphereSceneManager.signals.onBeforeRender.remove(onUpdateSpaceEditorCamera);
					} else {
						photoSphereSceneManager.signals.onBeforeRender.add(onUpdateSpaceEditorCamera);
					}

					return () => {
						photoSphereSceneManager.signals.onBeforeRender.remove(onUpdateSpaceEditorCamera);
						if (spaceViewRenderer.resizeDetector) {
							spaceViewRenderer.resizeDetector.isActive = true;
						}
					};
				}, [viewMode, activeCamera]);

				useEffect(() => {
					requestAnimationFrame(() => {
						if (photoSphereSceneManager.canvas.parentElement) {
							photoSphereSceneManager.onWindowResize();
						}
						if (spaceViewRenderer.canvas.parentElement) {
							spaceViewRenderer.onResize();
						}
					});
				}, [viewMode]);

				const domDestination = ["mixed", "split"].includes(viewMode)
					? spaceViewRenderer.canvas.parentElement
					: spaceViewRenderer.transport.appState.app.modalContainer;

				const shadowDivStyle: CSSProperties = {};
				const photoSphereViewerStyle: CSSProperties = {};

				shadowDivStyle.zIndex = zIndex.shadowDiv + 1; // https://dev.azure.com/xyicon/SpaceRunner%20V4/_workitems/edit/7800

				if (viewMode === "transitioning") {
					shadowDivStyle.background = "none";
					photoSphereViewerStyle.display = "none";
					spaceViewRenderer.canvas.style.width = "";
					if (photoSphereSceneManager.canvas.parentElement) {
						photoSphereSceneManager.canvas.parentElement.style.visibility = "hidden";
					}
				} else {
					shadowDivStyle.background = "";
					photoSphereViewerStyle.display = "";
					if (photoSphereSceneManager.canvas.parentElement) {
						photoSphereSceneManager.canvas.parentElement.style.visibility = "";
					}
					if (viewMode !== "normal") {
						shadowDivStyle.display = "contents";
						photoSphereViewerStyle.top = "0";
						photoSphereViewerStyle.left = "0";
						photoSphereViewerStyle.width = "100%";
						photoSphereViewerStyle.height = "100%";
						photoSphereViewerStyle.transform = "none";
					}
					if (viewMode === "split") {
						photoSphereSceneManager.canvas.style.width = "50%";
						photoSphereSceneManager.canvas.style.height = "100%";
						photoSphereSceneManager.canvas.style.left = "50%";
						photoSphereSceneManager.canvas.style.position = "absolute";
						spaceViewRenderer.canvas.style.width = "50%";
					} else {
						photoSphereSceneManager.canvas.style.width = "";
						photoSphereSceneManager.canvas.style.left = "";
						spaceViewRenderer.canvas.style.width = "";
					}
				}

				photoSphereSceneManager.canvas.style.opacity = viewMode === "mixed" ? `${canvasOpacity}` : "";

				requestAnimationFrame(() => {
					const currentDiv = divRef.current;

					if (currentDiv && !HTMLUtils.isDescendant(currentDiv, photoSphereSceneManager.canvas.parentElement)) {
						photoSphereSceneManager.unmount();
						photoSphereSceneManager.mount(currentDiv);
						photoSphereSceneManager.init();

						// We create a new cameracontrols instance, and with it a new cameraposition instance
						const newCameraPosition = photoSphereSceneManager?.cameraControls?.cameraPosition;
						if (newCameraPosition) {
							const activeCameraPosition = activeCamera.position;
							newCameraPosition.x.reset(activeCameraPosition.x, activeCameraPosition.x);
							newCameraPosition.y.reset(activeCameraPosition.y, activeCameraPosition.y);
							newCameraPosition.z.reset(activeCameraPosition.z, activeCameraPosition.z);
						}
						photoSphereSceneManager.animate();
						activePhotoSphere.activate();
						requestAnimationFrame(() => {
							photoSphereSceneManager.onWindowResize();
						});
					}
				});

				const availableViewModes: PhotoSphereViewMode[] = ["normal"];
				if (activePhotoSphere?.itemModel?.spaceId) {
					availableViewModes.push("mixed", "split");
				}

				const markup360Photos = spaceViewRenderer.markupManager.items.array.filter((item: SpaceItem) => item.type === MarkupType.Photo360);
				const limitValueOfDisplayed360Photos = Math.min(photoSphereSceneManager.hotSpotNumberLimit, markup360Photos.length - 1);
				const sliderMaxFor360PhotoLimit = Math.max(1, markup360Photos.length - 1);

				return (
					<DomPortal destination={domDestination}>
						<ShadowDivStyled style={shadowDivStyle}>
							<PhotoSphereViewerStyled
								ref={divRef}
								style={photoSphereViewerStyle}
							>
								{photoSphereManager.isPanoramaLoading && (
									<FullscreenLoader
										text="Loading panorama..."
										style={{width: "100%", height: "100%"}}
									/>
								)}
								<TopButtonContainerStyled
									onMouseDown={Functions.stopPropagation}
									onMouseMove={Functions.stopPropagation}
								>
									<SelectInputV5
										options={availableViewModes}
										sort={false}
										selected={photoSphereSceneManager.viewMode}
										onChange={onViewModeChange}
										render={(option) => `${StringUtils.capitalize(option)} View`}
									/>
									<IconButtonV5
										onClick={onCloseClick}
										IconComponent={CloseIcon}
									/>
								</TopButtonContainerStyled>
								<BottomLeftButtonContainerStyled onMouseDown={Functions.stopPropagation}>
									<ToggleSwitchFieldV5
										labelFirst={true}
										label="Show other 360 photos"
										noBooleanLabel={true}
										value={photoSphereSceneManager.showHotSpots}
										onChange={(newValue: boolean) => {
											photoSphereSceneManager.showHotSpots = newValue;
										}}
									/>
									<SliderContainerStyled>
										<SliderLabelStyled>Limit of 360 photos to show:</SliderLabelStyled>
										<SliderV5
											value={limitValueOfDisplayed360Photos}
											onValueChange={(newValue: number) => {
												photoSphereSceneManager.hotSpotNumberLimit = newValue;
											}}
											width={220}
											arrows={true}
											min={0}
											max={sliderMaxFor360PhotoLimit}
											largeStepValue={MathUtils.clamp(
												Math.round(
													MathUtils.getInterpolation(0.1 * sliderMaxFor360PhotoLimit, 0, sliderMaxFor360PhotoLimit) * sliderMaxFor360PhotoLimit,
												),
												1,
												5,
											)}
											roundValues={true}
										/>
										<div className="flexCenter">{limitValueOfDisplayed360Photos}</div>
									</SliderContainerStyled>
									{viewMode === "mixed" && (
										<SliderContainerStyled>
											<SliderLabelStyled>Opacity:</SliderLabelStyled>
											<SliderV5
												value={canvasOpacity}
												onValueChange={setCanvasOpacity}
												width={220}
												arrows={true}
												min={0}
												max={1}
												largeStepValue={0.1}
											/>
										</SliderContainerStyled>
									)}
									{viewMode !== "normal" && (
										<>
											<SliderContainerStyled>
												<SliderLabelStyled>Height Offset:</SliderLabelStyled>
												<SliderV5
													value={heightOffsetFromFloorplan}
													onValueChange={setHeightOffset}
													onPointerUp={persistMarkupChange}
													width={220}
													arrows={true}
													min={0.02}
													max={2 * DefaultHeightOfMarkupPhotosInMeters}
													largeStepValue={0.01}
												/>
											</SliderContainerStyled>
											<SliderContainerStyled>
												<SliderLabelStyled>Rotation Offset:</SliderLabelStyled>
												<SliderV5
													value={floorplanRotationOffset}
													onValueChange={setRotationOffset}
													onPointerUp={persistMarkupChange}
													width={220}
													arrows={true}
													min={-Math.PI}
													max={Math.PI}
													largeStepValue={0.01}
												/>
											</SliderContainerStyled>
										</>
									)}
								</BottomLeftButtonContainerStyled>
							</PhotoSphereViewerStyled>
						</ShadowDivStyled>
					</DomPortal>
				);
			}}
		</Observer>
	);
};

const SliderContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const SliderLabelStyled = styled.div`
	font-size: 14px;
`;

const BottomLeftButtonContainerStyled = styled.div`
	position: absolute;
	left: 8px;
	bottom: 8px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
	border-radius: 8px;
	padding: 16px 8px;
	z-index: 2;

	${ToggleSwitchFieldStyled} {
		margin-bottom: 0 !important;
		.label {
			margin-left: 0;
		}
	}
`;

const TopButtonContainerStyled = styled.div`
	position: absolute;
	top: 8px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 8px;
	z-index: 2;
	justify-content: space-between;

	${ButtonStyled} {
		background-color: white;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	}

	${SelectInputStyled} {
		background-color: white;
		padding: 0 8px;
	}
`;

const PhotoSphereViewerStyled = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80%;
	height: 80%;
	transform: translate(-50%, -50%);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
	cursor: grab;
	&.rotating {
		cursor: grabbing;
	}

	${IconButtonStyled} {
		background-color: white;
		cursor: pointer;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	}
`;
