import * as React from "react";
import {inject} from "mobx-react";
import {PointerDetector} from "../../../../utils/interaction/PointerDetector";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {ReactUtils} from "../../../utils/ReactUtils";
import {InfoButton} from "../../../modules/abstract/common/infobutton/InfoButton";
import type {AppState} from "../../../../data/state/AppState";
import {InfoBubble} from "../../../modules/abstract/common/infobutton/InfoBubble";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";

type IdType = string | number;

interface ISelectSliderProps {
	readonly options: ISelectSliderOption[];
	readonly rows: ISelectSliderRow[];
	readonly onChange: (rowKeys: string[], value: IdType) => void;
	readonly disabled?: boolean;
	readonly noButtons?: boolean;
	readonly appState?: AppState;
}

interface ISelectSliderState {
	isToolTipOpen: boolean;
	toolTipTransform: TransformObj;
	label: string;
	hoveredPermissionIndex: number;
	outlinedPermissionIndex: number;
}

export interface ISelectSliderOption {
	id: IdType;
	label: string;
	tooltip?: string;
}

export interface ISelectSliderRow {
	value: IdType;
	label: string;
	disabledOptionsList?: ISelectSliderOption[];
}

@inject("appState")
export class SelectSlider extends React.Component<ISelectSliderProps, ISelectSliderState> {
	private _parentRefArray: HTMLDivElement[] = [];
	private _floating = React.createRef<HTMLDivElement>();
	private _sliderDiv = React.createRef<HTMLDivElement>();
	private _header = React.createRef<HTMLDivElement>();

	private _bar: HTMLDivElement;
	private _rowKey = "";
	private _lastDispatchedId: IdType = null;

	constructor(props: ISelectSliderProps) {
		super(props);

		this.state = {
			isToolTipOpen: false,
			toolTipTransform: null,
			label: "",
			hoveredPermissionIndex: -1,
			outlinedPermissionIndex: -1,
		};
	}

	private onMouseDown = (event: React.MouseEvent, rowKey: string) => {
		this._rowKey = rowKey;
		this._lastDispatchedId = null;

		this._bar = event.currentTarget as HTMLDivElement;
		this.updateSlider(event.nativeEvent);

		document.addEventListener("mousemove", this.onMouseMoveWhenSliderClicked);
		document.addEventListener("mouseup", this.onMouseUp);
	};

	private onMouseUp = () => {
		document.removeEventListener("mousemove", this.onMouseMoveWhenSliderClicked);
		document.removeEventListener("mouseup", this.onMouseUp);
	};

	private onMouseOverOption = (event: React.MouseEvent, label: string, index: number) => {
		this.setState({
			isToolTipOpen: true,
			label,
			hoveredPermissionIndex: index,
		});
	};

	private onMouseLeavePermissionOption = () => {
		if (this.isHeaderButtonEnabled) {
			this.setState({
				isToolTipOpen: false,
				label: "",
				hoveredPermissionIndex: -1,
			});
		}
	};

	private onMouseOverGreyBar = (event: React.MouseEvent, rowKey: any) => {
		this._bar = event.currentTarget as HTMLDivElement;
		this._rowKey = rowKey;
		document.addEventListener("mousemove", this.updateOutline);
		this.updateOutline(event.nativeEvent);
	};

	private onMouseLeaveGreyBar = () => {
		if (this.isHeaderButtonEnabled) {
			document.removeEventListener("mousemove", this.updateOutline);
			this.setState({outlinedPermissionIndex: -1});
		}
	};

	private onMouseMoveWhenSliderClicked = (event: MouseEvent) => {
		this.updateSlider(event);
	};

	private calculateSliderOptionIndex = (event: MouseEvent) => {
		const {options, rows} = this.props;
		const rowIndex = rows.findIndex((row) => row.label === this._rowKey);
		const disabledOptions = rows[rowIndex].disabledOptionsList || [];
		const rowOptions = options.filter((option) => !disabledOptions.map((o) => o.label).includes(option.label));

		const mousePos = PointerDetector.getLocalCoords(event, this._bar);

		const ratio = MathUtils.clamp(mousePos[0] / this._bar.clientWidth, 0, 1);
		const value = (rowOptions.length - 1) * ratio;

		const index = Math.round(value);

		return {index, option: rowOptions[index]};
	};

	private updateOutline = (event: MouseEvent) => {
		if (this.isHeaderButtonEnabled) {
			const {index} = this.calculateSliderOptionIndex(event);

			this.setState({outlinedPermissionIndex: index});
		}
	};

	private updateSlider(event: MouseEvent) {
		const {onChange} = this.props;
		const {option} = this.calculateSliderOptionIndex(event);

		if (option) {
			if (this._lastDispatchedId !== option.id) {
				this._lastDispatchedId = option.id;
				onChange([this._rowKey], option.id);
			}
		}
	}

	private onChangeAllElementPermission = (permission: IdType) => {
		const {rows, onChange} = this.props;
		const keys: string[] = [];

		rows.forEach((row) => {
			const disabledOptionIds = row.disabledOptionsList?.map((list) => list.id);

			if (!disabledOptionIds?.includes(permission)) {
				keys.push(row.label);
			}
		});
		onChange(keys, permission);
	};

	private onScroll = () => {
		const header = this._header.current;

		if (this._sliderDiv.current.scrollTop > 0) {
			header.classList.add("scrolled");
		} else {
			header.classList.remove("scrolled");
		}
	};

	private get _modalContainer() {
		return this.props.appState.app.modalContainer;
	}

	private get isHeaderButtonEnabled() {
		const {rows, noButtons} = this.props;

		return rows.length !== 1 && !noButtons;
	}

	public override componentDidUpdate = (prevProps: ISelectSliderProps, prevState: ISelectSliderState) => {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parentRefArray[this.state.hoveredPermissionIndex] && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parentRefArray[this.state.hoveredPermissionIndex],
					this._floating.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
					5,
					0,
				),
			});
		}
	};

	public override componentDidMount() {
		this._sliderDiv.current.addEventListener("scroll", this.onScroll);
	}

	public override componentWillUnmount() {
		this._sliderDiv.current.removeEventListener("scroll", this.onScroll);
	}

	public override render() {
		const {options, rows, disabled} = this.props;
		const {isToolTipOpen, toolTipTransform, label, outlinedPermissionIndex} = this.state;

		const grid = {
			cols: `repeat(${options.length - 1}, 75px) 23px`,
			rows: `repeat(${rows.length}, 40px)`,
		};

		const floatingElement = this._floating.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: toolTipTransform?.translate,
		};

		return (
			<>
				<div
					className={ReactUtils.cls("PermissionOptions", {emptyLabel: rows.every((row) => !row.label)})}
					style={{
						gridTemplateColumns: grid.cols,
					}}
					ref={this._header}
				>
					{options.map((option, j) => (
						<span
							key={j}
							className="option hbox alignCenter justifyCenter"
							style={{gridRow: "1", gridColumn: j + 1}}
						>
							<div
								ref={(parentRef) => (this._parentRefArray[j] = parentRef)}
								className={ReactUtils.cls("option-label", {outlined: outlinedPermissionIndex === j, disabled: !this.isHeaderButtonEnabled})}
								onClick={() => this.isHeaderButtonEnabled && this.onChangeAllElementPermission(option.id)}
								onMouseOver={(e: React.MouseEvent) => this.isHeaderButtonEnabled && this.onMouseOverOption(e, option.label, j)}
								onMouseLeave={this.onMouseLeavePermissionOption}
							>
								{option.label}
							</div>
							{option.tooltip && this.isHeaderButtonEnabled && (
								<InfoButton
									bubbleText={option.tooltip}
									className="sliderToolTip"
								/>
							)}
						</span>
					))}
				</div>
				<div
					ref={this._sliderDiv}
					className={ReactUtils.cls("SelectSlider vbox", {disabled})}
				>
					<div
						className="sliders"
						style={{
							gridTemplateColumns: grid.cols,
							gridTemplateRows: grid.rows,
						}}
					>
						{rows.map((row, i) => {
							const selectedIndex = Math.max(
								0,
								options.findIndex((option) => row.value === option.id),
							);
							const disabledOptions = row.disabledOptionsList || [];
							const rowOptions = options.filter((option) => !disabledOptions.map((o) => o.label).includes(option.label));

							return (
								<div
									className="sliderRow"
									key={i}
								>
									{row.label && <div className="sliderName">{row.label}</div>}
									<div
										className="slider"
										style={{gridTemplateColumns: grid.cols}}
									>
										{options.map(
											(option, j) =>
												rowOptions.find((o) => option.label === o.label) && (
													<div
														className="ticks"
														style={{gridRow: i + 2, gridColumn: j + 1}}
														key={j}
													>
														<div className="tick top" />
														<div className="tick bottom" />
													</div>
												),
										)}
										<div
											className="greyBar"
											style={{gridRow: i + 2, gridColumn: `1 / ${rowOptions.length}`}}
											onMouseDown={(e) => this.onMouseDown(e, row.label)}
											onMouseOver={(e) => this.isHeaderButtonEnabled && this.onMouseOverGreyBar(e, row.label)}
											onMouseLeave={this.onMouseLeaveGreyBar}
										/>
										<div
											className="blueBar"
											style={{gridRow: i + 2, gridColumn: `1 / ${1 + selectedIndex}`, width: selectedIndex === 0 ? "5px" : ""}}
										/>
										<div
											className="knob"
											style={{gridRow: i + 2, gridColumn: `${1 + selectedIndex}`}}
										/>
									</div>
								</div>
							);
						})}
					</div>
					{isToolTipOpen && (
						<DomPortal destination={this._modalContainer}>
							<InfoBubble
								className="permissionInfoBubble"
								content={`Switch all to ${label}`}
								style={inlineStyle}
								divRef={this._floating}
							/>
						</DomPortal>
					)}
				</div>
			</>
		);
	}
}
