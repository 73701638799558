import * as React from "react";
import {inject} from "mobx-react";
import styled from "styled-components";
import type {IViewColumn, IViewSort} from "../../../../data/models/ViewUtils";
import {SortDirection} from "../../../../data/models/ViewUtils";
import type {Pointer} from "../../../../utils/interaction/Pointer";
import type {ManageColumnCallback} from "../../../widgets/table/TableHeaderDropDown";
import type {IFieldPointer} from "../../../../data/models/field/Field";
import {XyiconFeature} from "../../../../generated/api/base";
import type {AppState} from "../../../../data/state/AppState";
import {getSortCicleNextElement} from "../../../widgets/table/TableUtils";
import {ReactUtils} from "../../../utils/ReactUtils";
import {minHeaderWidth, minIconHeaderWidth} from "../../../widgets/table/TableConstants";
import {PointerDetectorReact} from "../../../interaction/PointerDetectorReact";
import ArrowCircleUpIcon from "../../icons/arrow-circle-up.svg?react";
import ArrowCircleDownIcon from "../../icons/arrow-circle-down.svg?react";
import ArrowUpArrowDownIcon from "../../icons/arrow-up-arrow-down.svg?react";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {colorPalette} from "../../styles/colorPalette";
import {baseDistance, ELLIPSIS, FLEXCENTER, radius} from "../../styles/styles";
import {DropdownOptionsStyled, DropdownOptionsV5} from "../../interaction/DropdownOptionsV5";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {Functions} from "../../../../utils/function/Functions";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {ListBuilderInputV5} from "../../details/ListBuilderInputV5";
import type {IMultiSelectFieldSettingsDefinitionV5} from "../../../../data/models/field/datatypes/MultiSelect";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {PopupV5} from "../../popup/PopupV5";

interface ITableHeaderProps<T> {
	readonly header: Partial<IViewColumn>;
	readonly index: number;
	readonly sort?: IViewSort;
	readonly sortIndex: number;
	readonly multipleSorts: boolean;
	readonly dragSource: boolean;
	readonly dragTarget: boolean;
	readonly onDragStart: (index: number, event: React.DragEvent) => void;
	readonly onDragEnd: (index: number, event: React.DragEvent) => void;
	readonly onDragEnter: (index: number, event: React.DragEvent) => void;
	readonly onDragOver: (index: number, event: React.DragEvent) => void;
	readonly onDragLeave: (index: number, event: React.DragEvent) => void;
	readonly onColResizeStart: (index: number, pointer: Pointer) => void;
	readonly onColResizeMove: (index: number, pointer: Pointer, header: Partial<IViewColumn>) => void;
	readonly onToggleSort?: (column: IFieldPointer) => void;
	readonly onAutoResizeColumn: (index: number, header: Partial<IViewColumn>) => void;
	readonly onManageColumns: ManageColumnCallback;
	readonly onSortAsc?: (id: IFieldPointer) => void;
	readonly onSortDesc?: (id: IFieldPointer) => void;
	readonly onClearSort?: (id: IFieldPointer) => void;
	readonly allowHide: boolean;
	readonly feature: XyiconFeature;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface ITableHeaderState<T> {
	hover: boolean;
	isOpen: boolean;
	sortOrder: SortDirection;
	isColBeingResized: boolean;
	isMultiSelectPopupOpen: boolean;
}

@inject("appState")
@inject("transport")
export class TableHeaderV5<T> extends React.Component<ITableHeaderProps<T>, ITableHeaderState<T>> {
	private _ref = React.createRef<HTMLDivElement>();

	constructor(props: ITableHeaderProps<T>) {
		super(props);
		this.state = {
			hover: false,
			isOpen: false,
			sortOrder: this.props.sort?.direction,
			isColBeingResized: false,
			isMultiSelectPopupOpen: false,
		};
	}

	public static getDerivedStateFromProps(props: ITableHeaderProps<any>, state: ITableHeaderState<any>) {
		if (!state.sortOrder && props.sort?.direction) {
			return {sortOrder: props.sort?.direction};
		}
		return null;
	}

	private onDragStart = (event: React.DragEvent) => {
		this.props.onDragStart(this.props.index, event);
	};

	private onDragEnd = (event: React.DragEvent) => {
		this.props.onDragEnd(this.props.index, event);
	};

	private onDragEnter = (event: React.DragEvent) => {
		this.props.onDragEnter(this.props.index, event);
	};

	private onDragOver = (event: React.DragEvent) => {
		this.props.onDragOver(this.props.index, event);
	};

	private onDragLeave = (event: React.DragEvent) => {
		this.props.onDragLeave(this.props.index, event);
	};

	private onColResizeStart = (pointer: Pointer) => {
		this.setState({
			isColBeingResized: true,
		});
		this.props.onColResizeStart(this.props.index, pointer);
	};

	private onColResizeMove = (pointer: Pointer) => {
		this.props.onColResizeMove(this.props.index, pointer, this.props.header);
	};

	private onColResizeEnd = (pointer: Pointer) => {
		this.setState({
			isColBeingResized: false,
		});
	};

	private onToggleSort = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.props.onToggleSort(this.props.header.field);

		const newSortOrder = getSortCicleNextElement(this.state.sortOrder);

		this.setState({sortOrder: newSortOrder});
	};

	private onHeaderDoubleClick = () => {
		this.props.onAutoResizeColumn(this.props.index, this.props.header);
	};

	private onMouseOver = () => {
		this.setState({hover: true});
	};

	private onMouseOut = () => {
		this.setState({hover: false});
	};

	private onToggleOpen = () => {
		this.onOpenChange(!this.state.isOpen);
	};

	private onClose = () => {
		this.onOpenChange(false);
		this.onMouseOut();
	};

	private onOpenChange = (isOpenValue: boolean, hoverValue?: boolean) => {
		this.setState({isOpen: isOpenValue});
		if (hoverValue !== undefined) {
			this.setState({hover: hoverValue});
		}
	};

	private onManageColumns = () => {
		this.props.onManageColumns({
			type: "manage",
			index: this.props.index,
		});
	};

	private onHideColumn = () => {
		this.props.onManageColumns({
			type: "hide",
			index: this.props.index,
		});
	};

	private onSortAsc = () => {
		const {appState, header, feature, onSortAsc} = this.props;

		onSortAsc?.(header.field);
		const realFeature = this.props.appState.app.spaceViewRenderer.isMounted ? XyiconFeature.SpaceEditor : feature;
		const view = appState.actions.getSelectedView(realFeature);

		view?.addSort({column: header.field, direction: SortDirection.ASC});

		this.setState({sortOrder: SortDirection.ASC});
	};

	private onSortDesc = () => {
		const {appState, header, feature, onSortDesc} = this.props;

		onSortDesc?.(header.field);
		const realFeature = this.props.appState.app.spaceViewRenderer.isMounted ? XyiconFeature.SpaceEditor : feature;
		const view = appState.actions.getSelectedView(realFeature);

		view?.addSort({column: header.field, direction: SortDirection.DESC});

		this.setState({sortOrder: SortDirection.DESC});
	};

	private onClearSort = (e?: React.MouseEvent) => {
		e?.stopPropagation();

		const {appState, header, feature, onClearSort} = this.props;

		onClearSort?.(header.field);
		const realFeature = this.props.appState.app.spaceViewRenderer.isMounted ? XyiconFeature.SpaceEditor : feature;
		const view = appState.actions.getSelectedView(realFeature);

		view?.addSort({column: header.field, direction: null});

		this.setState({sortOrder: null});
	};

	private onBlur = () => {
		if (this.state.isOpen) {
			this.onClose();
		}

		return false;
	};

	private saveFieldChanges = async () => {
		const {transport} = this.props;

		if (this.headerField.refId) {
			const {error} = await transport.services.typefield.updateField(this.headerField as any);

			if (error) {
				console.warn(error);
			}
		}
	};

	private get headerField() {
		return this.props.appState.actions.getFieldByRefId(this.props.header.field);
	}

	public override componentDidMount(): void {
		FocusLoss.listen(this._ref.current, this.onBlur);
	}

	public override componentDidUpdate() {
		if (this.props.sort?.column !== this.props.header.field && this.state.sortOrder) {
			this.setState({sortOrder: null});
		}
	}

	public override componentWillUnmount(): void {
		FocusLoss.stopListen(this._ref.current, this.onBlur);
	}

	public override render() {
		const {header, index, dragSource, dragTarget, allowHide, sortIndex, multipleSorts, appState} = this.props;
		const {hover, isOpen, sortOrder} = this.state;
		const iconField = header.field.includes("icon");
		const field = this.headerField;
		const width = iconField ? `${header.width || minIconHeaderWidth}px` : `${Math.max(header.width, minHeaderWidth) || minHeaderWidth}px`;

		return (
			<TableHeaderStyled
				ref={this._ref}
				className={ReactUtils.cls("th", {
					dragSource: dragSource,
					dragTarget: dragTarget,
					icon: header.field.includes("icon"),
				})}
				key={header.field ?? header.title ?? index}
				style={{
					width,
					minWidth: width,
				}}
				onClick={this.onToggleOpen}
				draggable={!this.state.isColBeingResized}
				onDragStart={this.onDragStart}
				onDragEnd={this.onDragEnd}
				onDragOver={this.onDragOver}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
				onMouseEnter={this.onMouseOver}
				onMouseLeave={this.onMouseOut}
			>
				{iconField ? (
					<span className="label icon">{header.title || `Column ${index + 1}`}</span>
				) : (
					<span className="label">{header.title || `Column ${index + 1}`}</span>
				)}
				{sortOrder && this.props.sort?.column === header.field && !iconField && (
					<div
						className={ReactUtils.cls("sort sortIndicator", {large: multipleSorts})}
						onClick={this.onToggleSort}
					>
						{sortOrder === SortDirection.ASC ? <ArrowCircleUpIcon /> : <ArrowCircleDownIcon />}
						{multipleSorts && sortIndex + 1}
					</div>
				)}
				{/* {
					sortOrder && hover && this.props.sort?.column === header.field && !iconField &&
						<div className="sort cancelSort" onClick={this.onClearSort}>
							<CloseIcon />
						</div>
				} */}
				{hover && !sortOrder && (
					<IconButtonV5
						onClick={this.onToggleSort}
						IconComponent={ArrowUpArrowDownIcon}
						className="sortIcon"
					/>
				)}
				{isOpen && (
					<DropdownOptionsV5
						onClose={this.onClose}
						showSearch={false}
						options={[
							...(!iconField
								? [
										{
											label: "Sort Ascending",
											onClick: this.onSortAsc,
											isActive: sortOrder === SortDirection.ASC,
										},
										{
											label: "Sort Descending",
											onClick: this.onSortDesc,
											isActive: sortOrder === SortDirection.DESC,
										},
										{
											label: "Clear Sort",
											onClick: this.onClearSort,
											disabled: !sortOrder,
										},
									]
								: []),
							...(this.props.onManageColumns
								? [
										{
											label: "Manage Columns",
											onClick: this.onManageColumns,
										},
										...(allowHide
											? [
													{
														label: "Hide Column",
														onClick: this.onHideColumn,
													},
												]
											: []),
									]
								: []),
						]}
						style={{top: "48px", left: "0", width, minWidth: "157px"}}
					/>
				)}
				{header.title == "Icon" ? (
					<PointerDetectorReact>
						<div className="resizer icon" />
					</PointerDetectorReact>
				) : (
					<PointerDetectorReact
						onDown={this.onColResizeStart}
						onMove={this.onColResizeMove}
						onUp={this.onColResizeEnd}
					>
						<div
							className="resizer"
							onDoubleClick={this.onHeaderDoubleClick}
							onClick={Functions.stopPropagation}
						/>
					</PointerDetectorReact>
				)}
				{this.state.isMultiSelectPopupOpen && (
					<DomPortal destination={appState.app.modalContainer}>
						<PopupV5
							parentRef={this._ref.current}
							label={field.name}
							closeOnClickOutside={true}
						>
							<ListBuilderInputV5
								list={(field.dataTypeSettings as IMultiSelectFieldSettingsDefinitionV5).choiceList}
								onChange={this.saveFieldChanges}
							/>
						</PopupV5>
					</DomPortal>
				)}
			</TableHeaderStyled>
		);
	}
}

const TableHeaderStyled = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	height: 100%;
	padding: 0 ${baseDistance.sm};
	gap: ${baseDistance.xs};
	cursor: pointer;

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	${DropdownOptionsStyled} {
		top: calc(100% + 8px);
		left: 0;
	}

	svg {
		width: 16px;
		height: 16px;
	}

	&:first-child {
		padding: 0 2px;
	}

	&.dragSource {
		outline: 1px solid black;
		outline-offset: -2px;
	}

	&.dragTarget {
		::after {
			content: "";
			position: absolute;
			right: 0px;
			width: 2px;
			top: 0;
			bottom: 0;
			background: ${colorPalette.primary.c700Dark};
		}
	}

	.label {
		${ELLIPSIS};
		padding-right: ${baseDistance.xs};

		&.icon {
			cursor: default;
		}
	}

	.sortIcon {
		${FLEXCENTER};
		width: 24px;
		height: 24px;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	.sort {
		&.sortIndicator {
			display: flex;
			align-items: center;
			gap: ${baseDistance.xs};
			background-color: ${colorPalette.primary.c200Light};
			border-radius: ${radius.sm};
			color: ${colorPalette.primary.c500Primary};
			padding: ${baseDistance.xs};
			cursor: pointer;
		}

		&.cancelSort {
			${FLEXCENTER};
			background-color: ${colorPalette.white};
			cursor: pointer;
		}
	}

	.resizer {
		position: absolute;
		top: 0px;
		right: -5px;
		width: 10px;
		height: 100%;
		z-index: 1;

		&.icon {
			cursor: default;
		}

		&:not(.checkbox, .icon) {
			cursor: col-resize;
		}

		&::after {
			content: "";
			position: absolute;
			left: 5px;
			width: 1px;
			height: 100%;
			background: ${colorPalette.gray.c400};
		}
	}

	.CheckboxInput {
		input[type="checkbox"] & + label {
			padding: 0;
			margin: 0 0 0 3px;
			width: 10px;
			height: 15px;
		}
	}
`;
