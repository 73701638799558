import type {Object3D} from "three";
import {Mesh} from "three";
import {RingGeometry} from "three/src/geometries/RingGeometry.js";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {BasicMaterial} from "../../materials/BasicMaterial";
import {Constants} from "../../Constants";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import type {Pointer} from "../../../../../../../utils/interaction/Pointer";
import {Tool} from "./Tool";
import type {ToolType} from "./Tools";

export class SetPinTool extends Tool {
	private _container: Object3D;
	private _mesh: Mesh;
	protected override _toolType: ToolType = "setPin";

	private _areListenersAdded: boolean = false;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, false, "crosshair");

		this._container = this._spaceViewRenderer.topLayerScene;
	}

	public addListeners() {
		if (!this._areListenersAdded) {
			this.initMeshIfNeeded();
			if (!this._mesh.parent) {
				THREEUtils.add(this._container, this._mesh);
			}
			this.updateMeshScale(true);
			this.cameraZoomChangeSignal.add(this.onCameraZoomChange);
			this._areListenersAdded = true;

			this._spaceViewRenderer.needsRender = true;
		}
	}

	public removeListeners() {
		if (this._areListenersAdded) {
			this.cameraZoomChangeSignal.remove(this.onCameraZoomChange);
			if (this._mesh.parent) {
				this._mesh.parent.remove(this._mesh);
			}
			this._areListenersAdded = false;

			this._spaceViewRenderer.needsRender = true;
		}
	}

	public updateMeshScale(updateMatrices: boolean = false) {
		if (this._mesh?.parent) {
			const correctionMultiplier = this._spaceViewRenderer.correctionMultiplier.original;
			const cameraZoomLevel = this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;
			const scale = (13 * correctionMultiplier) / cameraZoomLevel;

			this._mesh.scale.set(scale, scale, scale);
			if (updateMatrices) {
				THREEUtils.updateMatrices(this._mesh);
			}
		}
	}

	private onCameraZoomChange = () => {
		this.updateMeshScale(true);
	};

	private initMeshIfNeeded() {
		if (!this._mesh) {
			const geometry = new RingGeometry(0.8, 1.0, 32, 1);
			const material = new BasicMaterial(parseInt(`0x${Constants.COLORS.PICTON_BLUE}`));

			this._mesh = new Mesh(geometry, material);
		}
	}

	public setPinPosition(worldX: number, worldY: number) {
		this.initMeshIfNeeded();
		this.updateMeshScale();
		THREEUtils.setPosition(this._mesh, worldX, worldY);
		this._spaceViewRenderer.tileManager.setPivot(worldX, worldY);
		this._spaceViewRenderer.needsRender = true;
	}

	protected override onPointerDownCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		this.initMeshIfNeeded();
		THREEUtils.setPosition(this._mesh, worldX, worldY);
		this._spaceViewRenderer.needsRender = true;
	};

	protected override onPointerMoveCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		THREEUtils.setPosition(this._mesh, worldX, worldY);
		this._spaceViewRenderer.needsRender = true;
	};

	protected override onPointerUpCallback = () => {
		this._spaceViewRenderer.tileManager.setPivot(this._mesh.position.x, this._mesh.position.y);
		this._spaceViewRenderer.needsRender = true;
	};

	private get cameraZoomChangeSignal() {
		return this._spaceViewRenderer.toolManager.cameraControls.signals.cameraZoomChange;
	}
}
