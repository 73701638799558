import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import type {AppState} from "../../../data/state/AppState";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {FormContainerStyled, LoginWindowStyled} from "../user/UserStaticV5.Style";
import {LoaderIconV5} from "../loader/LoaderIconV5";
import CircleQuestionIcon from "../icons/circle-question.svg?react";
import {IconButton} from "../../widgets/button/IconButton";
import {handleLogin} from "../../../utils/LoginUtils";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {ReactUtils} from "../../utils/ReactUtils";
import type {XyiconLoginAndSSOLoginState} from "../../windows/login/LoginWindow";

interface IILoginWindowProps {
	readonly navigation?: Navigation;
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
	readonly isOrganizationSwitchView?: boolean;
	readonly handleClose?: () => void;
	readonly handleOrganizationSwitch?: () => Promise<void>;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class SSOLoginWindowV5 extends React.Component<IILoginWindowProps, XyiconLoginAndSSOLoginState> {
	constructor(props: IILoginWindowProps) {
		super(props);

		this.state = {
			isValidEmail: false,
			inputString: "",
		};
	}

	public override componentDidMount() {
		window.addEventListener("hashchange", this.onHashChange);
	}

	public override componentWillUnmount() {
		window.removeEventListener("hashchange", this.onHashChange);
	}

	private onHashChange = () => {
		this.props.appState.isLoggingIn = false;
	};

	private goBackToNormalLogin = () => {
		this.props.navigation.go("auth/login");
	};

	private validateInputs = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {
			target: {value},
		} = event;
		const isValid = StringUtils.emailValidator(value);
		this.setState({isValidEmail: isValid, inputString: value});
	};

	public override render() {
		const {user, isLoggingIn} = this.props.appState;
		const {isValidEmail, inputString} = this.state;
		const {isOrganizationSwitchView = false} = this.props;
		const isDisabled = isLoggingIn || !isValidEmail;

		return (
			<LoginWindowStyled className="LoginWindow">
				<FormContainerStyled
					className="formContainer hbox alignCenter login"
					$loginWithSSO={true}
					$isOrganizationSwitchView={isOrganizationSwitchView}
				>
					{isOrganizationSwitchView && (
						<div className="closeButtonContainer">
							<IconButton
								className="close"
								icon="close"
								onClick={this.props.handleClose}
							/>
						</div>
					)}
					<SVGIcon
						classNames="xyicon-logo"
						icon="xyiconlogo"
					/>
					<form
						className="vbox"
						role="form"
						name="formLogin"
						noValidate
						autoCapitalize="off"
						onSubmit={this.onSubmit}
					>
						<div className="inputGroup login">
							<div className="labelName">Email</div>
							<input
								id="userName"
								name="userName"
								type="text"
								className="Input"
								placeholder="Email"
								onChange={(event) => this.validateInputs(event)}
								value={isOrganizationSwitchView ? user?.email : inputString}
								disabled={isOrganizationSwitchView}
							/>
						</div>
						<button
							name="submitButton"
							className={ReactUtils.cls("Button primary", {disabled: isDisabled})}
							type="submit"
							disabled={isDisabled}
						>
							{isLoggingIn ? (
								<div className="loading">
									<LoaderIconV5 />
								</div>
							) : (
								"Log in with SSO"
							)}
						</button>
						{!isOrganizationSwitchView && (
							<div className="sso-button-container">
								<button
									type="button"
									className="sso-login-text"
									disabled={isLoggingIn}
									onClick={this.goBackToNormalLogin}
								>
									Go back to login page
								</button>
							</div>
						)}
						<div className="labelName help">
							<a
								href="https://support.xyicon.com/docs"
								target="_blank"
							>
								<CircleQuestionIcon />
								<p>Help</p>
							</a>
						</div>
					</form>
				</FormContainerStyled>
			</LoginWindowStyled>
		);
	}

	private handleLoginStateChange = (state: XyiconLoginAndSSOLoginState) => {
		this.setState(state);
	};

	private onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();
		const {transport, appState, isOrganizationSwitchView, navigation, handleOrganizationSwitch} = this.props;
		this.props.appState.lastError = "";

		handleLogin({
			event,
			transport,
			appState,
			navigation,
			isOrganizationSwitchView,
			loginWithSSO: true,
			handleOrganizationSwitch,
			handleLoginStateChange: this.handleLoginStateChange,
		});
	};
}
