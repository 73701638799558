import {Tool} from "../Tool";
import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import {MeasureType} from "../../../renderers/SpaceViewRendererUtils";
import {MarkupUtils, type IMarkupConfig} from "../../../elements3d/markups/abstract/MarkupUtils";
import type {ToolType} from "../Tools";
import type {Pointer} from "../../../../../../../../utils/interaction/Pointer";
import {MarkupType} from "../../../../../../../../generated/api/base";
import {MarkupPhoto360} from "../../../elements3d/markups/MarkupPhoto360";

type MarkupStampType = "photo360";

/**
 * A tool that can create MarkupAB: a common markup family that can be defined by 2 pairs of world coordinates: A, and B
 */

export class MarkupStampTool extends Tool {
	private _isCurrentlySaving: boolean = false;
	protected _markup: MarkupPhoto360;
	protected override _toolType: ToolType = "markup";
	private readonly _type: MarkupStampType;
	private readonly _measureType: MeasureType = MeasureType.NONE;

	constructor(spaceViewRenderer: SpaceViewRenderer, type: MarkupStampType) {
		super(spaceViewRenderer, false, "crosshair");
		this._type = type;
	}

	private get _markupManager() {
		return this._spaceViewRenderer.markupManager;
	}

	private getCurrentMarkupType(): MarkupType {
		switch (this._type) {
			case "photo360":
				return MarkupType.Photo360;
		}
	}

	public override deactivate(): boolean {
		this.abortMarkup();
		return super.deactivate();
	}

	protected override onPointerDownCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		if (this._isCurrentlySaving) {
			return;
		}

		const color = this._markupManager.markupColor;

		const fillConfig: IMarkupConfig = {
			strokeColor: color,
			fill: true,
			measureType: this._measureType,
			isTemp: false,
			fillOpacity: MarkupUtils.getDefaultFillOpacityForType(this.getCurrentMarkupType()),
		};

		switch (this._type) {
			case "photo360":
				this._markup = new MarkupPhoto360(this._spaceViewRenderer, fillConfig, this._markupManager.photo360InstancedMesh.count);
				this._markup.updateGeometry([{x: worldX, y: worldY}]);
				this._markup.setColor(parseInt(`0x${color}`), 1);
				this._markup.setGrayScaled(true);
				break;
		}

		this._markup?.showLayerWithNotification();
		this._spaceViewRenderer.spaceItemController.deselectAll();
	};

	protected override onPointerMoveCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		this._markup?.updateGeometry([{x: worldX, y: worldY}]);
	};

	protected override onPointerUpCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		this.finalizeMarkup(worldX, worldY);
	};

	private async finalizeMarkup(worldX: number, worldY: number) {
		if (!this._isCurrentlySaving) {
			this._isCurrentlySaving = true;
			const currentMarkup = this._markup;

			if (currentMarkup) {
				if (currentMarkup.isValid) {
					await this._markupManager.add([currentMarkup]);
				} else {
					this.abortMarkup();
				}

				this._markup = null;
			}
			this._isCurrentlySaving = false;
		}
	}

	public get markup() {
		return this._markup;
	}

	public abortMarkup() {
		this._markup?.destroy(false, true);
		this._markup = null;
	}
}
