import styled from "styled-components";
import React, {useState, useEffect, useRef} from "react";
import type {Space} from "../../../../../data/models/Space";
import ArrowDownIcon from "../../../icons/chevron-down.svg?react";
import ListIcon from "../../../icons/list.svg?react";
import {baseDistance, ELLIPSIS, FLEXCENTER, FlexCenterStyle, fontSize, fontWeight, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {useClickOutside} from "../../../utils";
import {VerticalLine} from "../../../widgets/VerticalLine";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import {useAppStore} from "../../../../../StateManager";
import {DropdownButtonStyled} from "../../../interaction/DropdownButtonV5";
import SearchIcon from "../../../icons/search.svg?react";
import {DomPortal} from "../../../../modules/abstract/portal/DomPortal";
import type {TransformObj} from "../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {SpaceList} from "./SpaceList";
import {SpaceExporter} from "./SpaceExporter";
import {GridSelector} from "./GridSelector";
import {SpaceSearch} from "./SpaceSearch";

interface ISpaceSelectorProps {
	readonly spaces: Space[];
	readonly selectedSpace: Space;
	readonly divRef: React.RefObject<HTMLDivElement>;
	readonly onSpaceSelect: (selectedSpace: Space) => void;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly verticalAlignment?: VerticalAlignment;
	readonly offsetX?: number;
	readonly offsetY?: number;
}

export const SpaceSelector = (props: ISpaceSelectorProps) => {
	const appState = useAppStore((state) => state.appState);
	const modalContainer = appState.app.modalContainer;
	const {
		selectedSpace,
		spaces,
		onSpaceSelect,
		horizontalAlignment = HorizontalAlignment.left,
		verticalAlignment = VerticalAlignment.bottomOuter,
		offsetX = 0,
		offsetY = 8,
	} = props;
	const wrapperRef = useRef<HTMLDivElement>();
	const searchRef = useRef<HTMLDivElement>();
	const _floatingElement = useRef<HTMLDivElement>();
	const [isSpaceContainerOpen, setIsSpaceContainerOpen] = useState<boolean>(false);
	const [isGridSelectorOpen, setIsGridSelectorOpen] = useState<boolean>(false);
	const [isSpaceSearchOpen, setIsSpaceSearchOpen] = useState<boolean>(false);
	const [optionsTransform, setOptionsTransform] = useState<TransformObj | null>(null);

	useEffect(() => {
		const searchInput = document.querySelector("#portfolio_search input");

		if (searchInput) {
			searchInput.addEventListener("click", () => {
				setIsSpaceSearchOpen(false);
			});
		}
	}, []);

	useClickOutside([props.divRef], (event: MouseEvent) => {
		setIsSpaceContainerOpen(false);
		setIsGridSelectorOpen(false);
		setIsSpaceSearchOpen(false);
	});

	const onCurrentSpaceContainerClick = () => {
		setIsSpaceContainerOpen(!isSpaceContainerOpen);
		setIsGridSelectorOpen(false);
		setIsSpaceSearchOpen(false);
	};

	const onGridSelectorClick = () => {
		setIsGridSelectorOpen(!isGridSelectorOpen);
		setIsSpaceContainerOpen(false);
		setIsSpaceSearchOpen(false);
	};

	const onSpaceSearchClick = () => {
		setIsSpaceSearchOpen(!isSpaceSearchOpen);
		setIsSpaceContainerOpen(false);
		setIsGridSelectorOpen(false);
	};

	const onSpaceExporterClick = () => {
		setIsGridSelectorOpen(false);
		setIsSpaceContainerOpen(false);
		setIsSpaceSearchOpen(false);
	};

	const onCloseSpaceContainer = () => {
		setIsSpaceContainerOpen(false);
	};

	useEffect(() => {
		if (isSpaceSearchOpen && searchRef.current && _floatingElement.current) {
			setOptionsTransform(
				DomUtils.getFixedFloatingElementPosition(
					searchRef.current,
					_floatingElement.current,
					verticalAlignment,
					horizontalAlignment,
					offsetY,
					offsetX,
					true,
				),
			);
		}
	}, [isSpaceSearchOpen, horizontalAlignment, verticalAlignment, offsetX, offsetY]);

	const inlineStyle: React.CSSProperties = {
		transform: optionsTransform?.translate,
		visibility: optionsTransform ? "visible" : "hidden",
	};

	return (
		<SpaceSelectorWrapper ref={props.divRef}>
			<SpaceSelectorStyled>
				<CurrentSpaceContainerStyled onClick={onCurrentSpaceContainerClick}>
					<CurrentSpaceStyled title={selectedSpace.name}>{selectedSpace.name}</CurrentSpaceStyled>
					<ArrowDownIcon style={{width: 16}} />
				</CurrentSpaceContainerStyled>
				<ButtonContainerStyled>
					<VerticalLine />
					<IconButtonV5
						className={isSpaceSearchOpen ? "search-active" : ""}
						divRef={searchRef}
						IconComponent={SearchIcon}
						onClick={onSpaceSearchClick}
					/>
					<IconButtonV5
						IconComponent={ListIcon}
						isActive={isGridSelectorOpen || !!appState.app.graphicalTools.gridItemSide}
						onClick={onGridSelectorClick}
					/>
					<SpaceExporter onClick={onSpaceExporterClick} />
				</ButtonContainerStyled>
			</SpaceSelectorStyled>
			{isSpaceContainerOpen && (
				<SpaceList
					spaces={spaces}
					onSpaceSelect={onSpaceSelect}
					selectedSpace={selectedSpace}
					onCloseSpaceContainer={onCloseSpaceContainer}
				/>
			)}
			{isGridSelectorOpen && <GridSelector onClose={() => setIsGridSelectorOpen(false)} />}
			{isSpaceSearchOpen && (
				<DomPortal destination={modalContainer}>
					<SpaceSearch
						currentSpace={selectedSpace.id}
						divRef={_floatingElement}
						style={inlineStyle}
						autoFocus={true}
					/>
				</DomPortal>
			)}
		</SpaceSelectorWrapper>
	);
};

const SpaceSelectorWrapper = styled.div`
	position: absolute;
	top: 16px;
	left: 16px;
	width: 288px;
	z-index: 2;
`;

const SpaceSelectorStyled = styled.div`
	background-color: ${colorPalette.white};
	height: 48px;
	padding-right: ${baseDistance.sm};
	border-radius: ${radius.md};
	box-shadow: 0px 4px 8px 0px #00000080;
	${FlexCenterStyle};
	justify-content: space-between;

	${IconButtonStyled} {
		&.isActive {
			background-color: ${colorPalette.primary.c200Light};
			color: ${colorPalette.primary.c500Primary};
		}
	}
`;

const CurrentSpaceStyled = styled.div`
	color: ${colorPalette.gray.c700Dark};
	flex: 1;
	${ELLIPSIS}
`;

const CurrentSpaceContainerStyled = styled.div`
	${FlexCenterStyle};
	justify-content: space-between;
	height: 32px;
	width: 142px;
	font-weight: ${fontWeight.normal};
	font-size: ${fontSize.lg};
	line-height: 24px;
	padding: ${baseDistance.sm};
	margin: 0 ${baseDistance.sm};
	cursor: pointer;
	border-radius: ${radius.sm};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}
`;

const ButtonContainerStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${baseDistance.sm};

	.IconButton.search-active {
		background-color: ${colorPalette.primary.c200Light} !important;

		svg path {
			stroke: ${colorPalette.primary.c500Primary};
		}
	}

	> ${IconButtonStyled} {
		${FLEXCENTER};
		width: 32px;
		height: 32px;
		border-radius: ${radius.md};
	}

	${DropdownButtonStyled} {
		border-radius: ${radius.md};
	}
`;
