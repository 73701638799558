import styled, {css} from "styled-components";
import type {MouseEvent} from "react";
import {Observer} from "mobx-react";
import LabelsIcon from "../../../icons/label-icon.svg?react";
import LayersIcon from "../../../icons/layer-icon.svg?react";
import FilterIcon from "../../../icons/filter.svg?react";
import FormattingIcon from "../../../icons/formatting-icon.svg?react";
import {baseDistance, FLEXCENTER, FlexCenterStyle, fontSize, radius, zIndex} from "../../../styles/styles";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import {colorPalette} from "../../../styles/colorPalette";
import type {TSpaceEditorPanelTypes} from "../SpaceEditorPanelV5";
import {Functions} from "../../../../../utils/function/Functions";
import {useAppStore} from "../../../../../StateManager";
import {XyiconFeature} from "../../../../../generated/api/base";
import XMark from "../../../icons/xmark.svg?react";
import FilterMenuIcon from "../../../icons/filter-menu.svg?react";
import {DropdownButtonV5} from "../../../interaction/DropdownButtonV5";
import {VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import type {View} from "../../../../../data/models/View";

const panelTypes: TSpaceEditorPanelTypes[] = ["filter", "layers", "labels", "formatting"];

const getIconComponentByPanelType = (panelType: TSpaceEditorPanelTypes) => {
	switch (panelType) {
		case "filter":
			return FilterIcon;
		case "layers":
			return LayersIcon;
		case "labels":
			return LabelsIcon;
		case "formatting":
			return FormattingIcon;
	}
};

interface ISpaceViewBarProps {
	readonly activePanel: TSpaceEditorPanelTypes;
	readonly divRef: React.RefObject<HTMLDivElement>;
	readonly dropdownStyle: boolean;
	readonly onOpenPanelClick: (type: TSpaceEditorPanelTypes | null) => void;
}

export const SpaceViewBarV5 = (props: ISpaceViewBarProps) => {
	const {activePanel, dropdownStyle, onOpenPanelClick} = props;
	const appState = useAppStore((state) => state.appState);

	const getCountComponent = (selectedView: View, filtersCount: number, inDropdown: boolean = false) => {
		return (
			<CountStyled $inDropdown={inDropdown}>
				<IconButtonV5
					className="cancel"
					onMouseDown={Functions.stopPropagation}
					onClick={(e: React.MouseEvent) => {
						e.stopPropagation();
						selectedView.removeAllFiltersFromActiveFilterType();
					}}
					IconComponent={XMark}
				/>
				<span className="label">{filtersCount.toString()}</span>
			</CountStyled>
		);
	};

	return (
		<Observer>
			{() => {
				const selectedView = appState.actions.getSelectedView(XyiconFeature.SpaceEditor);
				const {type: filterType} = selectedView.filters;
				const filtersCount = selectedView.getActiveFilterCount(filterType);

				return (
					<SpaceViewBarStyled
						ref={props.divRef}
						$vertical={props.dropdownStyle}
					>
						{dropdownStyle ? (
							<DropdownButtonV5
								verticalAlignment={VerticalAlignment.bottomOuter}
								optionsZIndex={zIndex.contextOptions}
								offsetY={16}
								onlyIcons={false}
								closeOnMouseLeave={true}
								button={
									<>
										<IconButtonV5
											IconComponent={FilterMenuIcon}
											onClick={Functions.emptyFunction}
										/>
										{filtersCount > 0 && getCountComponent(selectedView, filtersCount)}
									</>
								}
								options={panelTypes.map((panelType) => ({
									key: panelType,
									isActive: activePanel === panelType,
									label: "",
									IconComponent: getIconComponentByPanelType(panelType),
									onClick: (event: React.MouseEvent) => {
										onOpenPanelClick(activePanel === panelType ? null : panelType);
									},
									allowCustomElementWithoutLabel: true,
									customElement: panelType === "filter" && filtersCount > 0 && getCountComponent(selectedView, filtersCount, true),
								}))}
							/>
						) : (
							panelTypes.map((panelType) => (
								<>
									<IconButtonV5
										key={panelType}
										isActive={activePanel === panelType}
										IconComponent={getIconComponentByPanelType(panelType)}
										onClick={Functions.emptyFunction}
										onMouseDown={(event: MouseEvent) => {
											event.stopPropagation();
											onOpenPanelClick(activePanel === panelType ? null : panelType);
										}}
									/>
									{panelType === "filter" && filtersCount > 0 && getCountComponent(selectedView, filtersCount)}
								</>
							))
						)}
					</SpaceViewBarStyled>
				);
			}}
		</Observer>
	);
};

const SpaceViewBarStyled = styled.div<{$vertical: boolean}>`
	${FlexCenterStyle};
	gap: ${baseDistance.sm};
	position: absolute;
	z-index: 2;
	top: 16px;
	right: 16px;
	padding: ${baseDistance.sm};
	background-color: ${colorPalette.white};
	border-radius: ${radius.md};
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);

	${(props) =>
		props.$vertical &&
		css`
			flex-direction: column;
		`};

	${IconButtonStyled} {
		&.isActive {
			background-color: ${colorPalette.primary.c200Light};
			color: ${colorPalette.blueGray.c500Primary};
		}
	}
`;

const CountStyled = styled.div<{$inDropdown: boolean}>`
	position: absolute;
	top: ${(props) => (props.$inDropdown ? "-2px" : "2px")};
	left: ${(props) => (props.$inDropdown ? "15px" : "30px")};
	border-radius: ${radius.md};
	background: ${colorPalette.libraryColors.darkOrange};
	width: ${baseDistance.md};
	height: ${baseDistance.md};
	${FLEXCENTER}
	color: ${colorPalette.white};
	font-size: ${fontSize.sm};
	z-index: 999999999999999;

	${IconButtonStyled} {
		display: none;
	}

	&:hover {
		${IconButtonStyled} {
			background-color: transparent;
			width: ${baseDistance.md};
			height: ${baseDistance.md};
			display: block;
			cursor: pointer;

			svg {
				width: ${baseDistance.md};
				height: ${baseDistance.md};
				margin-left: 8px;
			}
		}

		.label {
			display: none;
		}
	}
`;
